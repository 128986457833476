/* Speakeasy Pro Modal Styles */
.speakeasy-pro-modal {
  max-width: 350px; /* Reduced max-width */
  width: 90%;
  max-height: 80vh; /* Reduced max-height */
  overflow-y: auto;
  background-color: #1a1a2e;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
  color: #ffffff;
  
  /* Animation properties */
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;

  /* Add margin-top to move the modal down */
}

/* Add this new class for the open state */
.speakeasy-pro-modal.open {
  transform: translateY(0);
  opacity: 1;
}

.speakeasy-pro-modal h2 {
  text-align: center;
  margin-bottom: 10px; /* Reduced margin */
  font-size: 1.3em; /* Reduced title size */
}

.pro-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;  /* Reduced padding */
}

.pro-content h2 {
  color: #ffffff;
  margin: 0 0 10px 0;  /* Adjust margin */
  font-size: 1.3em; /* Reduced font size */
  text-align: center;
  width: 100%;  /* Make sure it takes full width */
}

.highlight {
  color: #6c63ff;
  display: inline;  /* Ensure it displays inline with the prefix */
}

/* Add this to ensure the prefix is visible */
.pro-content h2 span:not(.highlight) {
  color: #ffffff;
  display: inline;
}

.pro-image {
  width: 50%;  /* Reduced image width */
  max-height: 150px; /* Reduced max-height */
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-bottom: 10px; /* Reduced margin */
}

.pro-content p {
  margin-bottom: 8px; /* Reduced margin */
  line-height: 1.3; /* Reduced line height */
  font-size: 0.8em; /* Reduced font size */
}

.pro-content ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 10px; /* Reduced margin */
  width: 100%;
}

.pro-content li {
  margin-bottom: 8px; /* Reduced margin */
  font-size: 0.8em; /* Reduced font size */
  display: flex;
  align-items: flex-start;
}

.pro-content li::before {
  content: '•';
  color: #6c63ff;
  font-weight: bold;
  margin-right: 8px; /* Reduced margin */
}

.pro-card {
  background-color: #2a2a4a;
  border-radius: 8px;
  padding: 10px; /* Reduced padding */
  box-shadow: 0 2px 4px rgba(108, 99, 255, 0.2);
  transition: transform 0.3s;
}

.pro-card:hover {
  transform: translateY(-2px); /* Reduced hover effect */
}

.pro-card h3 {
  margin-bottom: 6px; /* Reduced margin */
  color: #ffffff;
  font-size: 0.9em; /* Reduced font size */
}

.pro-card p {
  color: #cfcfcf;
  font-size: 0.8em; /* Reduced font size */
}

.pro-pricing {
  margin: 10px 0; /* Reduced margin */
}

.pro-price {
  font-size: 1.8em; /* Reduced font size */
  font-weight: bold;
  color: #6c63ff;
}

.pro-period {
  font-size: 0.9em; /* Reduced font size */
  color: #cfcfcf;
}

.pro-features {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 10px; /* Reduced margin */
  width: 100%;
  text-align: left;
}

.pro-features li {
  margin-bottom: 8px; /* Reduced margin */
  font-size: 0.8em; /* Reduced font size */
  display: flex;
  align-items: flex-start;
  position: relative;
}

.checkmark {
  color: #6c63ff;
  font-weight: bold;
  margin-right: 8px; /* Reduced margin */
  flex-shrink: 0;
}

.feature-text {
  position: relative;
  cursor: pointer;
}

.feature-tooltip {
  visibility: hidden;
  width: 180px; /* Reduced width */
  background-color: #2a2a4a;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -90px; /* Adjusted for new width */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.7em; /* Reduced font size */
}

.feature-text:hover .feature-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Add a small arrow to the tooltip */
.feature-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2a2a4a transparent transparent transparent;
}

/* Close Modal Button */
.close-modal-button-Pro-Modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px; /* Reduced font size */
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.3s;
  z-index: 1;  /* Ensure it's above other content */
}

.close-modal-button-Pro-Modal:hover {
  color: #6c63ff;
}

/* Upgrade Button */
.upgrade-button {
  display: block;
  width: calc(100% - 30px);  /* Adjust width to account for padding */
  padding: 10px; /* Reduced padding */
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 14px; /* Reduced font size */
  font-weight: bold;
  cursor: pointer;
  margin: 10px auto 0;  /* Center the button */
  transition: background-color 0.3s, transform 0.3s;
}

.upgrade-button:hover {
  background-color: #5b54d6;
  transform: translateY(-2px);
}

.upgrade-button:active {
  transform: translateY(0);
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .speakeasy-pro-modal {
    padding: 20px;
  }

  .pro-content h2 {
    font-size: 1.3em;
  }

  .upgrade-button {
    font-size: 14px;
  }
}

/* Modal Animations */
.modal {
  transition: opacity 0.3s ease-out;
}

.feature-icons {
  display: inline-flex;
  margin-left: 3px;
  align-items: center;
}

.icon {
  font-size: 1.6em;
  margin-left: 5px;
}

.icon.spotify {
  color: #1DB954;
}

.icon.youtube {
  color: #FF0000;
}

.feature-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature-tooltip {
  width: 250px; /* Increased width to accommodate more text */
  margin-left: -125px; /* Adjust this value to half of the width */
}

@media (max-width: 480px) {
  .feature-icons {
    margin-left: 4px;
  }

  .icon {
    font-size: 1em;
  }

  .feature-tooltip {
    width: 200px;
    margin-left: -100px;
  }
}

/* Overlay styles to center the modal */
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Ensure it's above other content */
}