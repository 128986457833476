.pro-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  height: auto;
  overflow-y: auto;
}

body {
  overflow-y: auto !important;
}

.pro-hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  margin-bottom: 4rem;
  padding: 4rem 0;
}

.hero-content {
  animation: fadeInLeft 0.8s ease-out;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  line-height: 1.2;
}

.highlight {
  background: linear-gradient(45deg, var(--primary) 0%, #4ecdc4 100%);
  -webkit-background-clip: text;
}

.hero-image {
  animation: fadeInRight 0.8s ease-out;
}

.hero-image img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.price-tag {
  margin: 2rem 0;
}

.amount {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary);
}

.period {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin-left: 0.5rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 4rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease;
  animation: fadeInUp 0.6s ease-out;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-card img {
  width: 80px;
  height: 80px;
  margin-bottom: 1.5rem;
  border-radius: 50%;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary);
}

.checkout-button {
  background: linear-gradient(45deg, var(--primary) 0%, #4ecdc4 100%);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: fit-content;
}

.checkout-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: #ff6b6b;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: rgba(255, 107, 107, 0.1);
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .pro-hero {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2rem;
    padding: 2rem 0;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .checkout-button {
    margin: 0 auto;
  }
} 