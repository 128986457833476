.navbar-spotify-player {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 50px;
  padding: 6px 12px;
  margin-right: 0px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 220px; /* Slightly increased width */
  height: 36px; /* Slightly reduced height */
  overflow: hidden;
}

.navbar-spotify-player:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.navbar-album-art {
  width: 32px; /* Slightly reduced size */
  height: 32px;
  border-radius: 4px;
  margin-right: 8px;
  flex-shrink: 0;
}

.navbar-track-info {
  flex: 1; /* Allow it to take up all available space */
  overflow: hidden;
  margin-right: 8px;
}

.navbar-track-name {
  font-size: 0.85em;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-artist-name {
  font-size: 0.75em;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-spotify-controls {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navbar-spotify-control-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1em; /* Reduced font size */
  cursor: pointer;
  padding: 3px;
  margin: 0 1px;
  transition: color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px; /* Reduced width */
  height: 24px; /* Reduced height */
  outline: none;
}

.navbar-spotify-control-button:hover {
  color: #1DB954;
  background: none;
}

.navbar-spotify-control-button:focus {
  outline: none;
  box-shadow: none;
  background: none;
}

/* Specific styles for play/pause button */
.navbar-spotify-control-button.play-pause {
  font-size: 1.2em;
}

/* Additional rule to override any global styles */
.navbar-spotify-control-button:hover,
.navbar-spotify-control-button:focus,
.navbar-spotify-control-button:active {
  background: none !important;
  box-shadow: none !important;
}
