.direct-messages-list {
  text-align: center;
}

.dm-instructions {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.dm-icon {
  font-size: 24px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.dm-instructions p {
  margin: 0;
  font-size: 0.9em;
  line-height: 1.4;
  max-width: 250px;
}
