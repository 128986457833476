.joyride-tooltip {
  background-color: #1f1f1f !important;
  color: #eaeaea !important;
  border-radius: 8px !important;
  padding: 20px !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3) !important;
}

.joyride-tooltip h4 {
  font-size: 1.8rem !important;
  color: #ffffff !important;
}

.joyride-tooltip p {
  font-size: 1.2rem !important;
  color: #b3b3b3 !important;
}

.joyride-tooltip .joyride-tooltip__buttons button {
  background-color: #6c63ff !important;
  border: none !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  margin: 5px !important;
  cursor: pointer !important;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s !important;
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.4) !important;
}

.joyride-tooltip .joyride-tooltip__buttons button:hover {
  background-color: #4b43cc !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 8px 20px rgba(108, 99, 255, 0.6) !important;
}

.joyride-tooltip__arrow {
  border-color: #1f1f1f !important;
}
