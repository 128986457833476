.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Darker background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.search-modal-content {
  background: #1b1b2f; /* Dark background for modal */
  color: #eaeaea; /* Light text color */
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Darker shadow */
  position: relative;
}

.close-button-search {
  position: absolute;
  top: 20px; /* Align with the top padding of the modal content */
  right: 20px; /* Align with the right padding of the modal content */
  background: none;
  border: none;
  color: #eaeaea;
  font-size: 1.5em;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s ease;
  height: 30px; /* Set a fixed height */
  width: 30px; /* Set a fixed width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button-search:hover {
  color: #ff6b6b; /* Change to a different color, like a light red */
}

.close-button-search:focus {
  outline: none;
}

/* Override any conflicting styles */
.close-button-search:hover,
.close-button-search:focus,
.close-button-search:active {
  background: none !important;
  box-shadow: none !important;
}

.search-results {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
}

.search-result-item:hover {
  background: #6c63ff; /* Purple background on hover */
}

.search-result-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.search-result-name {
  font-size: 1em;
}

.search-modal-input {
  width: 100%; /* Changed from calc(100% - 20px) */
  padding: 10px;
  margin-top: 15px; /* Add some space between title and input */
  border: 1px solid #6c63ff; /* Purple border */
  border-radius: 5px;
  background: #0f0e17; /* Dark input background */
  color: #eaeaea; /* Light text color */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.search-modal-input::placeholder {
  color: #aaaaaa; /* Light placeholder color */
}

/* Add some spacing between the title and the input */
.search-modal-content h2 {
  margin: 0;
  padding-right: 30px; /* Make room for the close button */
  line-height: 1.5; /* Adjust as needed to match the button height */
}

.search-form {
  display: flex;
  margin-bottom: 15px;
}

.search-modal-input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 14px;
}

.search-button {
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
}

.search-button:hover {
  background-color: rgba(15, 14, 23, 0.8);
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.search-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(15, 14, 23, 0.2);
}

.search-button:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}
