/* Vocabulary Race Container */
.vocabulary-race-container {
    width: 95%;
    max-width: 1000px;
    margin: 60px auto 0;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    border-radius: 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(255, 255, 255, 0.1);
    background-color: rgba(15, 14, 23, 0.8);
}

/* New Game Container */
.vocabulary-race-game-container {
    width: 400px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
}

/* Adjusted Word Pairs Grid */
.word-pairs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Increased min-width for wider layout */
    gap: 20px; /* Increased gap for better spacing */
    margin-bottom: 20px;
}

/* Existing Styles */
.vocabulary-race-intro {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vocabulary-race-title {
    font-size: 1.8em;
    color: #ffffff;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.vocabulary-race-logo-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(108, 99, 255, 0.6);
}

.vocabulary-race-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vocabulary-race-description {
    font-size: 1em;
    color: #eaeaea;
    margin-bottom: 20px;
    line-height: 1.4;
    max-width: 450px;
}

.vocabulary-race-rules {
    text-align: left;
    max-width: 300px;
    margin: 0 auto 20px;
    padding-left: 10px;
}

.vocabulary-race-rules li {
    margin-bottom: 8px;
    color: #bbb;
    font-size: 0.85em;
}

.vocabulary-race-difficulty {
    font-size: 1.3em;
    margin-bottom: 10px;
}

.level-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
}

.level-button {
    padding: 8px 15px;
    font-size: 0.9em;
    font-weight: bold;
    color: #ffffff;
    background-color: #6c63ff;
    border: 2px solid #6c63ff;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.level-button.selected, .level-button:hover {
    background-color: rgba(15, 14, 23, 0.8);
    border-color: #ffffff;
}

.vocabulary-race-button {
    padding: 12px 25px;
    font-size: 1em;
    font-weight: bold;
    background: linear-gradient(145deg, #7c73ff, #6c63ff);
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 3px 10px rgba(108, 99, 255, 0.3);
}

.vocabulary-race-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(108, 99, 255, 0.4);
}

.vocabulary-race-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1.2em;
    color: #666;
}

/* Optional: Add a loading spinner */
.loading-container p::after {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
    border: 2px solid #666;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.timer {
    font-size: 1.1em;
    text-align: center;
    margin-bottom: 15px;
}

.word-item {
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9em;
}

.word-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 10px rgba(108, 99, 255, 0.3);
}

.word-item.selected {
    background-color: #6c63ff;
}

.word-item.correct {
    background-color: #28a745;
}

.word-item.incorrect {
    background-color: #dc3545;
}

.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15px;
    border-radius: 8px;
    z-index: 1000;
}

.popup-message {
    color: #ffffff;
    font-size: 1em;
    text-align: center;
}

.vocabulary-race-finish {
    text-align: center;
}

.vocabulary-race-finish h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.vocabulary-race-finish p {
    font-size: 1.1em;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .vocabulary-race-container {
        width: 98%;
        padding: 20px 10px;
        margin: 30px auto 0;
    }

    .vocabulary-race-game-container {
        width: 100%;
        padding: 15px;
    }

    .word-pairs-grid {
        grid-template-columns: repeat(2, 1fr); /* Force 2 columns */
        gap: 12px;
    }

    .word-item {
        padding: 8px;
        font-size: 0.85em;
        min-height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .vocabulary-race-container {
        margin: 20px auto 0;
    }

    .word-pairs-grid {
        gap: 8px;
    }

    .word-item {
        padding: 6px;
        font-size: 0.8em;
        min-height: 32px;
    }
}