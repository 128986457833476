.ai-chat-section {
  background: linear-gradient(135deg, #2a2a4a, #1b1b2f);
  border-radius: 20px;
  padding: 30px;
  margin: 20px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(108, 99, 255, 0.2);
}

.ai-chat-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.ai-chat-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ai-chat-title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}

.ai-chat-primary-text {
  color: #6c63ff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.ai-chat-icon {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid rgba(108, 99, 255, 0.3);
  padding: 8px;
  background: rgba(108, 99, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ai-chat-section:hover .ai-chat-icon {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.2);
  border-radius: 50%;
}

.ai-chat-description {
  font-size: 1.2rem;
  margin-top: 15px;
  color: #e0e0e0;
}

.ai-chat-language-selector {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.ai-chat-flag-slider {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #6c63ff #1b1b2f;
}

.ai-chat-language-flag {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 2px solid #e0e0e0;
}

.ai-chat-language-flag:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
}

.ai-chat-language-flag.selected {
  border: 3px solid #6c63ff;
}

/* New styles for the add language button */
.ai-chat-section .add-language-button {
  position: absolute !important;
  right: 20px !important;
  bottom: 20px !important;
  width: 36px !important;
  height: 36px !important;
  border-radius: 50% !important;
  background: #6c63ff !important;
  color: white !important;
  border: none !important;
  font-size: 20px !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 2px 8px rgba(108, 99, 255, 0.3) !important;
  transition: background-color 0.2s ease !important;
  transform: none !important;
}

.ai-chat-section .add-language-button:hover {
  background: #5550d9 !important;
  transform: none !important;
}

/* Add this media query at the end of your file */
@media (max-width: 768px) {
  .ai-chat-section {
    padding: 20px;
  }

  .ai-chat-title {
    font-size: 2rem;
  }

  .ai-chat-icon {
    width: 80px;
    height: 80px;
    padding: 6px;
  }

  .ai-chat-description {
    font-size: 1rem;
  }

  .ai-chat-language-flag {
    width: 30px;
    height: 30px;
  }

  .ai-chat-section .add-language-button {
    width: 32px !important;
    height: 32px !important;
    font-size: 16px !important;
    right: 15px !important;
    bottom: 15px !important;
  }

  .ai-chat-flag-slider {
    padding: 8px;
    gap: 8px;
  }

  /* Remove hover effects on mobile */
  .ai-chat-section:hover {
    transform: none;
  }

  .ai-chat-section:hover .ai-chat-icon {
    transform: none;
  }

  .ai-chat-language-flag:hover {
    transform: none;
  }
}

/* For even smaller screens */
@media (max-width: 480px) {
  .ai-chat-section {
    padding: 15px;
  }

  .ai-chat-title {
    font-size: 1.75rem;
  }

  .ai-chat-icon {
    width: 60px;
    height: 60px;
    padding: 4px;
  }
} 