body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #0f0e17;
  color: #eaeaea;
  display: flex;
  padding: 40px;
  align-items: center;
  flex-direction: column; /* Ensure content flows vertically */
  min-height: 100vh;
  padding-bottom: 0px
}

.club-page {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #0f0e17;
  color: #eaeaea;
  padding: 40px;
  align-items: center;
  flex-direction: column; /* Ensure content flows vertically */
  min-height: 100vh;
}

/* Container Styles */
.meetings-container {
  width: 100%;
  max-width: 1200px;
  padding: 30px;
  background-color: #1b1b2f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  color: white;
  margin: 20px auto;
  text-align: center; /* Center the text inside the container */
  display: flex;
  flex-direction: column;
}

.create-meeting-container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background-color: #1b1b2f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  color: white;
  margin: 200px auto;
  text-align: center; /* Center the text inside the container */
  display: flex;
  flex-direction: column;
}

/* Header Styles */
.centered-text {
  text-align: center; /* Center the header text */
  width: 100%; /* Ensure it takes the full width */
}

/* Grid Styles */
.meetings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  justify-content: center; /* Center the grid items */
}

/* Card Styles */
.meeting-item {
  background-color: #1b1b2f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%; /* Ensure cards take up the full width available */
  max-width: 300px; /* Limit the maximum width of each card */
}

.meeting-header {
  margin-bottom: 10px;
}

.meeting-header h3 {
  margin: 0;
  font-size: 1.2em;
  color: #61dafb;
}

.meeting-info {
  margin-bottom: 10px;
}

.meeting-info div {
  margin: 5px 0;
}

.meeting-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attendance-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
}

.attendance-button:not(.added) {
  background-color: #007bff;
}

.attendance-button.added {
  background-color: #28a745;
}

.attendees-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.attendees-list span {
  margin-right: 10px;
}

.attendee-profile-pic-club {
  margin-bottom: -8px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -10px;
  border: 2px solid #0f0e17;
  position: relative;
  z-index: 1;
}

.attendee-profile-pic-club:hover {
  transform: scale(1.2); /* Scale up on hover */
}

.attendee-profile-pic-club:first-of-type {
  margin-left: 0;
}

/* Language Selection Styles */
.language-section {
  text-align: center;
  margin-top: 60px;
}

.languages-navigation {
  display: flex;
  align-items: center;
}

.languages-navigation button {
  background-color: #1b1b2f;
  color: #61dafb;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5em;
  margin: 0 10px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.languages-navigation button:hover {
  background-color: #333;
}

.languages-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}

.language-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  position: relative;
}

.language-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.language-item p {
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
}

.language-item.selected img {
  border: 4px solid #28a745;
}

.checkmark {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #28a745;
  color: white;
  border-radius: 50%;
  padding: 2px;
  font-size: 14px;
}

.language-item.selected .checkmark {
  display: block;
}

/* Get Started Button Styles */
.get-started-container {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.get-started-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: linear-gradient(45deg, #ff6b6b, #f06595, #cc5de8);
  background-size: 200% 200%;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: background-position 0.5s, box-shadow 0.3s, transform 0.3s;
}

.get-started-button:hover {
  background-position: 100% 0;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  transform: translateY(-5px);
}

.get-started-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
