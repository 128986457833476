/* Badge Modal Styles */
.badge-modal {
    background-color: #ffffff !important; /* Ensure white background */
    color: #1b1b2f;
    border-radius: 15px;
    padding: 40px;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    transition: all 0.3s ease-in-out;
    animation: spinAndGrow 0.6s ease-in-out;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  

  .badge-modal-content h2 {
    color: #1b1b2f; /* Darker color for the h2 text */
    font-size: 20px; /* Adjust size if needed */
}


.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}


.badge-modal-content p {
  color: #1b1b2f; /* Darker color for the h2 text */
}
  .badge-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .badge-modal-content {
    text-align: center;
    width: 300px;
  }
  
  .badge-modal-image {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    animation: spinImage 0.6s ease-in-out;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  
  
  @keyframes spinAndGrow {
    0% {
      transform: translateY(100%) scale(0.5);
      opacity: 0;
    }
    60% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }
  
  
  
  .badge-levels {
    text-align: left;
    margin-top: 20px;
  }
  
  .badge-level-info {
    margin-bottom: 10px;
  }
  
  .badge-level-info h3 {
    margin: 0;
    font-weight: bold;
  }
  
  .badge-level-info p {
    margin: 0;
    font-size: 0.9em;
    color: #555;
  }

/* Badge Modal Styles */
.badge-modal {
  background-color: #ffffff !important; /* Ensure white background */
  color: #1b1b2f;
  border-radius: 15px;
  padding: 40px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  transition: all 0.3s ease-in-out;
  animation: spinAndGrow 0.6s ease-in-out;
  text-align: center;

  /* Black border with white line running through it */
  border: 1px solid rgb(255, 255, 255); /* Black outer border */
  box-shadow: inset 0 0 0 2px rgb(0, 0, 0); /* White line inside */
}

.badge-modal-content h2 {
  color: #1b1b2f; /* Darker color for the h2 text */
  font-size: 20px; /* Adjust size if needed */
}

.ReactModal__Overlay {
background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
color: white;
border-radius: 10px;
padding: 20px;
max-width: 500px;
margin: 0 auto;
}

.badge-modal-content p {
color: #1b1b2f; /* Darker color for the p text */
}

.badge-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-modal-content {
  text-align: center;
  width: 300px;
}

.badge-modal-image {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  animation: spinImage 0.6s ease-in-out;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

@keyframes spinAndGrow {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
  }
  60% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.badge-levels {
  text-align: left;
  margin-top: 20px;
}

.badge-level-info {
  margin-bottom: 10px;
}

.badge-level-info h3 {
  margin: 0;
  font-weight: bold;
}

.badge-level-info p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
