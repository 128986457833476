.travel-videos-section {
  padding: 0 20px;
  max-width: 900px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.section-header {
  margin-bottom: 24px;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px 0;
  width: 100%;
}

.video-card {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background: rgb(15, 15, 15);
  aspect-ratio: 16 / 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-thumbnail {
  width: 100%;
  height: 100%;
  position: relative;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Simplified responsive breakpoints */
@media (max-width: 768px) {
  .travel-videos-section {
    padding: 0 10px;
  }

  .video-grid {
    gap: 15px;
    padding: 15px 0;
  }
}

@media (max-width: 480px) {
  .video-grid {
    grid-template-columns: 1fr;
    gap: 12px;
  }
} 
