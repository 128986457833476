.mobile-nav-container {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-nav-container {
    display: block;
  }

  .mobile-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(15, 14, 23, 0.95);
    backdrop-filter: blur(20px);
    padding: 15px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2);
    z-index: 999;
  }

  .mobile-nav-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    padding: 8px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    width: 45px;
    height: 45px;
  }

  .mobile-nav-link .icon {
    font-size: 22px;
    transition: all 0.3s ease;
  }

  .mobile-nav-link.active {
    color: #ffffff;
  }

  .mobile-nav-link.active .icon {
    transform: scale(1.2);
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.5));
  }

  .mobile-nav-link:hover {
    color: #ffffff;
  }

  .mobile-nav-link:active {
    transform: scale(0.95);
  }

  .mobile-nav-link::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transition: width 0.3s ease, height 0.3s ease;
  }

  .mobile-nav-link:active::before {
    width: 45px;
    height: 45px;
  }

  body {
    padding-bottom: 75px;
  }

  .mobile-profile-pic {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid transparent;
    transition: all 0.3s ease;
  }

  .mobile-nav-link.active .mobile-profile-pic {
    border-color: #ffffff;
    transform: scale(1.2);
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
  }
} 