/* General Leaderboard Page Styling */
.leaderboard-page {
    padding: 20px;
    background-color: #0f0e17; /* Dark background */
    color: white; /* Text color */
    font-family: 'Avenir', sans-serif; /* Modern, clean font */
  }
  
  .leaderboard-header {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 700;
  }
  
  /* Leaderboard Container */
  .leaderboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  /* Individual Leaderboard Card */
  .leaderboard-card {
    background-color: #1f1f2e; /* Slightly lighter dark background for contrast */
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Soft shadow */
    padding: 20px;
    width: 80%;
    max-width: 600px;
    display: flex;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    position: relative;
  }
  
  .leaderboard-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
  }
  
  /* Rank Styling */
  .leaderboard-rank {
    font-size: 2em;
    font-weight: 800;
    color: #ffffff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Slight outline effect */
    margin-right: 20px;
    width: 50px;
    text-align: center;
    position: absolute;
    left: -70px; /* Position the rank number outside the card */
  }
  
  /* Profile Picture Styling */
  .leaderboard-profile-pic {
    margin-right: 20px;
    flex-shrink: 0;
  }
  
  .leaderboard-profile-pic img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
    border: 2px solid #6c63ff; /* Accent border color */
  }
  
  /* Leaderboard Info */
  .leaderboard-info {
    flex-grow: 1;
  }
  
  .leaderboard-info h3 {
    margin: 0;
    font-size: 1.5em;
    font-weight: 600; /* Display name should stand out */
  }
  
  .leaderboard-info p {
    margin: 5px 0;
    font-size: 1.1em;
    color: #b0b0c3; /* Subtle, light color for text */
    font-weight: 400; /* Reduced boldness */
  }
  
  .leaderboard-info p strong {
    color: #ffffff; /* White for strong text contrast */
    font-weight: 500; /* Slightly less bold than the display name */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .leaderboard-card {
      width: 90%;
      padding: 15px;
    }
  
    .leaderboard-info h3 {
      font-size: 1.3em;
    }
  
    .leaderboard-info p {
      font-size: 1em;
    }
  
    .leaderboard-rank {
      left: -50px; /* Adjust position for smaller screens */
    }
  }
  
  .leaderboard-controls {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the controls horizontally */
    gap: 20px;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 15px; /* Rounded corners */
  }
  
  .leaderboard-controls label {
    font-size: 1.2em;
    color: #ffffff;
    margin-right: 10px;
  }
  
  .leaderboard-controls select {
    padding: 10px 15px;
    font-size: 1em;
    font-weight: bold;
    color: #ffffff; /* White text */
    background-color: #6c63ff; /* Purple background by default */
    border: 2px solid #6c63ff; /* Purple border */
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
  }
  
  .leaderboard-controls select:hover {
    background-color: rgba(15, 14, 23, 0.8); /* Transparent dark background on hover */
    color: #ffffff; /* White text on hover */
    border-color: #ffffff; /* White border on hover */
    box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4); /* Darker shadow on hover */
    transform: translateY(-3px);
  }
  
  .leaderboard-controls select:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(108, 99, 255, 0.5); /* Glow effect on focus */
  }
  
  @media (max-width: 768px) {
    .leaderboard-controls {
      flex-direction: column;
      gap: 10px;
    }
  
    .leaderboard-controls select {
      width: 100%;
    }
  }
  