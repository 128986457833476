.select-language-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0f0e17;
    color: #eaeaea;
    min-height: 100vh;
    padding: 40px;
}

.select-language-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
}

.select-language-card {
    background-color: #1b1b2f;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 200px;
    cursor: pointer;
    transition: transform 0.3s;
}

.select-language-card:hover {
    transform: scale(1.05);
}

.select-language-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
}

.select-language-name {
    font-size: 1.2em;
    margin-top: 10px;
    text-align: center;
    width: 100%;
}

.select-language-status {
    font-size: 0.9em;
    color: #aaaaaa;
    text-align: center;
    width: 100%;
}

.select-language-header {
    font-size: 2.5em;
    margin-bottom: 0px;
    color: #ffffff;
    text-align: center;
}

.select-language-instructions {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #dddddd;
    text-align: center;
}

.home-get-started-button {
    margin-top: 30px;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    color: #6c63ff;
    background-color: #ffffff;
    border: 2px solid #6c63ff;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.home-get-started-button:hover {
    background-color: #6c63ff;
    color: #ffffff;
    border-color: #ffffff;
    box-shadow: 0 15px 30px rgba(108, 99, 255, 0.4);
    transform: translateY(-3px);
}

.home-get-started-button:active {
    transform: translateY(0);
    box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2);
}


.select-language-card.selected {
    box-shadow: 0 0 5px 4px #28a745; /* Green glow when selected */
}

/* Specific styling for the home page's "Get Started" button */
/* Specific styling for the home page's "Get Started" button */
.home-get-started-button {
    margin-top: 30px;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    color: #6c63ff; /* Light purple text to match the background */
    background-color: #ffffff; /* White background */
    border: 2px solid #6c63ff; /* Light purple border */
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2); /* Light purple shadow */
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  }
  
  .home-get-started-button:hover {
    background-color: #6c63ff; /* Light purple background on hover */
    color: #ffffff; /* White text on hover */
    border-color: #ffffff;
    box-shadow: 0 15px 30px rgba(108, 99, 255, 0.4); /* Deeper purple shadow on hover */
    transform: translateY(-3px);
  }
  
  .home-get-started-button:active {
    transform: translateY(0);
    box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2); /* Light purple shadow */
  }
  

.select-language-name {
    font-size: 1.2em;
    margin-top: 10px;
}

.select-language-status {
    font-size: 0.7em;
    color: #aaaaaa;
    margin-top: 5px; /* Space between the language name and the learners count */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .select-language-page {
    padding: 20px;
  }

  .select-language-header {
    font-size: 1.8em;
    margin-bottom: 10px;
  }

  .select-language-instructions {
    font-size: 1em;
    margin-bottom: 20px;
    padding: 0 10px;
  }

  .select-language-container {
    gap: 15px;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .select-language-card {
    width: calc(45% - 15px);
    min-width: 120px;
    max-width: 140px;
    padding: 10px;
    margin: 5px;
  }

  .select-language-image {
    width: 60px;
    height: 60px;
  }

  .select-language-name {
    font-size: 1em;
    margin-top: 8px;
  }

  .select-language-status {
    font-size: 0.6em;
  }

  .home-get-started-button {
    margin-top: 20px;
    padding: 12px 24px;
    font-size: 16px;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .select-language-container {
    gap: 12px;
    padding: 0 12px;
  }

  .select-language-card {
    width: calc(45% - 12px);
    min-width: 100px;
    max-width: 120px;
    padding: 8px;
    margin: 4px;
  }

  .select-language-image {
    width: 50px;
    height: 50px;
  }

  .select-language-name {
    font-size: 0.85em;
    margin-top: 5px;
  }

  .select-language-status {
    font-size: 0.5em;
    margin-top: 3px;
  }

  .home-get-started-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* Ensure smooth transitions */
.select-language-card,
.select-language-image,
.select-language-name,
.home-get-started-button {
  transition: all 0.3s ease-in-out;
}
