.thread-detail-page {
  min-height: calc(100vh - 80px);
  background-color: #0f0e17;
  padding: 40px 20px;
}

.thread-detail-container {
  max-width: 1000px;
  margin: 0 auto;
  background: #13131f;
  border-radius: 20px;
  padding: 0 40px 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.thread-banner {
  width: 100vw;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0 30px;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  border-radius: 0;
  opacity: 0.8;
}

.thread-detail-header {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.thread-detail-header h1 {
  font-size: 2.2rem;
  color: #ffffff;
  margin: 20px 0;
  line-height: 1.3;
}

.thread-location {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.thread-metadata {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  position: relative;
}

.thread-author {
  display: flex;
  align-items: center;
  gap: 10px;
}

.thread-author img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #6c63ff;
}

.thread-author span {
  color: #ffffff;
  font-weight: 500;
}

.thread-metadata .timestamp {
  position: absolute;
  top: 0;
  right: 0;
  margin-left: 0;
  color: #a0a0a0;
  font-size: 0.9rem;
}

.thread-content {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #eaeaea;
  margin-bottom: 40px;
}

.thread-actions {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.action-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.action-button.liked {
  color: #FF6B6B;
  background: rgba(255, 107, 107, 0.1);
}

.action-button.liked:hover {
  background: rgba(255, 107, 107, 0.2);
}

.comments-section {
  margin-top: 40px;
}

.comments-section h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.comment {
  padding: 20px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  margin-bottom: 15px;
  border-left: 3px solid rgba(108, 99, 255, 0.2);
}

.comment-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 12px;
}

.comment-user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-header img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid rgba(108, 99, 255, 0.3);
}

.comment-header .username {
  color: #ffffff;
  font-weight: 500;
}

.comment-header .timestamp {
  margin-left: 38px;
  color: #a0a0a0;
  font-size: 0.85rem;
}

.comment-form {
  margin: 30px 0;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
}

.comment-form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 15px;
  color: #ffffff;
  margin-bottom: 15px;
  resize: vertical;
  box-sizing: border-box;
  min-height: 100px;
  max-height: 300px;
  font-family: inherit;
  font-size: 1rem;
}

.comment-form textarea:focus {
  outline: none;
  border-color: #6c63ff;
  box-shadow: 0 0 0 2px rgba(108, 99, 255, 0.2);
}

.comment-form-actions {
  display: flex;
  justify-content: flex-end;
}

.comment-form button {
  padding: 10px 20px;
  background: #6c63ff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
}

.comment-form button:hover {
  background: #5b52e7;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
}

@media (max-width: 1024px) {
  .thread-detail-container {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .thread-detail-container {
    padding: 0 20px 20px;
  }
  
  .thread-banner {
    height: 200px;
    margin: 0 0 20px;
  }
  
  .thread-metadata .timestamp {
    position: static;
    margin-top: 5px;
    text-align: right;
  }
}

.clickable-profile {
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.clickable-profile:hover {
  opacity: 0.8;
}

.clickable-username {
  cursor: pointer;
  transition: color 0.2s ease;
}

.clickable-username:hover {
  color: #007bff; /* or your preferred hover color */
  text-decoration: underline;
}

.comment-content {
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.4;
}

.comment-link {
  color: #0066cc;
  text-decoration: underline;
  word-break: break-all;
  cursor: pointer;
}

.comment-link:hover {
  color: #004499;
}

/* If your theme is dark, use these colors instead: */
.comment-link {
  color: #66b3ff;
  text-decoration: underline;
  word-break: break-all;
  cursor: pointer;
}

.comment-link:hover {
  color: #99ccff;
} 