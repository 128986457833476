.dashboard-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #0f0e17;
  color: #eaeaea;
  padding: 40px;
  width: 930px;
  margin: 0 auto;
}

.languages-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.dashboard-page-h1 {
  font-size: 3em;
  margin: 0;
  text-align: center;
}

.dashboard-page-h1-badges {
  font-size: 3em;
  margin-bottom: 30px;
  width: 100%;
  text-align: center; /* Center the text within the width of the container */
}


.languages-container-dash {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns per row */
  row-gap: 30px;
  align-items: flex-start;
  width: 100%; /* Ensure the grid takes full width */
  justify-items: center; /* Center the items within the grid */
  overflow: visible; /* Ensure content is fully visible */
  height: auto; /* Allow the height to adjust dynamically */
  padding-bottom: 20px; /* Add padding to prevent cut-off */


}

.language-card {
  background-color: #1b1b2f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 380px; /* Ensure the cards don't overlap */
  cursor: pointer;
  transition: transform 0.3s;
}

.language-card:hover {
  transform: scale(1.05);
}

.language-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
}

.language-name {
  font-size: 1.5em;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.language-level {
  background-color: #4a47a3;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 0.9em;
}

.badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 100%; /* Ensure the container takes full width */
  justify-content: center; /* Center the content within the container */
}

.badge-card {
  background-color: #1b1b2f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 190px; /* Increased height */
  cursor: pointer;
  transition: transform 0.3s;
  position: relative; /* Ensure the badge level tag is positioned relative to the card */
}

.badge-card:hover {
  transform: scale(1.05);
}

.badge-card.locked {
  opacity: 0.6;
}

.badge-card.unlocked {
  opacity: 1;
}

.badge-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.badge-details {
  text-align: center;
  display: flex;
  flex-direction: column; /* Ensure the text sits one below the other */
}

.badge-name {
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 5px; /* Add some space between name and description */
}

.badge-description {
  font-size: 0.8em;
  color: #888;
}

.badge-level {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4a47a3;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
}

/* Modal Styles */

.language-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.language-item.selected img {
  border: 2px solid #61dafb;
}

.checkmark {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #28a745;
  border-radius: 50%;
  padding: 5px;
}

/* .add-language-button */
.add-language-button {
  background-color: #0f0e17;
  color: #ffffff; /* White text color */
  background-color: #0f0e17;
  border-radius: 50%;
  font-size: 1.5em;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Transparent black box shadow */
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.add-language-button:hover {
  background-color: #6c63ff; /* Purple background on hover */
  color: #ffffff; /* White text color */
  border-color: #6c63ff; /* Purple border on hover */
  box-shadow: 0 15px 30px rgba(108, 99, 255, 0.4);
  transform: translateY(-3px);
}

.add-language-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2);
}

/* .add-languages-button and .close-modal-button */
.add-languages-button,
.close-modal-button {
  background-color: #1b1b2f;
  color: #ffffff; /* White text color */
  background-color: #1b1b2f;
  padding: 15px 30px; /* Match the padding from .acc-button-pp */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50px; /* Rounded corners to match .acc-button-pp */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Transparent black box shadow */
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
}

.add-languages-button:hover,
.close-modal-button:hover {
  background-color: #6c63ff; /* Purple background on hover */
  color: #ffffff; /* White text color */
  border-color: #6c63ff; /* Purple border on hover */
  box-shadow: 0 15px 30px rgba(108, 99, 255, 0.4);
  transform: translateY(-3px);
}

.add-languages-button:active,
.close-modal-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2);
}




.modal {
  background-color: #1b1b2f;
  color: #eaeaea;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: auto;
  position: relative;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.languages-container-modal {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.language-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.language-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.language-item p {
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
}

.language-item.selected img {
  border: 4px solid #28a745;
}

.badge-level.vibranium {
  background: linear-gradient(135deg, #4B0082 25%, #000000 25%, #4B0082 50%, #000000 50%, #4B0082 75%, #000000 75%, #4B0082);
  background-size: 200% 200%; 
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: vibraniumAnimation 6s linear infinite;
}



.badge-level.silver {
  background: linear-gradient(135deg, #c0c0c0 25%, #808080 25%, #c0c0c0 50%, #808080 50%, #c0c0c0 75%, #808080 75%, #c0c0c0);
  background-size: 200% 200%; 
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: silverAnimation 8s linear infinite;
}


.badge-level.gold {
  background: linear-gradient(135deg, #ffd700 25%, #b8860b 25%, #ffd700 50%, #b8860b 50%, #ffd700 75%, #b8860b 75%, #ffd700);
  background-size: 200% 200%; 
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: goldAnimation 6s linear infinite;
}

.dashboard-page-container {
  position: relative;
  min-height: 100vh;
  /* ... other existing styles ... */
}

.speakeasy-pro-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* Copy the acc-button styles from Account.css */
.acc-button {
  margin-top: 0px;
  padding: 15px 30px;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
}

.acc-button:hover {
  background-color: rgba(15, 14, 23, 0.8);
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.acc-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(15, 14, 23, 0.2);
}
.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.tour-active .language-card,
.tour-active .add-language-button,
.tour-active .account-badge-card {
  pointer-events: none;
}

.shepherd-element {
  z-index: 10000;
}

.shepherd-enabled {
  z-index: 10001;
  position: relative;
  pointer-events: auto !important;
}

.shepherd-target {
  pointer-events: auto !important;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .dashboard-page {
    width: 100%;
    padding: 20px;
    padding-bottom: 80px; /* Space for bottom nav */
    margin: 0;
  }

  /* Headers */
  .dashboard-page-h1,
  .dashboard-page-h1-badges {
    font-size: 1.5em;
    margin-bottom: 20px;
    translate: 0;
  }

  /* Languages Grid */
  .languages-container-dash {
    grid-template-columns: 1fr;
    gap: 25px;
    padding: 0;
    width: 100%;
    max-width: 450px;
  }

  /* Language Cards */
  .language-card {
    width: 100%;
    margin: 0;
  }

  .language-image {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }

  .language-name {
    font-size: 1.2em;
  }

  .language-level {
    padding: 4px 8px;
    font-size: 0.8em;
  }

  /* Add Language Button */
  .add-language-button {
    width: 40px;
    height: 40px;
    font-size: 1.2em;
    margin-top: -10px;
  }

  /* Badges Section */
  .account-badges-container {
    padding: 0 10px;
  }

  .account-badges-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 15px;
    justify-items: center;
  }

  .account-badge-card {
    width: 90px;
    height: 90px;
  }

  .account-badge-image {
    width: 60px;
    height: 60px;
  }

  /* Modal Adjustments */
  .modal {
    width: 90%;
    max-width: 350px;
    padding: 15px;
  }

  .languages-container-modal {
    gap: 15px;
  }

  .language-item img {
    width: 60px;
    height: 60px;
  }

  /* Pro Button */
  .speakeasy-pro-button {
    bottom: 80px; /* Above bottom nav */
    right: 15px;
    padding: 10px 20px;
    font-size: 12px;
  }

  /* Center the header elements */
  .languages-header {
    justify-content: center;
    gap: 15px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
}

/* Smaller phones */
@media screen and (max-width: 380px) {
  .languages-container-dash {
    grid-template-columns: 1fr;
  }

  .language-card {
    padding: 12px;
  }

  .language-image {
    width: 50px;
    height: 50px;
  }

  .account-badges-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Add these styles at the bottom of the file */
.progress-preview-container {
  background: rgba(108, 99, 255, 0.1);
  border: 1px solid rgba(108, 99, 255, 0.2);
  border-radius: 15px;
  padding: 25px;
  margin-top: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.progress-preview-container:hover {
  transform: translateY(-3px);
  background: rgba(108, 99, 255, 0.15);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.progress-preview-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.progress-preview-title {
  color: #6c63ff;
  font-size: 1.2em;
  font-weight: bold;
}

.progress-preview-description {
  color: #bbb;
  font-size: 0.9em;
}

.progress-preview-icon {
  color: #6c63ff;
  font-size: 1.5em;
}

@media (max-width: 768px) {
  .progress-preview-container {
    padding: 15px;
    margin-top: 20px;
  }
  
  .progress-preview-title {
    font-size: 1.1em;
  }
  
  .progress-preview-description {
    font-size: 0.85em;
  }
}