.travel-feature-section {
    background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
    border-radius: 20px;
    padding: 30px;
    margin: 20px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .travel-feature-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
  
  .travel-feature-link {
    text-decoration: none;
    color: white;
    display: block;
  }
  
  .travel-feature-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .travel-feature-title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .travel-feature-title {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .travel-feature-beta {
    background: rgba(255, 255, 255, 0.15);
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 0.7rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 1px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 10px;
  }
  
  .travel-feature-icon {
    width: 100px;
    height: auto;
    flex-shrink: 0;
  }
  
  .travel-feature-description {
    font-size: 1.2rem;
    margin-top: 15px;
    color: #e0e0e0;
  }
  
  @media (max-width: 768px) {
    .travel-feature-title {
      font-size: 2rem;
    }
  
    .travel-feature-icon {
      width: 80px;
    }
  
    .travel-feature-description {
      font-size: 1rem;
    }
  }