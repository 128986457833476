.context-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.context-modal {
  background: linear-gradient(135deg, #2a2a4a 0%, #1b1b2f 100%);
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.context-suggestions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin: 15px 0;
}

.context-button {
  background: rgba(108, 99, 255, 0.15);
  border: 1px solid rgba(108, 99, 255, 0.3);
  padding: 8px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.85em;
  text-align: center;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.context-button:hover {
  background: rgba(108, 99, 255, 0.3);
  transform: translateY(-2px);
}

.custom-context {
  margin-top: 15px;
}

.custom-context input {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: white;
  margin-bottom: 8px;
  font-size: 0.9em;
}

.submit-context, .close-button {
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  margin-top: 8px;
  font-size: 0.9em;
  transition: all 0.2s;
}

.submit-context {
  background: #6c63ff;
  color: white;
}

.close-button {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  margin-left: 8px;
}

/* Mobile Adjustments */
@media (max-width: 480px) {
  .context-modal {
    padding: 15px;
    width: 95%;
    max-height: 75vh;
  }

  .context-suggestions {
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    margin: 10px 0;
  }

  .context-button {
    padding: 6px;
    font-size: 0.75em;
    min-height: 35px;
  }

  .custom-context input {
    padding: 6px;
    font-size: 0.85em;
  }

  .submit-context, .close-button {
    padding: 6px 12px;
    font-size: 0.8em;
  }
}

/* Smaller phones */
@media (max-width: 360px) {
  .context-modal {
    padding: 12px;
  }

  .context-suggestions {
    gap: 4px;
  }

  .context-button {
    font-size: 0.7em;
    min-height: 32px;
  }
}

/* Scrollbar styling */
.context-modal::-webkit-scrollbar {
  width: 6px;
}

.context-modal::-webkit-scrollbar-track {
  background: rgba(108, 99, 255, 0.1);
  border-radius: 3px;
}

.context-modal::-webkit-scrollbar-thumb {
  background-color: #6c63ff;
  border-radius: 3px;
} 