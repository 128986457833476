.spotify-player-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  color: #ffffff;
  padding: 40px 20px;
  margin-top: 80px;
}

.spotify-auth-logo-container {
  margin-bottom: 40px;
}

.spotify-auth-logo {
  width: 200px;
  height: auto;
}

.spotify-auth-container {
  text-align: center;
  max-width: 500px;
  background: linear-gradient(145deg, #1db954, #191414);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(29, 185, 84, 0.2);
  position: relative;
}

.spotify-auth-heading {
  font-size: 2.2em;
  margin-bottom: 30px;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  letter-spacing: -0.5px;
}

.spotify-auth-message {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #ffffff;
  text-align: left;
}

.spotify-auth-message p {
  margin-bottom: 15px;
}

.spotify-auth-message ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.spotify-auth-message ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}

.spotify-auth-message ul li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #1db954;
  font-weight: bold;
}

.spotify-auth-button {
  background-color: #ffffff;
  color: #1db954;
  padding: 12px 24px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1em;
  transition: all 0.3s ease;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.spotify-auth-button:hover {
  background-color: #1db954;
  color: #ffffff;
  box-shadow: 0 5px 15px rgba(29, 185, 84, 0.4);
  transform: translateY(-3px);
}

.spotify-player-container {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spotify-player-widget {
  width: 100%;
  background-color: #282828;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spotify-player-album-art {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.spotify-player-track-info {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.spotify-player-track-name {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
}

.spotify-player-artist-name {
  font-size: 0.8em;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b3b3b3;
}

.spotify-player-controls {
  display: flex;
  align-items: center;
}

.spotify-player-button {
  background: none;
  border: none;
  color: #b3b3b3;
  font-size: 18px;
  margin: 0 8px;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.spotify-player-button:hover {
  color: #1db954;
  transform: scale(1.1);
}

.spotify-player-button-play-pause {
  font-size: 24px;
  color: #1db954;
}

.spotify-player-playlist-selector {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

.spotify-player-playlist-label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.spotify-player-playlist-select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #3e3e3e;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
}

.spotify-player-playlist-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #1db954;
}

.spotify-player-reauthenticate {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1db954;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.spotify-player-reauthenticate:hover {
  background-color: #1ed760;
}

.spotify-note {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
}

.spotify-x {
  font-weight: bold;
  margin: 0 5px;
  color: #1DB954; /* Spotify green color */
}

/* Add this new class for the bottom message */
.spotify-player-bottom-message {
  margin-top: 20px;
  font-size: 14px;
  color: #b3b3b3;
  text-align: center;
}

.spotify-player-connection-message {
  background-color: #1db954;
  color: white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
}

.spotify-player-connection-message p {
  margin: 5px 0;
}
