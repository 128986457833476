.register-container {
  display: flex;
  position: fixed; /* Make the container fixed to the viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Use full viewport width */
  height: 100vh; /* Use full viewport height */
  background-color: #0f0e17;
}

.register-left {
  flex: 0.3; /* Take 30% of the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-right: 3px solid #777; /* Thin gray border */
  padding: 20px;
  position: relative; /* Position relative for pseudo-element */
  overflow: hidden;
  background-color: #000;
  border-right: 3px solid #777;
  padding: 0;
}

.register-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay, adjust opacity as needed */
  z-index: 1; /* Ensure it's on top */
  pointer-events: none; /* Allow interaction with underlying elements */
}

.register-left-content {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.register-logo {
  position: absolute; /* Position it absolutely */
  top: 20px; /* 20px from the top */
  right: 20px; /* 20px from the right */
  max-width: 150px;
  cursor: pointer !important; /* Force the cursor style to apply */
}

.register-right {
  flex: 0.7; /* Take 70% of the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #ffffff; /* White text */
}

.register-title {
  margin-bottom: 30px;
  font-size: 28px;
  color: #ffffff; /* White text */
  text-align: center;
}

.register-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.register-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.register-label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  color: #bbbbbb; /* Lighter gray for labels */
}

.register-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #333;
  font-size: 14px;
  background-color: #1e1e1e; /* Dark background for input fields */
  color: #ffffff;
}

.register-button {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(45deg, #ff6b6b, #f06595, #cc5de8);
  background-size: 200% 200%;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: background-position 0.5s, box-shadow 0.3s, transform 0.3s;
}

.register-button:hover {
  background-position: 100% 0;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  transform: translateY(-5px);
}

.register-or-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: 14px;
  color: #bbbbbb;
  width: 100%;
  position: relative; /* Ensure the lines are contained within the parent */
}

.register-or-divider::before,
.register-or-divider::after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: #444; /* Dark gray line */
  margin: 0 10px;
}

.register-or-divider span {
  white-space: nowrap; /* Prevent line break */
  padding: 0 10px;
  color: #bbbbbb; /* Text color */
}


.register-oauth-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.register-oauth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #333;
  background-color: #1e1e1e;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #ffffff;
}

.register-oauth-icon {
  margin-right: 10px;
  height: 20px;
}

.register-oauth-button:hover {
  background-color: #38385a;
}

/* Styling for Selected Languages */
.register-languages-title {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}

.register-languages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.register-language-item {
  text-align: center;
}

.register-language-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.register-get-started-button {
  margin-top: 10px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff; /* White text */
  background-color: #6c63ff; /* Purple background by default */
  border: 2px solid #6c63ff; /* Purple border */
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2); /* Light purple shadow */
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
}

.register-get-started-button:hover {
  background-color: rgba(15, 14, 23, 0.8); /* Transparent dark background on hover */
  color: #ffffff; /* White text on hover */
  border-color: #ffffff; /* White border on hover */
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4); /* Darker shadow on hover */
  transform: translateY(-3px);
}

.register-get-started-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(15, 14, 23, 0.2); /* Darker shadow on active */
}

.register-signin-link {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9em;
}

.register-signin-link-text {
  font-weight: bold;
  color: #6c63ff; /* Light purple color */
  text-decoration: none;
}

.register-signin-link-text:hover {
  text-decoration: underline;
}


.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  top: -35px; /* Adjust as necessary */
  left: 0;
  z-index: 1000;
  white-space: nowrap;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: -10px; /* Adjust as necessary */
  left: 10px; /* Adjust as necessary */
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.register-form-group {
  position: relative; /* Ensure tooltips are positioned relative to their input */
}

.error-text {
  display: none; /* Hide error text that would be shown below the input */
}

/* Add these mobile styles at the end of your existing CSS */

@media (max-width: 768px) {
  .register-container {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    position: relative;
  }

  /* Hide the left section with background image on mobile */
  .register-left {
    display: none; /* Hide completely on mobile */
  }

  .register-right {
    flex: 1;
    padding: 20px;
    width: 100%;
    margin-top: 0; /* Remove any top margin since left section is hidden */
  }

  /* Adjust logo positioning since it's now in the right section */
  .register-logo {
    position: static; /* Remove absolute positioning */
    display: block;
    margin: 0 auto 20px;
    max-width: 100px;
  }

  .register-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .register-form {
    max-width: 100%;
    gap: 15px;
  }

  .register-input {
    padding: 12px;
    font-size: 16px; /* Larger for better mobile touch */
  }

  .register-button {
    padding: 12px 24px;
    font-size: 16px;
  }

  .register-languages {
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px;
  }

  .register-language-item {
    flex: 0 0 calc(33.33% - 10px); /* 3 items per row */
  }

  .register-language-img {
    width: 40px;
    height: 40px;
  }

  .register-oauth-buttons {
    width: 100%;
  }

  .register-oauth-button {
    padding: 12px;
    font-size: 14px;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .register-right {
    padding: 15px;
  }

  .register-logo {
    max-width: 80px;
  }

  .register-title {
    font-size: 20px;
  }

  .register-form {
    gap: 12px;
  }

  .register-label {
    font-size: 13px;
  }

  .register-input {
    padding: 10px;
    font-size: 14px;
  }

  .register-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .register-language-item {
    flex: 0 0 calc(50% - 10px); /* 2 items per row on very small screens */
  }

  .register-language-img {
    width: 35px;
    height: 35px;
  }

  .register-or-divider {
    font-size: 12px;
  }

  .register-oauth-button {
    padding: 10px;
    font-size: 13px;
  }

  .register-signin-link {
    font-size: 0.8em;
  }

  /* Adjust tooltip positioning for mobile */
  .tooltip {
    font-size: 0.75rem;
    padding: 4px 8px;
    top: -30px;
  }
}

/* Fix for iOS Safari 100vh issue */
@supports (-webkit-touch-callout: none) {
  .register-container {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    position: relative;
  }

  .register-left {
    flex: none;
    height: 150px; /* Reduced height for mobile */
    width: 100%;
    border-right: none;
    border-bottom: 2px solid #777;
  }

  .register-logo {
    max-width: 100px;
    top: 10px;
    right: 10px;
  }

  .register-right {
    flex: none;
    padding: 20px;
    width: 100%;
  }

  .register-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .register-form {
    max-width: 100%;
    gap: 15px;
  }

  .register-input {
    padding: 12px;
    font-size: 16px; /* Larger for better mobile touch */
  }

  .register-button {
    padding: 12px 24px;
    font-size: 16px;
  }

  .register-languages {
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px;
  }

  .register-language-item {
    flex: 0 0 calc(33.33% - 10px); /* 3 items per row */
  }

  .register-language-img {
    width: 40px;
    height: 40px;
  }

  .register-oauth-buttons {
    width: 100%;
  }

  .register-oauth-button {
    padding: 12px;
    font-size: 14px;
  }
}

.video-slider {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 200%;
  height: 100%;
  transition: transform 1.5s ease-in-out;
}

.video-slide {
  flex: 0 0 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.sign-in-hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
}
