.spotify-section {
  background: linear-gradient(135deg, #1DB954, #191414);
  border-radius: 20px;
  padding: 30px;
  margin: 20px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.spotify-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.spotify-link {
  text-decoration: none;
  color: white;
  display: block;
}

.spotify-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spotify-title {
  display: flex;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}

.speakeasy-text {
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.x-text {
  font-size: 1.5rem; /* Larger font size */
  color: #ffffff; /* Black color */
  margin: 0 10px; /* Increased spacing */
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5); /* Light text shadow for contrast */
  transform: scaleY(1.2); /* Slightly stretch vertically */
  display: inline-block;
}

.spotify-text-logo {
  height: 70px; /* Adjust this value to match your desired size */
  width: auto;
  margin-left: 0px;
}

.spotify-logo {
  width: 80px;
  height: auto;
}

.spotify-description {
  font-size: 1.2rem;
  margin-top: 15px;
  color: #e0e0e0;
}

@media (max-width: 768px) {
  .spotify-title {
    font-size: 22px;
  }

  .x-text {
    font-size: 20px;
    margin: 0 5px;
  }

  .spotify-text-logo {
    height: 20px;
  }

  .spotify-logo {
    width: 40px;
  }

  .spotify-description {
    font-size: 1rem;
  }
}
