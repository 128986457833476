.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.create-thread-modal {
  background: #1b1b2f;
  border-radius: 12px;
  width: 90%;
  max-width: 550px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 0;
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: #1b1b2f;
  z-index: 1;
}

.modal-header h2 {
  margin: 0;
  color: #ffffff;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  color: #888;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
}

.form-section {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.section-label {
  color: #ffffff;
  font-size: 1.1rem;
  margin-bottom: 12px;
}

.location-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.location-input-wrapper input {
  flex: 1;
  padding: 12px;
  background: #0f0e17;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #ffffff;
}

.emoji-picker-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background: rgba(108, 99, 255, 0.2);
  border: 1px solid rgba(108, 99, 255, 0.3);
  border-radius: 20px;
  padding: 6px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.emoji-picker-button:hover {
  background: rgba(108, 99, 255, 0.3);
  transform: translateY(-1px);
}

.flag-icon {
  font-size: 1.2rem;
}

.flag-text {
  font-size: 0.9rem;
  color: #ffffff;
}

.emoji-picker-container {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 5px;
  z-index: 1000;
  max-width: 100%;
  overflow: hidden;
}

/* Dark theme overrides for emoji-mart */
.emoji-mart {
  background: #1b1b2f !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.popular-locations {
  margin-top: 12px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.suggestion {
  background: rgba(108, 99, 255, 0.1);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.suggestion:hover {
  background: rgba(108, 99, 255, 0.2);
  transform: translateY(-1px);
}

.suggestion.selected {
  background: rgba(108, 99, 255, 0.2);
  border-color: #6c63ff;
}

.input-tip {
  color: #888;
  font-size: 0.9rem;
  margin-top: 8px;
}

.form-section textarea {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 15px;
  font-size: 1rem;
  min-height: 200px;
  line-height: 1.6;
  background: #0f0e17;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #ffffff;
  resize: vertical;
  overflow-x: hidden;
}

.form-actions {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

/* Inherit existing styles for inputs, textareas, and buttons */
.form-section input[type="text"] {
  width: 100%;
  padding: 12px 15px;
  font-size: 1rem;
  height: 45px;
  box-sizing: border-box;
  background: #0f0e17;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #ffffff;
}

.form-section input[type="text"]:focus,
.form-section textarea:focus {
  outline: none;
  border-color: #6c63ff;
  box-shadow: 0 0 0 2px rgba(108, 99, 255, 0.2);
}

/* Add/Update these styles */
.category-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 10px;
}

.category-option {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 15px;
  background: rgba(15, 14, 23, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.category-option:hover {
  background: rgba(108, 99, 255, 0.1);
  border-color: rgba(108, 99, 255, 0.3);
}

.category-option.selected {
  background: rgba(108, 99, 255, 0.15);
  border-color: #6c63ff;
}

.category-icon {
  font-size: 1.5rem;
  line-height: 1;
}

.category-info {
  flex: 1;
}

.category-info h4 {
  margin: 0 0 5px 0;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}

.category-info p {
  margin: 0;
  color: #8a8a9a;
  font-size: 0.85rem;
  line-height: 1.4;
}

/* Update the form action buttons */
.form-actions {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.cancel-btn {
  padding: 10px 24px;
  font-size: 0.95rem;
  font-weight: 600;
  color: #8a8a9a;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.submit-btn {
  padding: 10px 24px;
  font-size: 0.95rem;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(135deg, #ff6b6b 0%, #6c63ff 100%);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
}

/* Update media query for mobile */
@media screen and (max-width: 768px) {
  .category-options {
    grid-template-columns: 1fr;
  }
  
  .form-actions {
    flex-direction: column-reverse;
  }
  
  .cancel-btn, .submit-btn {
    width: 100%;
  }
}