.connect-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.remote-video {
  object-fit: contain;
  background-color: #000;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.friend-request-sent {
  font-size: 0.9rem;
  color: #666;
  margin: 5px 0;
  padding: 4px 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.is-friend {
  font-size: 0.9rem;
  color: #4caf50;
  margin: 5px 0;
  padding: 4px 8px;
  background-color: #e8f5e9;
  border-radius: 4px;
}

.friend-request-button-wrapper {
  position: absolute;
  right: 10px;
  top: 100%;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.send-friend-request-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
}

.send-friend-request-button:hover {
  background-color: #45a049;
}
