/* Language Page */
.language-page {
  text-align: center;
  background-color: #0f0e17;
  color: #eaeaea;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  position: relative; /* Ensure the phrases can be absolutely positioned */
  min-height: 100vh; /* Ensure the page takes the full viewport height */
  padding: 0; /* Remove padding to ensure full width */
  margin: 0; /* Remove margin to ensure full width */
}

.cover-image {
  width: 100vw;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  margin-top: 50px;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}
.flag-container {
  margin: 20px 0;
}

.language-flag {
  width: 80px;
  height: 80px;
  border-radius: 50%; /* Make the image round */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a slight shadow */
}

.level-selection {
  margin-top: 30px;
}

.level-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.level-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #6c63ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.level-button:hover {
  background-color: #4a47a3;
  transform: translateY(-2px);
}

.level-button.selected {
  background-color: #ff6b6b;
}

.level-info {
  margin-top: 20px;
}

.level-info a {
  color: #61dafb;
  text-decoration: underline;
  font-size: 18px;
}

.level-info a:hover {
  color: #4a47a3;
}

.connect-section {
  margin-top: 30px;
}

.connect-button,
.chat-button {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  margin: 15px; /* Add margin between buttons */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.chat-button {
  background-color: #28a745;
}

.chat-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.connect-button {
  background-color: #007bff;
}

.connect-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.minigame-section {
  margin-top: 40px;
  text-align: center;
  padding: 0 20px;
}

.minigame-header {
  font-size: 2em;
  margin-bottom: 30px;
  color: #ffffff;
}

.minigame-card {
  background-color: #1b1b2f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  padding: 20px;
  display: grid;
  grid-template-columns: 90px 1fr;
  align-items: center;
  width: 100%;
  max-width: 380px;
  cursor: pointer;
  transition: transform 0.3s;
  margin: 20px auto;
  height: 80px;
  position: relative;
}

.minigame-card:hover {
  transform: scale(1.05);
}

.minigame-image {
  width: 90px;
  height: 90px;
}

.minigame-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.minigame-info {
  display: grid;
  grid-template-columns: 1fr;
}

.minigame-name {
  font-size: 1.1em;
  color: #ffffff;
  white-space: normal; /* Allow text to wrap */
  translate: -13px;
  line-height: 1.2;
}

.games-played-text {
  font-size: 0.9em;
  color: #bbb;
  margin-left: 0; /* Remove any margin */
  grid-column: 1;
  translate: -13px;
}

.minigame-stats {
  font-size: 0.9em;
  color: #bbb;
  margin-top: 5px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.back-button {
  position: absolute;
  top: 70px;
  left: 45px;
  background-color: #61dafb;
  color: #0f0e17;
  border: none;
  border-radius: 50%;
  font-size: 1.5em;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  translate: 100px;
}

.back-button:hover {
  transform: scale(1.05);
}

.minigame-card.coming-soon {
  position: relative;
  cursor: default;
  opacity: 0.7;
}

.coming-soon-banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  background-color: #ff6b6b;
  color: white;
  padding: 5px 20px;
  font-size: 1.2em;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.wordpacks-section {
  margin: 40px auto;
  padding: 0 20px;
  max-width: 1200px;
  position: relative; /* For absolute positioning of past-packs */
}

.wordpack-container {
  margin: 0 auto; /* Center the word pack */
  max-width: 380px;
}

.wordpacks-header {
  font-size: 32px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.wordpack-card {
  background: linear-gradient(135deg, #2a2a4a 0%, #1b1b2f 100%);
  border-radius: 15px;
  padding: 25px;
  margin: 20px auto;
  max-width: 380px;
  position: relative;
  overflow: hidden;
}

.pack-shine {
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  animation: shine 3s infinite;
}

@keyframes shine {
  to {
    left: 200%;
  }
}

.wordpack-image {
  width: 130px;
  height: 130px;
  margin: 0 auto 20px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  box-shadow: 0 6px 15px rgba(108, 99, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.wordpack-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(108, 99, 255, 0.4);
}

.wordpack-image img {
  width: 160%;
  height: 160%;
  object-fit: cover;
  transition: transform 0.5s ease;
  transform-origin: center;
  translate: -40px -40px;
}



.wordpack-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 15px;
}

.wordpack-description {
  color: #bbb;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.open-pack-button {
  background: linear-gradient(135deg, #6c63ff 0%, #4a47a3 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 1.1em;
  cursor: pointer;
  transition: transform 0.2s;
  width: 100%;
}

.open-pack-button:hover {
  transform: scale(1.05);
}

.open-pack-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.past-packs-section {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  background: rgba(42, 42, 74, 0.9);
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  max-height: 230px;
  display: flex;
  flex-direction: column;
}

.past-packs-header {
  font-size: 0.9em;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  flex-shrink: 0;
}

.past-packs-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  flex-grow: 1;
  scrollbar-width: thin;
  scrollbar-color: rgba(108, 99, 255, 0.5) transparent;
  padding-right: 5px;
}

.past-pack-card {
  background: rgba(108, 99, 255, 0.1);
  border: 1px solid rgba(108, 99, 255, 0.2);
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.8em;
}

.past-pack-card:hover {
  transform: translateX(-5px);
  background: rgba(108, 99, 255, 0.2);
  border-color: rgba(108, 99, 255, 0.4);
}

.past-pack-date {
  color: #a0a0a0;
  font-size: 0.9em;
}

.past-pack-level {
  color: #6c63ff;
  font-weight: bold;
  margin-top: 4px;
}

.past-pack-context {
  color: #6c63ff;
  font-size: 0.8em;
  margin-top: 4px;
  opacity: 0.8;
}

.past-packs-container::-webkit-scrollbar {
  width: 6px;
}

.past-packs-container::-webkit-scrollbar-track {
  background: transparent;
}

.past-packs-container::-webkit-scrollbar-thumb {
  background-color: rgba(108, 99, 255, 0.5);
  border-radius: 3px;
}

.past-packs-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(108, 99, 255, 0.7);
}

@media (max-width: 768px) {
  .past-packs-section {
    position: static;
    transform: none;
    margin: 20px auto;
    max-width: 380px;
    width: 100%;
    max-height: 230px;
  }

  .language-page {
    padding-bottom: 20px;
  }

  .wordpacks-section {
    flex-direction: column;
    align-items: center;
  }

  .past-packs-section {
    width: 100%;
    margin: 20px auto;
    max-width: 380px;
  }

  .wordpacks-section {
    gap: 20px;
  }

  .wordpack-container {
    margin-right: 0;
  }

  .cover-image {
    height: 200px; /* Further reduce height for mobile */
    margin-top: 30px;
  }

  .language-flag {
    width: 60px; /* Reduced from 80px */
    height: 60px;
  }

  .wordpack-card {
    padding: 20px; /* Reduced from 25px */
    margin: 15px auto;
  }

  .wordpack-image {
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    position: relative;
    overflow: hidden;
  }

  .wordpack-image img {
    width: 140%;
    height: 140%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    translate: 0 0;
  }

  .wordpack-title {
    font-size: 20px; /* Reduced from 24px */
  }

  .wordpack-description {
    font-size: 14px; /* Reduced from 16px */
  }

  .minigame-card {
    padding: 15px;
    margin: 10px 0;
  }

  .minigame-image {
    width: 60px; /* Adjust minigame image size */
    height: 60px;
  }

  .minigame-name {
    font-size: 16px;
  }

  .games-played-text {
    font-size: 0.8em;
  }

  .coming-soon-banner {
    font-size: 1em;
    padding: 4px 15px;
  }

  .wordpacks-header {
    font-size: 24px; /* Reduced from 32px */
    margin-bottom: 20px;
  }

  .back-button {
    top: 60px;
    left: 20px;
    width: 40px;
    height: 40px;
    font-size: 1.2em;
    translate: 0;
  }

  .level-buttons {
    flex-wrap: wrap;
    gap: 8px;
  }

  .level-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .cover-image {
    height: 200px;
    margin-top: 30px;
  }

  .minigame-section {
    padding: 0 15px;
  }

  .minigame-card {
    margin: 25px auto;
    height: 70px;
  }

  .minigame-header {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .minigame-card {
    grid-template-columns: 70px 1fr;
    padding: 15px;
    height: auto;
    min-height: 70px;
  }

  .minigame-name {
    font-size: 1em;
    translate: -8px;
  }

  .games-played-text {
    font-size: 0.8em;
    translate: -8px;
  }

  /* Remove padding for both home and language pages */
  body.home-page,
  body.language-page {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* Ensure the language page content fills the width */
  .language-page {
    width: 100vw;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  /* Adjust any direct children that might need padding */
  .language-page > * {
    padding-left: 15px;
    padding-right: 15px;
  }

  /* Update container styles */
  .minigame-section,
  .wordpacks-section {
    width: calc(100% - 30px); /* Account for padding */
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  /* Center the cards */
  .minigame-card,
  .wordpack-card {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    max-width: 350px; /* Slightly reduced from 380px to account for padding */
  }

  /* Ensure container doesn't add extra padding */
  .wordpack-container {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  /* Remove conflicting padding */
  .language-page > *:not(.cover-image) {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Add styles for even smaller screens */
@media (max-width: 480px) {
  .cover-image {
    height: 150px;
    margin-top: 20px;
  }

  .language-flag {
    width: 50px;
    height: 50px;
  }

  .wordpack-image {
    width: 80px;
    height: 80px;
  }

  .wordpacks-header {
    font-size: 20px; /* Reduced from 32px */
    margin-bottom: 15px;
  }

  .minigame-card {
    padding: 12px;
  }

  .minigame-image {
    width: 50px;
    height: 50px;
  }

  .back-button {
    width: 35px;
    height: 35px;
    font-size: 1em;
  }

  .cover-image {
    height: 150px;
    margin-top: 20px;
  }

  .minigame-section {
    padding: 0 10px;
  }

  .minigame-card {
    margin: 20px auto;
    height: 80px;
    padding: 12px;
    
  }

  .minigame-header {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .minigame-card {
    grid-template-columns: 60px 1fr;
    padding: 12px;
    min-height: 60px;
  }

  .minigame-name {
    font-size: 0.9em;
    translate: -5px;
  }

  .minigame-section,
  .wordpacks-section {
    width: calc(100% - 20px); /* Slightly smaller padding for smaller screens */
  }

  .minigame-card,
  .wordpack-card {
    width: calc(100% - 20px);
  }
}


@media (max-width: 768px) {
  body.language-page-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .language-page {
    width: 100vw;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  /* Add padding only to specific content sections that need it */
  .language-page > *:not(.cover-image) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.level-button-container {
  position: relative;
  display: inline-block;
}

.level-tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  text-align: center;
  padding: 8px 12px;
  border-radius: 6px;
  width: 200px;
  font-size: 14px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

.level-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
}

.level-button-container:hover .level-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Update media query for mobile */
@media (max-width: 768px) {
  .level-tooltip {
    width: 150px;
    font-size: 12px;
    bottom: 130%;
  }
  
  .level-buttons {
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.level-helper-text {
  color: #8a8a8a;
  font-size: 0.9em;
  margin: 10px 0;
  font-style: italic;
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update for mobile */
@media (max-width: 768px) {
  .level-helper-text {
    font-size: 0.8em;
    margin: 8px 0;
  }
}

.conversation-section {
  margin: 60px auto;
  max-width: 800px;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.conversation-header {
  font-size: 32px;
  margin-bottom: 30px;
  text-align: center;
}

.conversation-card {
  background: linear-gradient(135deg, #2a2a4a 0%, #1b1b2f 100%);
  border-radius: 15px;
  padding: 25px;
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin: 20px auto;
  max-width: 600px;
}

.conversation-card:hover {
  transform: scale(1.02);
}

.conversation-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.conversation-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.conversation-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.conversation-name {
  font-size: 1.4em;
  color: #ffffff;
  font-weight: bold;
}

.conversation-description {
  color: #bbb;
  font-size: 0.9em;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .conversation-card {
    grid-template-columns: 70px 1fr;
    padding: 15px;
    gap: 15px;
  }

  .conversation-image {
    width: 70px;
    height: 70px;
  }

  .conversation-name {
    font-size: 1.2em;
  }
}

/* Add new Comprehensible Input section styles */
.comprehensible-input-section {
  margin: 40px auto;
  padding: 0 20px;
}

.comprehensible-input-header {
  text-align: center;
  margin-bottom: 10px;
  color: white;
  font-size: 1.8em;
}

.comprehensible-input-description {
  text-align: center;
  color: #a0a0a0;
  margin-bottom: 20px;
  font-size: 1.1em;
}

.comprehensible-input-container {
  margin: 30px auto;
  max-width: 800px;
  padding: 0 20px;
}

.comprehensible-input-video-container {
  position: relative;
  width: 100%;
  height: 450px; /* Set fixed height */
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.comprehensible-input-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will maintain aspect ratio */
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.comprehensible-input-watch-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(108, 99, 255, 0.9);
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 500;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.comprehensible-input-watch-button:hover {
  background: rgba(108, 99, 255, 1);
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 4px 15px rgba(108, 99, 255, 0.3);
}

@media (max-width: 768px) {
  .comprehensible-input-video-container {
    height: 250px; /* Smaller height for mobile */
  }
  
  .comprehensible-input-header {
    font-size: 1.5em;
  }

  .comprehensible-input-description {
    font-size: 1em;
  }
  
  .comprehensible-input-watch-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
.comprehensible-input-nav-arrows {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  transform: translateY(-50%);
  z-index: 2;
}

.comprehensible-input-nav-button {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2em;
  opacity: 0;
}

.comprehensible-input-video-container:hover .comprehensible-input-nav-button {
  opacity: 1;
}

.comprehensible-input-nav-button:hover {
  background: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.5);
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .comprehensible-input-nav-button {
    width: 35px;
    height: 35px;
    font-size: 1em;
  }
  
  .comprehensible-input-nav-arrows {
    padding: 0 15px;
  }
}

/* Add these new styles for the multiplayer tag */
.multiplayer-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(108, 99, 255, 0.9);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 2;
}

.multiplayer-tag i {
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .multiplayer-tag {
    font-size: 0.6em;
    padding: 3px 6px;
    top: 8px;
    right: 8px;
  }
}