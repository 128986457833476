/* Floating Feedback Button */
.feedback-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in;
}

.feedback-button:hover {
  background-color: rgba(15, 14, 23, 0.8);
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

/* Mobile Feedback Button */
@media screen and (max-width: 768px) {
  .feedback-button {
    bottom: 80px;
    right: 15px;
    padding: 8px 15px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
    opacity: 0.9;
    transform: none;
    min-width: auto;
  }

  .feedback-button i {
    font-size: 16px;
  }

  .feedback-button .button-text {
    display: inline;
  }

  .feedback-button:active {
    transform: scale(0.95);
  }
}

/* Modal Styles */
.feedback-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(100%);
  width: 320px;
  background-color: #1a1a2e;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
  color: #ffffff;
  opacity: 0;
  transition: all 0.5s ease-out;
}

.feedback-modal.open {
  transform: translate(-50%, -50%) translateY(0);
  opacity: 1;
}

.feedback-content {
  padding: 20px;
  text-align: center;
}

/* Form Styles */
.form-group {
  margin-bottom: 12px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 4px;
  color: #cfcfcf;
  font-size: 0.9em;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px;
  background-color: #2a2a4a;
  border: 1px solid #2a2a4a;
  border-radius: 5px;
  color: #ffffff;
  font-size: 13px;
}

.submit-button {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  border-radius: 50px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover:not(:disabled) {
  background-color: rgba(15, 14, 23, 0.8);
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Overlay and Messages */
.feedback-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 1000;
}

.feedback-description {
  font-size: 12px;
  margin-bottom: 15px;
  color: #cfcfcf;
}

.feedback-error {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 12px;
  color: #ff6b6b;
  font-size: 0.9em;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}