/* Add this at the top of your Travel.css file */


.travel-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: #0f0e17;
  color: #eaeaea;
  padding: 0;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

.travel-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.travel-header h1 {
  font-size: 3.6em;
  margin: 0;
  color: #ffffff;
  font-weight: 800;
  letter-spacing: -0.5px;
  text-shadow: none;
  background: linear-gradient(135deg, #ffffff 0%, #e0e0e0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.travel-beta-badge {
  background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: absolute;
  top: -10px;
  right: calc(50% - 80px);
}

.travel-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  max-width: 1360px;
  margin: 0 auto 30px;
  padding: 0 20px;
}

.section-header h2 {
  font-size: 2.4rem;
  color: #ffffff;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin: 0;
  text-shadow: 2px 2px 15px rgba(255, 255, 255, 0.15);
}

.create-thread-btn {
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 15px rgba(255, 107, 107, 0.2);
}

.create-thread-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(255, 107, 107, 0.4);
}

.thread-container {
  position: relative;
  padding: 10px 0;
  overflow: hidden;
  min-height: 500px;
  width: 100%;
  margin: 0 auto;
}

.thread-grid {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px;
  max-width: none;
}

.thread-card {
  position: absolute;
  width: 400px;
  left: 50%;
  background: #1b1b2f;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid rgba(78, 205, 196, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.thread-card:not(.active) {
  pointer-events: none;
  opacity: 0.6;
}

.thread-card.before {
  transform: translateX(-120%) scale(0.9);
  opacity: 0.4;
  z-index: 0;
  visibility: visible;
}

.thread-card.after {
  transform: translateX(20%) scale(0.9);
  opacity: 0.6;
  z-index: 1;
  visibility: visible;
}

.thread-card.active {
  transform: translateX(-50%);
  opacity: 1;
  z-index: 2;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

.thread-card.active:hover {
  transform: translateX(-50%) translateY(-5px);
}

.thread-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, #4ECDC4, transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thread-card:hover::before {
  opacity: 1;
}

.thread-card-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.country-flag {
  font-size: 1.5rem;
}

.category-tag {
  background: rgba(108, 99, 255, 0.1);
  color: #6c63ff;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.thread-title {
  font-size: 1rem;
  color: #ffffff;
  margin: 0 0 10px 0;
  line-height: 1.4;
  font-weight: 600;
  letter-spacing: -0.3px;
}

.thread-preview {
  color: #8a8a9a;
  font-size: 0.85rem;
  line-height: 1.5;
  margin-bottom: 15px;
  flex-grow: 1;
}

.thread-footer {
  margin-top: auto;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.user-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.user-info img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #FF6B6B;
}

.user-info span {
  font-size: 0.85rem;
  color: #ffffff;
  font-weight: 500;
}

.thread-stats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.stat {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #8a8a9a;
  font-size: 0.8rem;
  padding: 4px 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.stat:hover {
  background: rgba(255, 107, 107, 0.1);
  color: #FF6B6B;
}

.thread-time {
  font-size: 0.75rem;
  color: #666;
  margin-top: 8px;
  display: block;
}

/* Category-specific colors */
.category-tag.question { background: rgba(255, 107, 107, 0.1); color: #FF6B6B; }
.category-tag.recommendation { background: rgba(78, 205, 196, 0.1); color: #4ECDC4; }
.category-tag.guide { background: rgba(255, 107, 107, 0.1); color: #FF6B6B; }
.category-tag.tip { background: rgba(78, 205, 196, 0.1); color: #4ECDC4; }

.thread-navigation {
  position: absolute;
  bottom: 120px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 3;
}

.nav-button {
  background: rgba(78, 205, 196, 0.1);
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #4ECDC4;
  font-size: 1.2rem;
}

.nav-button:hover {
  background: rgba(78, 205, 196, 0.2);
  transform: translateY(-2px);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .travel-page {
    padding: 100px 10px 20px;
  }

  .travel-header h1 {
    font-size: 2.5em;
  }

  .section-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .thread-card {
    flex: 0 0 90%;
    max-width: 90%;
  }
}

.thread-card.before.adjacent {
  visibility: visible;
}

.thread-card.after.adjacent {
  visibility: visible;
}

.travel-hero {
  position: relative;
  height: 70vh;
  width: 100vw;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #000;
  left: 50%;
  transform: translateX(-50%);
}

.video-slider {
  display: flex;
  width: 300%;
  height: 100%;
  transition: transform 1.5s ease-in-out;
}

.video-slide {
  flex: 0 0 33.333%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.travel-hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
}

.travel-hero-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
}

.travel-hero-content h1 {
  font-size: 4rem;
  background: linear-gradient(135deg, #ffffff 0%, #e0e0e0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

.travel-hero-btn {
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 15px 40px;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.travel-hero-btn:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .travel-hero-content h1 {
    font-size: 2.5rem;
  }
  
  .travel-hero-btn {
    padding: 12px 30px;
    font-size: 1rem;
  }

  .travel-hero {
    height: 80vh;
    width: 100%;
    left: 0;
    transform: none;
  }

  .video-slider {
    width: 100%;
    transform: none !important;
  }

  .video-slide {
    display: none;
    width: 100%;
    flex: none;
  }

  /* Only show the active video */
  .video-slide:first-child {
    display: block;
  }

  .travel-hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.stat.liked {
  color: #FF6B6B;
  background: rgba(255, 107, 107, 0.1);
}

/* Add these new styles */

.country-filter {
  width: 300px;
  margin: 10px auto 20px;
  padding: 0;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.country-select .country-select__control {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 8px 12px;
  min-height: 42px;
  width: 300px;
}

.country-select .country-select__single-value {
  color: #ffffff;
}

.country-select .country-select__menu {
  width: 300px;
  background: #1b1b2f;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.country-select .country-select__option {
  color: #ffffff;
  background: transparent;
}

.country-select .country-select__option:hover {
  background: rgba(255, 255, 255, 0.1);
}

.country-select {
  width: 300px;
  font-size: 1rem;
}

/* Video Grid Styles */
.video-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(200px, 1fr));
  gap: 15px;
  padding: 20px 0;
  width: 100%;
}

.video-card {
  background: #1b1b2f;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.video-card:hover {
  transform: translateY(-4px);
}

.video-thumbnail {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.video-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-duration {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.video-info {
  padding: 15px;
}

.video-info h3 {
  font-size: 1rem;
  margin: 0 0 8px;
  color: #ffffff;
  line-height: 1.4;
}

.video-channel {
  color: #8a8a9a;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.video-meta {
  display: flex;
  gap: 8px;
  color: #666;
  font-size: 0.8rem;
}

/* Responsive adjustments */
@media (max-width: 1600px) {
  .video-grid {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
  }
}

@media (max-width: 1200px) {
  .video-grid {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
}

@media (max-width: 900px) {
  .video-grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .video-grid {
    grid-template-columns: 1fr;
  }
}

/* Update these styles */
.discussions-section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thread-container {
  position: relative;
  padding: 20px 0;
  overflow: visible;
  min-height: 500px;
  width: 100%;
  margin: 0 auto;
  transform: none;
  left: auto;
}

.thread-card.before {
  transform: translateX(-120%) scale(0.9);
  opacity: 0.4;
  z-index: 0;
  visibility: visible;
}

.thread-card.after {
  transform: translateX(20%) scale(0.9);
  opacity: 0.6;
  z-index: 1;
  visibility: visible;
}

.suggested-content-section {
  margin-top: 30px;
  padding: 0 20px;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 30px;
}

.video-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.video-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.video-thumbnail {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.video-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-duration {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.video-info {
  padding: 16px;
}

.video-info h3 {
  font-size: 1rem;
  margin: 0 0 8px;
  color: #ffffff;
  line-height: 1.4;
}

.video-channel {
  color: #8a8a9a;
  font-size: 0.9rem;
  margin-bottom: 4px;
}

.video-meta {
  display: flex;
  gap: 8px;
  color: #666;
  font-size: 0.8rem;
  align-items: center;
}

.video-meta span {
  color: #8a8a9a;
}

.map-section {
  margin: 20px auto;
  padding: 20px;
  background: rgba(15, 14, 23, 0.8);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Update existing styles */
.country-filter {
  margin-top: 20px;
  width: 100%;
  max-width: 1000px;
}

/* Add new globe section styles */
.globe-section {
  opacity: 1;
  transform: translateY(0);
  text-align: center;
  padding: 20px;
  margin-top: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.globe-section-header {
  margin-bottom: 20px;
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto 20px;
}

.globe-section-header h2 {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 12px;
  color: #ffffff;
  text-shadow: 2px 2px 15px rgba(255, 255, 255, 0.15);
}

.globe-section-header p {
  font-size: 1rem;
  color: #8a8a9a;
  max-width: 500px;
  margin: 0 auto;
  line-height: 1.5;
}

/* Update existing styles for a more modern look */
.travel-header h1 {
  font-size: 3.6em;
  background: linear-gradient(135deg, #ffffff 0%, #e0e0e0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

.section-header {
  margin-bottom: 30px;
}

.section-header h2 {
  font-size: 2.4rem;
  color: #ffffff;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin: 0;
  text-shadow: 2px 2px 15px rgba(255, 255, 255, 0.15);
}

.create-thread-btn {
  transform: scale(1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.create-thread-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 25px rgba(255, 107, 107, 0.3);
}

.no-content-message {
  background: rgba(27, 27, 47, 0.6);
  border-radius: 16px;
  padding: 40px;
  text-align: center;
  margin: 20px auto;
  max-width: 600px;
  border: 1px solid rgba(108, 99, 255, 0.2);
  position: relative;
}

.message-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.message-content h3 {
  font-size: 1.5rem;
  color: #ffffff;
  margin: 0;
}

.message-content p {
  color: #8a8a9a;
  font-size: 1.1rem;
  margin: 0;
  line-height: 1.4;
}

.message-content .create-thread-btn {
  margin-top: 10px;
  background: linear-gradient(135deg, #6c63ff, #4ECDC4);
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.message-content .create-thread-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(108, 99, 255, 0.3);
}

.dotted-arrow {
  position: absolute;
  top: -60px;
  right: 50px;
  width: 40px;
  height: 50px;
  animation: floatArrow 2s ease-in-out infinite;
}

.dotted-arrow::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    #6c63ff 50%,
    transparent 50%
  );
  background-size: 2px 8px;
  background-repeat: repeat-y;
  left: 50%;
  transform: translateX(-50%);
}

.dotted-arrow::after {
  content: '▲';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  color: #6c63ff;
  font-size: 20px;
}

@keyframes floatArrow {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Tablet and smaller screens */
@media (max-width: 1024px) {
  .travel-header h1 {
    font-size: 3em;
  }

  .thread-card {
    width: 350px;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 15px;
  }

  .globe-section-header h2 {
    font-size: 1.8rem;
  }
}

/* Mobile devices */
@media (max-width: 768px) {
  .travel-page {
    padding: 20px 0;
    width: 100%;
    overflow-x: hidden;
  }

  .travel-header {
    margin-bottom: 30px;
  }

  .travel-header h1 {
    font-size: 2.2em;
  }

  .travel-beta-badge {
    font-size: 0.6rem;
    padding: 3px 10px;
    right: calc(50% - 60px);
  }

  .section-header {
    flex-direction: column;
    gap: 20px;
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .section-header h2 {
    font-size: 1.8rem;
    text-align: center;
  }

  .create-thread-btn {
    width: 100%;
    justify-content: center;
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .thread-container {
    padding: 5px 0;
    min-height: 400px;
    overflow: hidden;
  }

  .thread-card {
    width: 90%;
    max-width: 300px;
    padding: 15px;
    box-sizing: border-box;
  }

  .thread-card.before {
    transform: translateX(-110%) scale(0.85);
  }

  .thread-card.after {
    transform: translateX(10%) scale(0.85);
  }

  .thread-title {
    font-size: 0.9rem;
  }

  .thread-preview {
    font-size: 0.8rem;
  }

  .thread-navigation {
    bottom: 80px;
  }

  .nav-button {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .video-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .video-card {
    margin: 0 auto;
    max-width: 350px;
  }

  .country-filter {
    width: 100%;
    padding: 0 15px;
  }

  .country-select,
  .country-select .country-select__control {
    width: 100%;
    max-width: 300px;
  }

  .no-content-message {
    margin: 15px;
    padding: 25px;
  }

  .message-content h3 {
    font-size: 1.2rem;
  }

  .message-content p {
    font-size: 0.9rem;
  }

  .dotted-arrow {
    display: none;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .travel-header h1 {
    font-size: 1.8em;
  }

  .thread-card {
    width: 85%;
    max-width: 280px;
    padding: 12px;
  }

  .thread-card.before {
    transform: translateX(-105%) scale(0.8);
  }

  .thread-card.after {
    transform: translateX(5%) scale(0.8);
  }

  .thread-stats {
    flex-wrap: wrap;
    gap: 10px;
  }

  .stat {
    font-size: 0.75rem;
  }

  .video-thumbnail {
    padding-top: 60%; /* Slightly taller for mobile */
  }

  .globe-section-header h2 {
    font-size: 1.5rem;
  }

  .globe-section-header p {
    font-size: 0.9rem;
  }
}

/* Landscape orientation */
@media (max-height: 600px) and (orientation: landscape) {
  .thread-container {
    min-height: 350px;
  }

  .thread-navigation {
    bottom: 60px;
  }

  .travel-header {
    margin-bottom: 20px;
  }
}