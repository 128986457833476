.home-page {
  position: relative;
  min-height: 100vh;
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #0f0e17;
  color: #eaeaea;
  overflow-x: hidden;
  width: 100%;
  padding-bottom: 0;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.home-header-section {
  padding: 50px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #6c63ff;
  box-sizing: border-box;
}

.home-header-text {
  max-width: 600px;
  text-align: center;
  margin-right: 200px;
}

.home-header-text h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.home-header-text p {
  font-size: 1.2em;
  margin-bottom: 0;
  text-align: center;
}

.home-get-started-container {
  text-align: center;
  margin-top: -10px;
}

.home-get-started-container-languages-selected {
  text-align: center;
  margin-top: 10px;
}

.home-get-started-button-2 {
  margin-top: 30px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #ffffff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.home-get-started-button-2:hover {
  background-color: #ffffff;
  color: #6c63ff;
  border-color: #6c63ff;
  box-shadow: 0 15px 30px rgba(108, 99, 255, 0.4);
  transform: translateY(-3px);
}

.home-get-started-button-2:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2);
}

.getstartedwithlanguage-button {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #0f0e17;
  background-color: #ffffff;
  border: 2px solid #0f0e17;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  margin-top: 40px;
}

.getstartedwithlanguage-button:hover {
  background-color: #0f0e17;
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px);
}

.getstartedwithlanguage-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.home-header-image {
  max-width: 500px;
  width: 100%;
  margin-left: 20px;
}

.home-language-section {
  text-align: center;
  margin-top: 60px;
}

.home-languages-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  height: 150px;
}

.home-languages-navigation button {
  background-color: #1b1b2f;
  color: #61dafb;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5em;
  margin: 0 10px;
  border-radius: 50%;
  transition: background-color 0.3s;
  align-items: center;
  justify-content: center;
}

.home-languages-navigation button:hover {
  background-color: #333;
}

.home-languages-container-wrapper {
  width: 780px;
  overflow: hidden;
}

.home-languages-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.home-language-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  position: relative;
  margin: 0 20px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  padding: 5px;
}

.home-language-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.home-language-item p {
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
}

.home-language-item.selected {
  box-shadow: 0 0 15px rgba(108, 99, 255, 0.5);
  background-color: rgba(108, 99, 255, 0.1);
}

.home-checkmark {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #28a745;
  color: white;
  border-radius: 50%;
  padding: 2px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.home-language-item.selected .home-checkmark {
  opacity: 1;
}

.home-about-section {
  padding: 80px 20px;
  background: #0f0e17;
}

.home-about-header {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 60px;
  color: #ffffff;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-card {
  background: rgba(108, 99, 255, 0.05);
  border: 1px solid rgba(108, 99, 255, 0.1);
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(108, 99, 255, 0.1);
}

.feature-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  background: rgba(108, 99, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-icon img {
  width: 40px;
  height: 40px;
}

.feature-card h3 {
  color: #6c63ff;
  font-size: 1.5em;
  margin-bottom: 15px;
}

.feature-card p {
  color: #eaeaea;
  line-height: 1.6;
  font-size: 1.1em;
}

.stats-section {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-top: 80px;
  padding: 40px;
  background: rgba(108, 99, 255, 0.05);
  border-radius: 20px;
}

.stat-item {
  text-align: center;
}

.stat-number {
  display: block;
  font-size: 2.5em;
  font-weight: bold;
  color: #6c63ff;
  margin-bottom: 5px;
}

.stat-label {
  color: #eaeaea;
  font-size: 1.1em;
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }

  .stats-section {
    flex-direction: column;
    gap: 30px;
    padding: 20px;
  }

  .stat-item {
    padding: 20px;
    border-bottom: 1px solid rgba(108, 99, 255, 0.1);
  }

  .stat-item:last-child {
    border-bottom: none;
  }
}

.section-divider {
  border: none;
  border-top: 1px solid #333;
  margin: 60px 0 0;
  width: 100%;
}

.home-info-section {
  position: relative;
  width: 100%;
  padding: 20px;
  background-color: transparent;
}

.info-content {
  position: absolute;
  bottom: 20px;
  right: 40px;  /* Increased right padding */
  display: flex;
  flex-direction: column;
  align-items: center;  /* Center align the content */
  padding: 15px 20px 10px;
  background-color: rgba(15, 14, 23, 0.7);
  border-radius: 10px;
}

.info-social {
  display: flex;
  flex-direction: column;
  align-items: center;  /* Center align the social content */
}

.info-social h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #ffffff;
  text-align: center;  /* Center the text */
}

.social-icons {
  display: flex;
  justify-content: center;  /* Center the icons */
  gap: 15px;
}

.social-icons a {
  color: #ffffff;
  font-size: 1.3em;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: #6c63ff;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .info-content {
    position: static;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.sign-in-container {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: 25px;
  backdrop-filter: blur(5px);
}

.sign-in-link {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #6c63ff;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
}

.sign-in-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #6c63ff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.sign-in-link:hover {
  color: #4a45b3;
}

.sign-in-link:hover::after {
  transform: scaleX(1);
}

.language-selector {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #1b1b2f;
  color: white;
  border: 2px solid #4a47a3;
  border-radius: 20px;
  padding: 8px 35px 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto;
  min-width: 120px;
}

.language-selector:hover {
  border-color: #6c63ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236c63ff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.language-selector:focus {
  outline: none;
  border-color: #6c63ff;
  box-shadow: 0 0 0 3px rgba(108, 99, 255, 0.2);
}

.typewriter-container {
  min-height: 40px;
  margin: 15px 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 0;
  text-align: center;
}

.home-about-text {
  display: flex;
  flex-direction: column;
}

.Typewriter__wrapper {
  line-height: 1.5;
}

/* Add this to your existing CSS */
[dir="rtl"] .typewriter-container {
  direction: rtl;
  text-align: right;
}

[dir="rtl"] .Typewriter__wrapper {
  text-align: right;
}

@media (max-width: 1024px) {
  .home-header-section {
    flex-direction: column;
    padding: 30px 20px;
  }

  .home-header-text {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .home-header-text h1 {
    font-size: 2.5em;
  }

  .home-languages-container-wrapper {
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
  }

  .home-about-content {
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 60px;
  }

  .home-about-content:nth-child(even) .home-about-image,
  .home-about-content:nth-child(odd) .home-about-image,
  .home-about-content:nth-child(even) .home-about-text,
  .home-about-content:nth-child(odd) .home-about-text {
    order: unset;
    margin: 0;
  }

  .home-about-text {
    margin: 30px 0 !important;
    text-align: center;
  }

  .home-about-image {
    margin-bottom: 30px;
  }

  .home-about-text {
    margin: 0 !important;
    text-align: center;
  }

  .home-about-subheader {
    text-align: center;
  }

  .home-about-text p {
    text-align: center;
  }

  .home-about-image img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .home-header-text h1 {
    font-size: 2em;
  }

  .home-header-text p {
    font-size: 1.1em;
  }

  .home-header-image {
    max-width: 100%;
    margin: 0;
  }

  .home-get-started-button-2,
  .getstartedwithlanguage-button {
    padding: 12px 24px;
    font-size: 16px;
  }

  .home-languages-navigation button {
    font-size: 1.2em;
  }

  .home-language-item {
    margin: 0 10px;
  }

  .home-language-item img {
    width: 60px;
    height: 60px;
  }

  .home-about-header {
    font-size: 2em;
    margin-bottom: 50px;
  }

  .home-about-subheader {
    font-size: 1.6em;
  }

  .home-about-text p {
    font-size: 1.1em;
  }

  .language-selector {
    position: static;
    margin: 20px auto;
  }
}

@media (max-width: 480px) {
  .home-header-text h1 {
    font-size: 1.8em;
  }

  .home-header-text p {
    font-size: 1em;
  }

  .home-get-started-button-2,
  .getstartedwithlanguage-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .home-language-item {
    width: 80px;
    margin: 0 8px;
  }

  .home-language-item img {
    width: 50px;
    height: 50px;
  }

  .home-language-item p {
    font-size: 14px;
  }

  .home-about-header {
    font-size: 1.8em;
    margin-bottom: 40px;
  }

  .home-about-subheader {
    font-size: 1.4em;
  }

  .home-about-text p {
    font-size: 1em;
  }

  .typewriter-container {
    font-size: 1em;
    min-height: 30px;
  }

  .sign-in-container {
    top: 10px;
    right: 10px;
    padding: 8px 16px;
  }

  .sign-in-link {
    font-size: 16px;
  }

  .social-icons {
    gap: 10px;
  }

  .social-icons a {
    font-size: 1.1em;
  }
}

/* Add smooth transitions for responsive changes */
.home-header-section,
.home-about-content,
.home-language-item,
.home-header-text h1,
.home-header-text p {
  transition: all 0.3s ease-in-out;
}

/* Update header section for mobile */
@media (max-width: 768px) {
  .home-header-section {
    flex-direction: column;
    width: calc(100% + 80px); /* Account for left and right padding */
    margin-left: -40px; /* Offset the body padding */
    margin-right: -40px;
    background-color: #6c63ff;
    box-sizing: border-box;
  }

  .home-header-text {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .home-header-text h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  .home-header-text p {
    font-size: 0.9em;
  }

  .home-header-image {
    max-width: 200px;
    width: 100%;
    margin: 0;
  }

  .getstartedwithlanguage-button {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 15px;
  }

  .typewriter-container {
    min-height: 25px;
    font-size: 0.9em;
    margin: 8px 0;
  }
}

/* Even smaller phones */
@media (max-width: 480px) {
  .home-header-text h1 {
    font-size: 1.6em;
  }

  .home-header-image {
    max-width: 180px;
  }

  .typewriter-container {
    font-size: 0.8em;
  }
}

/* Hide profile icon on homepage */
.home-page .mobile-nav-profile {
  display: none;
}

@media (max-width: 768px) {
  body.home-page {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* Hide mobile nav on homepage on mobile */
@media (max-width: 768px) {
  body.home-page .mobile-nav-container,
  body.home-page .mobile-menu {
    display: none !important;
  }
}

/* Language section styles */
.home-languages-container-wrapper {
  width: 780px;
  overflow: hidden;
}

.home-languages-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* Update mobile styles for language section */
@media (max-width: 768px) {
  .home-languages-container-wrapper {
    width: 300px; /* Reduced width for mobile */
    margin: 0 auto;
  }

  .home-languages-navigation {
    height: auto;
    padding: 20px 0;
    margin-top: 30px;
  }

  .home-languages-navigation button {
    padding: 8px;
    font-size: 1.2em;
    margin: 0 5px;
  }

  .home-language-item {
    width: 80px; /* Smaller width for mobile */
    margin: 0 10px;
    padding: 3px;
  }

  .home-language-item img {
    width: 50px;
    height: 50px;
  }

  .home-language-item p {
    font-size: 12px;
    margin-top: 5px;
  }

  /* Reduce margin for about section on mobile */
  .home-about-section {
    margin-top: 100px; /* Reduced from 200px */
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .home-languages-container-wrapper {
    width: 240px; /* Even smaller width */
  }

  .home-language-item {
    width: 60px;
    margin: 0 8px;
  }

  .home-language-item img {
    width: 40px;
    height: 40px;
  }

  .home-language-item p {
    font-size: 11px;
  }

  /* Further reduce margin for about section on very small screens */
  .home-about-section {
    margin-top: 80px;
  }
}

/* Override body padding specifically for home page */
body.home-page {
  padding: 40px 0 0 0; /* 20px top, 0 right, 0 bottom, 0 left */
}

/* Mobile styles for about section */
@media (max-width: 768px) {
  .home-about-content {
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 40px; /* Reduced margin between sections */
  }

  /* Reset all order styles */
  .home-about-content:nth-child(even) .home-about-image,
  .home-about-content:nth-child(odd) .home-about-image,
  .home-about-content:nth-child(even) .home-about-text,
  .home-about-content:nth-child(odd) .home-about-text {
    order: unset;
    margin: 0;
  }

  /* Force the order of elements */
  .home-about-content {
    display: grid;
    grid-template-areas:
      "header"
      "image"
      "text";
    gap: 20px;
  }

  .home-about-subheader {
    grid-area: header;
    text-align: center;
    font-size: 1.6em;
    margin: 0;
  }

  .home-about-image {
    grid-area: image;
    margin: 0;
  }

  .home-about-image img {
    max-width: 300px; /* Smaller images on mobile */
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .home-about-text {
    grid-area: text;
    margin: 0 !important;
  }

  .home-about-text p {
    text-align: center;
    font-size: 1.1em;
    margin: 0;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .home-about-content {
    gap: 15px;
    margin-bottom: 30px;
  }

  .home-about-image img {
    max-width: 250px; /* Even smaller images on very small screens */
  }

  .home-about-subheader {
    font-size: 1.4em;
  }

  .home-about-text p {
    font-size: 1em;
  }
}

/* Mobile styles for language selector */
@media (max-width: 768px) {
  .language-selector {
    position: fixed;
    bottom: 10px;
    left: 10px;
    font-size: 12px;
    padding: 6px 25px 6px 10px;
    min-width: 100px;
    background-size: 12px;
    background-position: right 8px center;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .language-selector {
    font-size: 11px;
    padding: 5px 20px 5px 8px;
    min-width: 90px;
    background-size: 10px;
  }
}

.founder-story-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.founder-images {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.founder-image {
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.founder-image:hover {
  transform: scale(1.02);
}

.founder-story {
  text-align: center;
  max-width: 800px;
  line-height: 1.6;
  font-size: 1.2rem;
  color: #eaeaea;
}

@media (max-width: 768px) {
  .founder-images {
    flex-direction: column;
  }

  .founder-image {
    width: 100%;
    height: 300px;
  }
}