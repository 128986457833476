.success-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: linear-gradient(135deg, var(--background) 0%, var(--background-secondary) 100%);
}

.success-content {
  background: var(--background);
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
  animation: fadeInUp 0.8s ease-out;
}

.success-icon,
.error-icon {
  font-size: 4rem;
  margin-bottom: 1.5rem;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--background-secondary);
  border-top: 5px solid var(--primary);
  border-radius: 50%;
  margin: 0 auto 1.5rem;
  animation: spin 1s linear infinite;
}

.success-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.success-content p {
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.redirect-message {
  font-size: 0.9rem;
  opacity: 0.7;
  margin-top: 2rem;
}

.return-button {
  background: var(--primary);
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  margin-top: 1.5rem;
}

.return-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 