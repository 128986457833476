.privacy-policy {
  background-color: #0f0e17;
  color: white;
  min-height: 100vh;
  padding: 40px 20px;
}

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  color: #6c63ff;
  font-size: 2.5em;
  margin-bottom: 10px;
  text-align: center;
}

.last-updated {
  text-align: center;
  color: #888;
  margin-bottom: 40px;
}

.privacy-policy h2 {
  color: #6c63ff;
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 15px;
}

.privacy-policy section {
  margin-bottom: 40px;
}

.privacy-policy p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.privacy-policy ul {
  list-style-type: none;
  padding-left: 20px;
}

.privacy-policy li {
  margin-bottom: 10px;
  position: relative;
}

.privacy-policy li::before {
  content: "•";
  color: #6c63ff;
  position: absolute;
  left: -20px;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 15px;
  }

  .privacy-policy h1 {
    font-size: 2em;
  }

  .privacy-policy h2 {
    font-size: 1.5em;
  }
} 