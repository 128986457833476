.progress-analytics {
  padding: 100px 2rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
  color: var(--text-color);
}

.progress-header {
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.progress-header h1 {
  font-size: 2.4rem;
  color: #ffffff;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin: 0;
  text-shadow: 2px 2px 15px rgba(255, 255, 255, 0.15);
}

.progress-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.progress-tab {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  color: var(--text-color);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.progress-tab.active {
  background: linear-gradient(45deg, #FF6B6B, #4ECDC4);
  color: white;
  border: none;
}

.progress-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.progress-stat-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 16px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.progress-stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #FF6B6B, #4ECDC4);
}

.progress-stat-card h3 {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: #ccc;
}

.progress-stat-card p {
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(45deg, #FF6B6B, #4ECDC4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.progress-charts-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.progress-chart-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 300px;
}

.progress-language-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.progress-language-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Add more styles for responsiveness */
@media (max-width: 1200px) {
  .progress-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .progress-charts-row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .progress-stats-grid {
    grid-template-columns: 1fr;
  }
}

/* Add these new styles */
.recent-activity {
  height: 100%;
  padding: 1rem;
}

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}

.activity-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.activity-item:hover {
  background: rgba(255, 255, 255, 0.08);
}

.activity-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  font-size: 1.2rem;
}

.activity-details {
  flex: 1;
}

.activity-title {
  font-weight: 600;
  margin: 0;
  color: #ffffff;
  font-size: 0.95rem;
}

.activity-subtitle {
  margin: 2px 0 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
  cursor: help;
}

.activity-subtitle:hover {
  color: rgba(255, 255, 255, 0.8);
}

.activity-meta {
  font-weight: 500;
  color: var(--primary-color);
}

.language-select {
  width: 200px;
  z-index: 10;
}

.language-select__control {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  padding: 8px 12px;
  min-height: 42px;
}

.language-select__single-value {
  color: #ffffff !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.language-select__option {
  color: #ffffff !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  padding: 10px 12px !important;
}

.language-select__option:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.language-select__menu {
  background: #1b1b2f !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  margin-top: 4px !important;
  overflow: hidden !important;
}

.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: var(--text-color);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  font-size: 1rem;
  text-align: center;
  padding: 20px;
}

.progress-pro-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0f0e17;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 1000;
}

.progress-pro-content {
  background: rgba(108, 99, 255, 0.1);
  border: 1px solid rgba(108, 99, 255, 0.2);
  border-radius: 15px;
  padding: 40px;
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.progress-pro-content h2 {
  color: #6c63ff;
  font-size: 2em;
  margin-bottom: 20px;
}

.progress-pro-content p {
  color: #bbb;
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 30px;
}

.progress-upgrade-button {
  background: linear-gradient(135deg, #6c63ff 0%, #4a47a3 100%);
  color: white;
  border: none;
  padding: 15px 40px;
  border-radius: 30px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.progress-upgrade-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
}

@media (max-width: 768px) {
  .progress-pro-content {
    padding: 30px;
  }

  .progress-pro-content h2 {
    font-size: 1.5em;
  }

  .progress-pro-content p {
    font-size: 1em;
  }
}

/* Add/update these media queries at the bottom of your file */
@media (max-width: 768px) {
  .progress-analytics {
    padding: 80px 1rem 1rem;
  }

  .progress-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .progress-header h1 {
    font-size: 1.8rem;
  }

  .language-select {
    width: 100%;
  }

  .progress-tabs {
    padding: 0.5rem;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .progress-tab {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    flex: 1;
    min-width: 120px;
    text-align: center;
  }

  .progress-stat-card {
    padding: 1rem;
  }

  .progress-stat-card h3 {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .progress-stat-card p {
    font-size: 1.5rem;
  }

  .progress-chart-container {
    padding: 1rem;
    min-height: 250px;
  }

  .activity-list {
    max-height: 300px;
  }

  .activity-item {
    padding: 8px;
    gap: 8px;
  }

  .activity-icon {
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }

  .activity-title {
    font-size: 0.85rem;
  }

  .activity-subtitle {
    font-size: 0.75rem;
  }
}

/* Add styles for even smaller screens */
@media (max-width: 480px) {
  .progress-analytics {
    padding: 60px 0.75rem 0.75rem;
  }

  .progress-header h1 {
    font-size: 1.5rem;
  }

  .progress-tab {
    min-width: 100px;
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }

  .progress-stat-card {
    padding: 0.75rem;
  }

  .progress-stat-card h3 {
    font-size: 0.8rem;
  }

  .progress-stat-card p {
    font-size: 1.3rem;
  }

  .progress-chart-container {
    padding: 0.75rem;
    min-height: 200px;
  }

  .activity-list {
    max-height: 250px;
  }

  .activity-icon {
    width: 28px;
    height: 28px;
    font-size: 0.9rem;
  }
}

/* Add specific chart responsiveness */
@media (max-width: 768px) {
  .progress-charts-row canvas {
    max-height: 300px !important;
  }
}

@media (max-width: 480px) {
  .progress-charts-row canvas {
    max-height: 250px !important;
  }
} 