/* Reuse existing overlay and modal base styles */
.wordpack-intro-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px 0;
}

.wordpack-intro-modal {
  background: linear-gradient(135deg, #2a2a4a 0%, #1b1b2f 100%);
  border-radius: 20px;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  position: relative;
  color: white;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  margin: auto;
}

/* Additional styles specific to the daily limit modal */
.next-available-container {
  margin-top: 20px;
  padding: 20px;
  background: rgba(108, 99, 255, 0.05);
  border-radius: 16px;
  border: 1px solid rgba(108, 99, 255, 0.1);
  transition: all 0.3s ease;
}

.next-available-container:hover {
  background: rgba(108, 99, 255, 0.1);
  border-color: rgba(108, 99, 255, 0.3);
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(108, 99, 255, 0.15);
}

.time-display {
  font-size: 2em;
  font-weight: bold;
  color: #6c63ff;
  margin-top: 10px;
}

.intro-icon {
  font-size: 3em;
  color: #6c63ff;
  margin-bottom: 20px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Reuse existing button styles */
.intro-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s;
}

.intro-close-button:hover {
  transform: scale(1.1);
}

.intro-next-button {
  background: #6c63ff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1.1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.2s, background-color 0.2s;
  margin-top: 30px;
  width: 100%;
}

.intro-next-button:hover {
  background: #5b54d6;
  transform: translateY(-2px);
}

@media (max-width: 480px) {
  .wordpack-intro-modal {
    width: 95%;
    padding: 20px;
  }

  .intro-title {
    font-size: 1.5em;
  }

  .time-display {
    font-size: 1.5em;
  }
}

.daily-limit-modal {
  background: white;
  border-radius: 12px;
  padding: 24px;
  max-width: 500px;
  width: 90%;
  position: relative;
  margin: 40px auto;
}

.daily-limit-modal h2 {
  color: #333;
  margin-bottom: 16px;
}

.pro-advertisement {
  background: linear-gradient(145deg, rgba(138, 43, 226, 0.1) 0%, rgba(108, 99, 255, 0.1) 100%);
  border: 1px solid rgba(138, 43, 226, 0.2);
  border-radius: 16px;
  padding: 24px;
  margin: 20px 0;
  text-align: left;
  backdrop-filter: blur(5px);
  box-shadow: 0 8px 32px rgba(138, 43, 226, 0.1);
  transition: all 0.3s ease;
}

.pro-advertisement:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 40px rgba(138, 43, 226, 0.15);
  border-color: rgba(138, 43, 226, 0.3);
}

.pro-message {
  color: #8a2be2;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  text-shadow: 0 0 20px rgba(138, 43, 226, 0.3);
}

.pro-icon {
  color: #8a2be2;
  font-size: 1.4em;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.pro-features {
  list-style: none;
  padding: 0;
  margin: 16px 0;
}

.pro-features li {
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 0.95em;
  transition: transform 0.2s ease;
}

.pro-features li:hover {
  transform: translateX(5px);
}

.check-icon {
  color: #8a2be2;
  font-size: 1em;
  background: rgba(138, 43, 226, 0.1);
  padding: 6px;
  border-radius: 50%;
}

.pro-upgrade-button {
  background: linear-gradient(135deg, #8a2be2 0%, #6c63ff 100%);
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(138, 43, 226, 0.3);
}

.pro-upgrade-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.pro-upgrade-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(138, 43, 226, 0.4);
}

.pro-upgrade-button:hover:before {
  left: 100%;
}

.pro-upgrade-button:active {
  transform: translateY(1px);
}

.close-button {
  margin-top: 16px;
  padding: 8px 16px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.daily-limit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
} 