@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0f0e17;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  border-bottom: none;
  transition: border-bottom 0.3s ease-in-out;
  font-size: 0.9em;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: auto;
  position: relative;
}

/* Left Section: Branding */
.navbar-brand {
  flex-shrink: 0;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center; /* Center the logo and text vertically */
  font-size: 1.5em; /* Adjust font size */
  color: #61dafb;
  text-decoration: none;
  transition: color 0.3s;
}

.navbar-brand img {
  height: 50px; /* Adjust the height of the logo */
  width: auto; /* Keep the width auto to maintain aspect ratio */
  margin-right: 15px; /* Adjust spacing between the logo and text */
}

.navbar-brand:hover {
  color: #21a1f1;
}

/* Center Section: Navigation Links */
.nav-links {
  display: flex;
  align-items: center;
  gap: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  translate: -25px;
}

.nav-link {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 1em; /* Adjust font size */
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  transition: color 0.3s, opacity 0.3s;
  padding: 10px 0; /* Add padding for spacing */
  display: flex;
  align-items: center; /* Center the icon and text vertically */
}

.nav-link .icon {
  margin-right: 10px; /* Add spacing between the icon and the text */
}

.nav-link:hover,
.nav-link.active {
  color: rgba(255, 255, 255, 0.9); /* Change color on hover and active */
}

.nav-link.active::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px; /* Adjust as needed */
  height: 2px;
  background-color: #61dafb; /* Color of the bottom border */
}

/* Right Section: Spotify Player, Notifications, Streaks, Profile */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between items */
  transform: translateX(-50px);
}

.navbar-album-art {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.navbar-spotify-player:hover .navbar-album-art {
  transform: scale(1.1) rotate(5deg);
}

.navbar-track-info {
  flex-grow: 1;
  overflow: hidden;
}

.navbar-track-name {
  font-size: 0.9em;
  font-weight: bold;
  color: #fff;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-artist-name {
  font-size: 0.75em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-play-pause-button {
  background: none;
  border: none;
  color: #1DB954;
  cursor: pointer;
  font-size: 1.2em;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.navbar-play-pause-button:hover {
  background-color: rgba(29, 185, 84, 0.1);
  transform: scale(1.1);
}

/* Notification Bell */
.notification-bell {
  position: relative;
  cursor: pointer;
}

.bell-icon {
  font-size: 1.5rem;
  color: #61dafb;  /* Light blue to fit dark theme */
  transition: color 0.3s ease;
}

.notification-bell:hover .bell-icon {
  color: #21a1f1;  /* Slightly lighter blue on hover */
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #ff4757;  /* Bright red for better contrast */
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 0.9rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);  /* Slight shadow for pop effect */
}

/* Notification Dropdown */
.notification-dropdown {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #444;
  padding: 15px;
  background-color: #1c1b22;
  position: absolute;
  right: 0; /* Align dropdown to the bell */
  top: 40px;
  width: 350px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.notification-bell:hover .notification-dropdown,
.notification-bell:focus .notification-dropdown,
.notification-bell:focus-within .notification-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.notification-dropdown h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: #61dafb;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 2px solid #61dafb;
  padding-bottom: 10px;
}

/* Notification List */
.notification-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notification-list li {
  position: relative;
  padding-right: 60px; /* Make room for the clear button */
  padding: 15px;
  border-bottom: 1px solid #333;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s ease;
  text-align: left;
}

.notification-list li:hover {
  background-color: #2a292f;
}

.notification-message {
  font-family: 'Poppins', sans-serif;
  color: #f1f1f1;
  text-align: left;
  margin-bottom: 8px;
  line-height: 1.5;
  max-height: 70px;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: normal;
  font-size: 0.95rem;
  letter-spacing: 0.3px;
}

.time-ago {
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  color: #888;
  align-self: flex-end;
  font-style: italic;
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: #666;
}

.notification-list li.unread {
  background-color: rgba(97, 218, 251, 0.1);
  font-weight: 600;
}

/* Scrollbar styling for notification message */
.notification-message::-webkit-scrollbar {
  width: 4px;
}

.notification-message::-webkit-scrollbar-thumb {
  background-color: #61dafb;
  border-radius: 2px;
}

.notification-message::-webkit-scrollbar-track {
  background-color: #222;
}

/* For Firefox */
.notification-message {
  scrollbar-width: thin;
  scrollbar-color: #61dafb #222;
}

/* Scrollbar styling for notification dropdown */
.notification-dropdown::-webkit-scrollbar {
  width: 8px;
}

.notification-dropdown::-webkit-scrollbar-thumb {
  background-color: #444;  /* Darker scrollbar thumb */
  border-radius: 4px;  /* Rounded scrollbar */
}

.notification-dropdown::-webkit-scrollbar-track {
  background-color: #1c1b22;  /* Background of the scrollbar track */
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px); /* Move up 20px */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Return to original position */
  }
}

.fade-in-page {
  animation: fadeInUp 0.8s ease-in-out; /* Duration of the animation */
  opacity: 0; /* Initial opacity */
  animation-fill-mode: forwards; /* Ensures it stays at the end of the animation */
}

/* Streak Container */
.streak-container {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 1em;
  color: #fff; /* White text color to fit dark theme */
}

.streak-container .streak-icon {
  margin-right: 5px;
  color: #fff; /* White color for the star */
  font-size: 1.2em; /* Adjust size to match the number */
}

.streak-container span {
  font-size: 1em; /* Adjust font size for the number */
  color: #fff; /* Ensure the number is white */
}

/* Profile Container */
.profile-container {
  display: flex;
  align-items: center;
}

.profile-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #61dafb;
  color: #0f0e17;
  font-size: 1.2em;
  margin-left: 10px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.profile-icon img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.profile-icon:hover {
  background-color: rgba(97, 218, 251, 0.3);
  transform: scale(1.1);
}

.profile-icon:hover img {
  transform: scale(1.1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Border appears when scrolled */
.navbar.scrolled {
  border-bottom: 1px solid rgb(125, 116, 116);
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }

  .navbar {
    padding: 10px 15px;
  }

  .navbar-content {
    overflow: visible;
    padding: 0 5px;
  }

  .navbar-brand {
    font-size: 1.2em;
  }

  .navbar-brand img {
    height: 40px;
  }

  /* Adjust right section */
  .navbar-right {
    transform: none;
    gap: 15px;
    margin-left: auto;
    position: relative;
    z-index: 1000;
  }

  /* Hide Spotify player on mobile */
  .navbar-spotify-player {
    display: none;
  }

  /* Adjust notification dropdown */
  .notification-dropdown {
    width: 300px;
    right: -10px;
    left: auto;
    max-width: calc(100vw - 30px);
    position: fixed;
    top: 70px;
    transform: none;
    max-height: calc(100vh - 140px);
    margin: 0 15px;
    overflow-y: auto;
  }

  /* Ensure the notification bell container is properly positioned */
  .notification-bell {
    position: relative;
  }

  /* Adjust backdrop for notifications */
  .notification-dropdown::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  /* Adjust z-index to ensure dropdown appears above other elements */
  .notification-dropdown {
    z-index: 1002;
  }

  /* Adjust profile icon size */
  .profile-icon {
    height: 35px;
    width: 35px;
  }

  /* Adjust profile icon position */
  .profile-container {
    transform: translateX(-15px);
    position: relative;
    z-index: 1000;
  }

  /* Hide only profile container in top navbar on mobile */
  .navbar-right .profile-container {
    display: none;
  }

  /* Keep notification bell and streaks visible */
  .navbar-right {
    gap: 15px;
    display: flex;
    align-items: center;
  }

  /* Style notification bell for mobile */
  .notification-bell {
    font-size: 0.9em;
  }

  /* Style streaks for mobile */
  .streak-container {
    display: flex;
    align-items: center;
    gap: 1px;
    font-size: 0.9em;
  }

  .streak-icon {
    font-size: 1.2em;
  }

  /* Public Profiles Section - Mobile Adjustments */
  .discover-profiles-grid {
    grid-template-columns: repeat(2, 1fr); /* Show 2 cards per row */
    gap: 15px; /* Reduce gap between cards */
    padding: 10px;
    width: 100%;
  }

  .discover-profile-card {
    max-width: 100%; /* Allow cards to fill their grid space */
    height: auto;
    padding: 12px;
    flex-direction: column; /* Stack content vertically */
    text-align: center;
    min-height: 180px; /* Set minimum height */
  }

  .discover-profile-pic {
    margin: 0 0 0px 0; /* Adjust margins for vertical layout */
  }

  .discover-profile-pic img {
    width: 50px;
    height: 50px;
  }

  .discover-profile-info {
    align-items: center; /* Center content */
  }

  .discover-profile-info h2 {
    font-size: 1.1em;
    margin-top: 5px;
    text-align: center;
    justify-content: center;
  }

  .discover-languages-container {
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
  }

  .discover-language-flag {
    width: 20px;
    height: 20px;
  }

  /* Adjust verified badge size */
  .verified-badge {
    width: 15px;
    height: 15px;
    margin-left: 3px;
  }

  /* Leaderboard Section Adjustments */
  .leaderboard-card {
    padding: 10px 8px;
    min-height: 60px; /* Minimum height to prevent squishing */
  }

  .leaderboard-rank {
    font-size: 1em;
    margin-right: 8px;
    min-width: 20px; /* Ensure consistent width */
    text-align: center;
  }

  .leaderboard-profile-pic img {
    width: 35px;
    height: 35px;
    margin-right: -6px;
    margin-left: 8px;
    flex-shrink: 0; /* Prevent image from shrinking */
  }

  .leaderboard-info {
    min-width: 0;
    overflow: visible; /* Allow content to be visible */
    padding-right: 8px; /* Add some right padding */
  }

  .leaderboard-info h3 {
    font-size: 0.9em;
    margin: 0;
    white-space: normal; /* Allow text to wrap */
    overflow: visible;
    text-overflow: clip;
    line-height: 1.2; /* Add some line height */
    margin-bottom: 2px;
  }

  .leaderboard-info p {
    font-size: 0.75em;
    margin: 2px 0;
    white-space: normal; /* Allow text to wrap */
    overflow: visible;
    text-overflow: clip;
    line-height: 1.2; /* Add some line height */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
  }

  .verified-badge {
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }

  /* Feedback Modal Container */
  .feedback-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 350px;
    max-height: calc(100vh - 140px); /* Account for bottom bar */
    overflow-y: auto;
    margin: 0;
    padding: 15px;
    background: #1b1b2f;
    border-radius: 15px;
    z-index: 1100; /* Ensure it's above other content */
  }

  /* Modal Content */
  .feedback-modal-content {
    padding-bottom: 60px; /* Add padding to prevent content from being hidden behind bottom bar */
  }

  /* Modal Backdrop */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1099;
  }

  /* Modal Close Button */
  .feedback-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
  }

  /* Modal Form Elements */
  .feedback-form {
    margin-top: 10px;
  }

  .feedback-form textarea,
  .feedback-form input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 8px;
  }

  /* Submit Button */
  .feedback-submit-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
  }

  /* Scrollbar Styling */
  .feedback-modal::-webkit-scrollbar {
    width: 4px;
  }

  .feedback-modal::-webkit-scrollbar-thumb {
    background: #61dafb;
    border-radius: 2px;
  }

  /* Option 1: Fixed position above the bottom bar */
  .feedback-trigger {
    position: fixed;
    bottom: 70px; /* Adjust based on your bottom bar height */
    right: 15px;
    background: #1b1b2f;
    padding: 8px 15px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 998;
  }

  /* Option 2: Floating action button style */
  .feedback-trigger {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #61dafb;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 998;
  }

  /* Option 3: Move to top right corner */
  .feedback-trigger {
    position: fixed;
    top: 80px; /* Below navbar */
    right: 15px;
    background: #1b1b2f;
    padding: 8px 15px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 998;
  }
}

/* Hide desktop nav on mobile */
@media screen and (max-width: 768px) {
  .nav-links:not(.mobile-nav) {
    display: none;
  }
}

/* Hide mobile nav on desktop */
@media screen and (min-width: 769px) {
  .mobile-nav-container {
    display: none !important;
  }
}

/* Update mobile menu z-index */
.mobile-menu {
  /* ... existing styles ... */
  z-index: 998; /* Below navbar elements */
}
  /* Hide mobile menu on homepage */
.home-page .mobile-menu,
.home-page .mobile-nav-container {
  display: none !important;
}

/* Update existing mobile menu styles */
.mobile-menu {
  z-index: 998;
}

.clear-notification-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.clear-notification-btn:hover {
  background-color: #0056b3;
}

.time-ago {
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: #666;
}

