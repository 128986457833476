/* General Container for StoryChain */
.storychain-container {
    width: 95%;
    max-width: 1100px;
    margin: 60px auto 0;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    border-radius: 30px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(255, 255, 255, 0.1);
    background-color: rgba(15, 14, 23, 0.8);
}

/* Story Chain Display Area */
.storychain-chain {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 25px;
    border-radius: 20px;
    box-shadow: inset 0 2px 15px rgba(0, 0, 0, 0.3), 0 8px 25px rgba(0, 0, 0, 0.2);
    font-size: 1.3em;
    color: #fff;
    height: 200px;
    overflow-y: auto;
    transition: all 0.3s ease;
}

.storychain-chain:hover {
    box-shadow: inset 0 2px 20px rgba(0, 0, 0, 0.4), 0 10px 30px rgba(0, 0, 0, 0.3);
}

/* Word colors */
.user-word {
    color: #6c63ff; /* Matching the button color */
    font-weight: 600;
}

.connected-user-word {
    color: #61dafb; /* A contrasting color */
    font-weight: 600;
}

/* Input and Button for Adding a Word */
.storychain-input-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.storychain-input {
    flex: 1;
    padding: 15px;
    font-size: 1.1em;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    border-radius: 15px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.storychain-input:focus {
    outline: none;
    border-color: #6c63ff;
    box-shadow: 0 0 0 3px rgba(108, 99, 255, 0.3), 0 5px 15px rgba(0, 0, 0, 0.2);
}

.storychain-button {
    padding: 15px 30px;
    font-size: 1.1em;
    font-weight: bold;
    background: linear-gradient(145deg, #7c73ff, #6c63ff);
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
}

.storychain-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(108, 99, 255, 0.4);
}

/* Turn Indicator */
.storychain-turn-indicator {
    font-size: 1.3em;
    text-align: center;
    color: #ffd700;
}

/* Finish Button */
.storychain-finish {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.storychain-finish-button {
    padding: 12px 25px;
    font-size: 1.1em;
    font-weight: bold;
    background-color: #28a745;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 15px auto 0;
    box-shadow: 0 5px 15px rgba(40, 167, 69, 0.3);
    display: block;
}

.storychain-finish-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(40, 167, 69, 0.4);
}

.connected-message {
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
    color: #28a745; /* Green color for a positive connection status */
    margin-bottom: 15px;
    padding: 8px;
    background-color: #e8f5e9; /* Light green background */
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    color: #0f0e17; /* Text color */
}

/* StoryChain Intro Section */
.storychain-intro {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.storychain-title {
    font-size: 2.2em;
    color: #ffffff;
    margin-bottom: 15px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.storychain-logo-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 25px;
    box-shadow: 0 0 25px rgba(108, 99, 255, 0.6);
}

.storychain-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.storychain-description {
    font-size: 1.1em;
    color: #eaeaea;
    margin-bottom: 25px;
    line-height: 1.5;
    max-width: 500px;
}

.storychain-rules {
    text-align: left;
    max-width: 350px;
    margin: 0 auto 25px;
    padding-left: 15px;
}

.storychain-rules li {
    margin-bottom: 12px;
    color: #bbb;
    font-size: 0.9em;
}

.storychain-searching {
    text-align: center;
    color: #ffffff;
}

.storychain-spinner {
    border: 4px solid #1b1b2f;
    border-top: 4px solid #6c63ff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 1s linear infinite;
    margin: 15px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.inactivity-warning-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.inactivity-warning-content {
    background-color: rgba(15, 14, 23, 0.95);
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    color: #ffffff;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.inactivity-warning-content h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #6c63ff;
}

.inactivity-warning-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.inactivity-warning-content .storychain-button {
    margin-top: 10px;
}

.inactivity-warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.inactivity-warning-modal {
    background-color: #1f1f1f;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
}

.inactivity-warning-content h2 {
    margin-bottom: 10px;
}

.inactivity-warning-content p {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .storychain-container {
        width: 98%;
        margin: 30px auto 0;
        padding: 20px 15px;
        gap: 20px;
    }

    .storychain-chain {
        padding: 15px;
        font-size: 1.1em;
        height: 180px;
    }

    .storychain-input-container {
        flex-direction: column;
        gap: 15px;
    }

    .storychain-input {
        width: 100%;
        padding: 12px;
        font-size: 1em;
    }

    .storychain-button {
        width: 100%;
        padding: 12px 20px;
        font-size: 1em;
    }

    .storychain-title {
        font-size: 1.8em;
    }

    .storychain-logo-container {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
    }

    .storychain-description {
        font-size: 1em;
        padding: 0 10px;
    }

    .storychain-rules {
        max-width: 300px;
        padding-left: 10px;
    }
}

@media (max-width: 480px) {
    .storychain-container {
        margin: 20px auto 0;
        padding: 15px 10px;
        gap: 15px;
    }

    .storychain-chain {
        padding: 12px;
        font-size: 1em;
        height: 150px;
    }

    .storychain-title {
        font-size: 1.5em;
    }

    .storychain-logo-container {
        width: 100px;
        height: 100px;
    }

    .inactivity-warning-content h2 {
        font-size: 1.4em;
    }

    .inactivity-warning-content p {
        font-size: 1em;
    }

    .storychain-turn-indicator {
        font-size: 1.1em;
    }

    .storychain-finish {
        font-size: 1.2em;
    }

    .connected-message {
        font-size: 1.1em;
        padding: 6px;
    }

    .storychain-finish {
        font-size: 1.2em;
    }

    .storychain-finish-button {
        width: 180px;
        font-size: 1em;
        padding: 10px 20px;
    }
}

.storychain-timeout-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
}

.storychain-button-secondary {
    background-color: #6c757d;
}

.storychain-button-secondary:hover {
    background-color: #5a6268;
}

.storychain-timeout {
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.storychain-timeout h2 {
    color: #ff6b6b;
    font-size: 1.4em;
    margin-bottom: 0.5rem;
}

.storychain-timeout p {
    color: #eaeaea;
    font-size: 1.1em;
    margin-bottom: 1.5rem;
}

.storychain-timeout-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

@media (max-width: 480px) {
    .storychain-timeout h2 {
        font-size: 1.2em;
    }
    
    .storychain-timeout p {
        font-size: 0.9em;
    }
    
    .storychain-timeout-buttons {
        flex-direction: column;
        gap: 0.8rem;
    }
}
