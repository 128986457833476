.wordpack-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.wordpack-modal {
  background: linear-gradient(135deg, #2a2a4a 0%, #1b1b2f 100%);
  border-radius: 15px;
  padding: 25px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.close-button:hover {
  opacity: 1;
}

.pack-content {
  text-align: center;
}

.phrase-counter {
  font-size: 0.9em;
  color: #6c63ff;
  text-align: center;
  margin-bottom: 10px;
}

.phrase-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 15px;
  margin: 15px 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.phrase-text {
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-button {
  background: none;
  border: none;
  color: #6c63ff;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.audio-button:hover {
  background: rgba(108, 99, 255, 0.1);
  transform: scale(1.1);
}

.audio-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.audio-button.playing {
  animation: pulse 1s infinite;
}

.phrase-translation {
  font-size: 1.4em;
  color: #bbb;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reveal-button {
  background: linear-gradient(135deg, #6c63ff 0%, #4a47a3 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.2s;
}

.reveal-button:hover {
  transform: scale(1.05);
}

.navigation-buttons {
  margin-top: 20px;
}

.next-button, .finish-button {
  background: linear-gradient(135deg, #6c63ff 0%, #4a47a3 100%);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 1.1em;
  cursor: pointer;
  transition: transform 0.2s;
}

.next-button:hover, .finish-button:hover {
  transform: scale(1.05);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.phrase-translation.revealed {
  animation: revealText 0.5s ease-out;
}

@keyframes revealText {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.pack-context {
  background: rgba(108, 99, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid rgba(108, 99, 255, 0.2);
}

.pack-context h3 {
  color: #6c63ff;
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.context-description {
  color: #bbb;
  margin: 0;
  font-size: 0.9em;
} 