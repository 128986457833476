.account-page {
  position: relative;
  min-height: 100vh;
  width: 1600px;
  margin: 0 auto;
  padding: 40px 20px 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #ffffff;
  background-color: #0f0e17;
  font-family: 'Inter', sans-serif;
  margin-top: 100px;
  border-radius: 15px;
}

@media (max-width: 1540px) {
  .account-page {
    width: 1300px;
    padding: 30px 15px;
  }
}

@media (max-width: 1224px) {
  .account-page {
    width: 100%;
    padding: 20px 10px;
    margin-top: 50px;
  }

  .profile-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-img img {
    width: 100px;
    height: 100px;
  }

  .profile-info p {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .account-page {
    padding: 15px 5px;
    margin-top: 30px;
  }

  .profile-details {
    gap: 10px;
  }

  .profile-img img {
    width: 80px;
    height: 80px;
  }

  .friends-grid, .pending-requests-grid {
    grid-template-columns: 1fr;
  }

  .friend-card, .pending-request-card {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .account-page {
    padding: 10px 5px;
    margin-top: 20px;
  }

  .profile-details {
    gap: 5px;
  }

  .profile-info p {
    font-size: 0.9em;
  }

  .acc-button, .accept-button, .reject-button {
    padding: 8px 15px;
    font-size: 0.9em;
  }
}

/* Profile Details Styling */
.profile-details {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #333;
}

.profile-img img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 3px solid #4a47a3;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-info p {
  margin: 5px 0;
  font-size: 1.2em;
  color: #eaeaea;
}

input[type="file"] {
  display: none;
}

.profile-info strong {
  font-weight: bold;
}

/* Button Styling */
button, .custom-file-upload {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1em;
  color: #ffffff;
  background-color: #4a47a3;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover, .custom-file-upload:hover {
  background-color: #61dafb;
  transform: scale(1.05);
}

/* Friends List Styling */
.friends-list-container {
  text-align: left;
  width: 100%;
}

.friends-list-container h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #ffffff;
}

.friends-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
  width: 100%;
}

.friend-card {
  background-color: #1b1b2f;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 250px;
  max-width: 300px;
  margin: auto;
}

.friend-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.friend-pic img, .friend-placeholder {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  background-color: #444;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1.5em;
}

.friend-info h3 {
  margin: 0;
  color: #ffffff;
  font-size: 1.2em;
}

.languages-container-account {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.language-flag-account {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #4a47a3;
}
.more-languages {
  font-weight: bold;
  color: white;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-top: 2px;
  font-family: 'Montserrat', sans-serif;
}

/* Pending Friend Requests Styling */
.pending-requests-container {
  text-align: left;
  width: 100%;
}

.pending-requests-container h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #ffffff;
}

.pending-requests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
}

.pending-request-card {
  background-color: #1b1b2f;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 250px;
  max-width: 300px;
  margin: auto;
}

.pending-request-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.pending-request-card p {
  margin: 10px 0;
  color: #ffffff;
  font-size: 1.1em;
  text-align: center;
}

.request-actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.accept-button, .reject-button {
  padding: 8px 16px;
  font-size: 0.9em;
  font-weight: bold;
  color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: none;
  width: 100px;
  text-align: center;
}

.accept-button {
  background-color: #4caf50;
}

.accept-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.reject-button {
  background-color: #f44336;
}

.reject-button:hover {
  background-color: #e53935;
  transform: scale(1.05);
}


/* Badges Styling */
.account-badges-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}

.account-badges-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  justify-items: start;
  width: 100%;
}

.account-badge-card {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.account-badge-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.account-badge-card:hover {
  transform: scale(1.1);
}

.account-badge-card.locked {
  opacity: 0.5;
  background-color: rgba(27, 27, 47, 0.8);
}


/* Fade-In Animation */
@keyframes fadeIn {
  from { 
    opacity: 0; 
    transform: translateY(10px); 
  }
  to { 
    opacity: 1; 
    transform: translateY(0); 
  }
}

.account-badges-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account-badges-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.account-badge-card {
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.account-badge-card:hover {
  transform: scale(1.1);
}

.account-badge-card.locked {
  opacity: 0.5;
  background-color: rgba(27, 27, 47, 0.8);
}

.account-badge-image {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.account-badge-card.vibranium {
  background: linear-gradient(135deg, rgba(75, 0, 130, 0.5) 25%, rgba(0, 0, 0, 0.5) 25%, rgba(75, 0, 130, 0.5) 50%, rgba(0, 0, 0, 0.5) 50%, rgba(75, 0, 130, 0.5) 75%, rgba(0, 0, 0, 0.5) 75%, rgba(75, 0, 130, 0.5));
  background-size: 200% 200%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  animation: vibraniumAnimation 8s linear infinite;
}

.account-badge-card.silver {
  background: linear-gradient(135deg, rgba(192, 192, 192, 0.5) 25%, rgba(128, 128, 128, 0.5) 25%, rgba(192, 192, 192, 0.5) 50%, rgba(128, 128, 128, 0.5) 50%, rgba(192, 192, 192, 0.5) 75%, rgba(128, 128, 128, 0.5) 75%, rgba(192, 192, 192, 0.5));
  background-size: 200% 200%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  animation: silverAnimation 8s linear infinite;
}

.account-badge-card.gold {
  background: linear-gradient(135deg, rgba(255, 215, 0, 0.5) 25%, rgba(184, 134, 11, 0.5) 25%, rgba(255, 215, 0, 0.5) 50%, rgba(184, 134, 11, 0.5) 50%, rgba(255, 215, 0, 0.5) 75%, rgba(184, 134, 11, 0.5) 75%, rgba(255, 215, 0, 0.5));
  background-size: 200% 200%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  animation: goldAnimation 6s linear infinite;
}


@keyframes silverAnimation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 200%;
  }
}

@keyframes goldAnimation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 200%;
  }
}

@keyframes vibraniumAnimation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 350% 350%;
  }
}


@keyframes bronzeFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.account-badge-card.bronze {
  background: linear-gradient(270deg, rgba(205, 127, 50, 0.5), rgba(139, 69, 19, 0.5), rgba(205, 127, 50, 0.5));
  background-size: 200% 200%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  animation: bronzeFlow 4s ease infinite;
}


.acc-button {
  margin-top: 0px;
  padding: 15px 30px;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
}

.acc-button:hover {
  background-color: rgba(15, 14, 23, 0.8);
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.acc-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(15, 14, 23, 0.2);
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.left-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
}

.logout-button {
  margin-left: auto;
}

.friends-header, .requests-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.friends-header h2, .requests-header h2 {
  margin: 0;
  display: flex;
  align-items: center;
}

.account-search-icon {
  font-size: 14px;
  cursor: pointer;
  color: #61dafb;
  transition: color 0.3s ease;
  margin-left: 10px;
}

.account-search-icon:hover {
  color: #ffffff;
}

.pro-badge {
  background-color: #8a2be2;
  color: white;
  font-size: 0.7em;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 5px;
  font-weight: bold;
  vertical-align: middle;
}

.friend-info h3 {
  display: flex;
  align-items: center;
}

.verified-badge {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

/* Add these new styles */
.account-language-selector {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #1b1b2f;
  color: white;
  border: 2px solid #4a47a3;
  border-radius: 20px;
  padding: 8px 35px 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}

.account-language-selector:hover {
  background-color: #2d2d4d;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.account-language-selector:focus {
  outline: none;
  border-color: #6c63ff;
  box-shadow: 0 0 0 2px rgba(108, 99, 255, 0.2);
}

.account-language-selector option {
  background-color: #1b1b2f;
  color: white;
  padding: 8px;
}

.language-flag-option {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .account-language-selector {
    bottom: 10px;
    left: 10px;
    font-size: 12px;
    padding: 6px 30px 6px 10px;
  }
}

/* Add to your existing styles */
.language-selector {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: #1b1b2f;
  color: white;
  border: 2px solid #4a47a3;
  border-radius: 20px;
  padding: 8px 35px 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto;
  min-width: 150px;
  margin-top: 20px;
}

.language-selector:hover {
  background-color: #2d2d4d;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .language-selector {
    bottom: 10px;
    left: 10px;
    font-size: 12px;
    padding: 6px 30px 6px 10px;
  }
}

/* Update the account-action-btn styles */
.account-action-btn {
  margin-top: 0px;
  padding: 12px 24px;
  font-size: 0.9em;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
  width: 180px;
  text-align: center;
}

.account-action-btn:hover {
  background-color: rgba(15, 14, 23, 0.8);
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.account-action-btn:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(15, 14, 23, 0.2);
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .account-action-btn {
    padding: 10px 20px;
    font-size: 0.85em;
    width: 150px;
  }
  
  .button-container {
    gap: 10px;
  }
  
  .left-buttons {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .account-action-btn {
    padding: 8px 16px;
    font-size: 0.8em;
    width: 130px;
  }
  
  .button-container {
    gap: 8px;
  }
  
  .left-buttons {
    gap: 8px;
  }
}

.subscription-info {
  margin-top: 20px;
  padding: 15px;
  background: rgba(108, 99, 255, 0.1);
  border-radius: 8px;
}

.subscription-info h3 {
  margin: 0 0 10px 0;
  color: #ffffff;
  font-size: 1.2em;
}

.subscription-info p {
  margin: 5px 0;
  font-size: 0.9em;
}

.manage-subscription-btn {
  padding: 15px 30px;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  background-color: #8a2be2; /* Purple color */
  border: 2px solid #8a2be2;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  height: fit-content;
}

.manage-subscription-btn:hover {
  background-color: rgba(138, 43, 226, 0.8);
  transform: translateY(-3px);
  box-shadow: 0 15px 30px rgba(138, 43, 226, 0.2);
}

.manage-subscription-btn:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(138, 43, 226, 0.1);
}

.manage-subscription-text {
  color: #8a2be2;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  transition: all 0.3s ease;
  margin-left: 15px;
}

.manage-subscription-text:hover {
  color: #9f50ea;
  transform: translateY(-2px);
}