/* Flexbox container for the chat title */
.progress-and-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

/* Chat title wrapper */
.chat-title-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-title {
    text-align: center;
}


.chat-title p {
    margin: 0;
    font-size: 1rem;
    color: #666;
}

/* Make sure the chat-messages-container takes up the rest of the available space */
.chat-messages-container {
    display: flex;
    flex-direction: column;
    width: 1300px;
    flex-grow: 1; /* Let it expand based on available space */
    background-color: #0f0e17;
    border-radius: 10px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(125, 116, 116);
    position: relative;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Footer with progress bar at the bottom, not taking up much space */
.footer {
    margin-top: auto;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    color: #888;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Progress bar styling inside footer */
.badge-progress-container {
    width: 100%;
    max-width: 200px;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-fill {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease-in-out;
  }