.globe-container {
    width: 100%;
    height: 500px;
    position: relative;
    background: transparent;
    border-radius: 15px;
    overflow: hidden;
    margin: 0 auto;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transform: scale(1);
  }
  
  .globe-container.animate-in {
    animation: globeIntro 1s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  
  @keyframes globeIntro {
    0% {
      opacity: 0;
      transform: scale(0.8) translateY(20px);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
  
  .globe-container canvas {
    cursor: grab;
    width: 100% !important;
    height: 100% !important;
  }
  
  .globe-container canvas:active {
    cursor: grabbing;
  }
  
  .globe-container :global(.point-label) {
    color: white;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    padding: 3px 6px;
    font-size: 12px;
    pointer-events: none;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  .country-popup {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(15, 14, 23, 0.9);
    border: 1px solid #6c63ff;
    border-radius: 8px;
    padding: 20px;
    color: white;
    width: 300px;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .country-popup h2 {
    margin: 0 0 15px 0;
    color: #6c63ff;
  }
  
  .country-info p {
    margin: 8px 0;
    font-size: 14px;
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .close-popup:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .marker-container {
    position: absolute;
    pointer-events: auto;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .marker-container:hover {
    transform: translate(-50%, -50%) scale(1.3) !important;
  }
  
  .marker-container svg {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
  }
  
  .marker-circle {
    animation: pulse 2s infinite;
  }
  
  .marker-ring {
    opacity: 0.5;
    animation: ripple 2s infinite;
  }
  
  .marker-ring-inner {
    opacity: 0.7;
    animation: ripple 2s infinite 0.5s;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0.8;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes ripple {
    0% {
      opacity: 0.5;
      transform: scale(0.8);
    }
    50% {
      opacity: 0.2;
      transform: scale(1.1);
    }
    100% {
      opacity: 0;
      transform: scale(1.3);
    }
  }
  
  .scene-container canvas {
    font-family: "Segoe UI Emoji", "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Symbol", sans-serif;
    font-size: 16px;
  }
  
  .country-popup {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(15, 14, 23, 0.9);
    border-radius: 8px;
    padding: 20px;
    color: white;
    max-width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }