/* Discover.css */

@keyframes fadeIn {
  from { 
    opacity: 0; 
    transform: translateY(10px); 
  }
  to { 
    opacity: 1; 
    transform: translateY(0); 
  }
}

/* General Page Styling */
.discover-page {
  padding: 20px;
  background-color: #0f0e17;
  color: white;
  animation: fadeIn 0.6s ease-out; /* Apply the fade-in animation */
}

/* Header Styling */
.discover-header {
  margin-top: 100px;
  font-size: 3em;
  text-align: center;
}

.discover-header-upper {
  margin-top: 50px;
  font-size: 3em;
  text-align: center;
}

/* Videos Section */
.discover-videos-container {
  position: relative;
  width: 100%;
  margin-bottom: 70px;
  overflow: hidden;
  border-radius: 20px;
  height: 500px;
}

.discover-video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.discover-video-deck {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.discover-video-thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease;
  border-radius: 20px;
  margin-top: 50px;
}

.discover-video-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  background: linear-gradient(to top, rgba(0,0,0,0.9), transparent);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
}

.discover-video-title {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.watch-youtube-button {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  color: white;
  border: 2px solid white;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.watch-youtube-button:hover {
  background: white;
  color: black;
  transform: translateY(-2px);
}

/* Remove the old arrow buttons and side thumbnails */
.discover-arrow-left,
.discover-arrow-right,
.blurred-thumbnail {
  display: none;
}

@media screen and (max-width: 768px) {
  .discover-videos-container {
    height: 300px;
    margin-bottom: 40px;
  }

  .discover-video-content {
    padding: 20px;
  }

  .watch-youtube-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .discover-video-title {
    font-size: 1.5rem;
  }
}

/* Search Bar Section */
.discover-search-container {
  text-align: center;
  margin-bottom: 20px;
}

.search-form {
  display: flex;
  justify-content: center;
  align-items: stretch;
  max-width: 500px;
  margin: 0 auto;
  gap: 10px; /* Add space between the input and button */
}

.discover-search-bar {
  flex-grow: 1;
  padding: 10px 15px;
  border: none;
  border-radius: 25px; /* Fully rounded */
  font-size: 16px;
  background-color: #1b1b2f;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.discover-search-bar:focus {
  outline: none;
  box-shadow: 0 4px 12px rgba(108, 99, 255, 0.4);
}

.search-button {
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  border-radius: 25px; /* Fully rounded */
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
}

.search-button:hover {
  background-color: rgba(15, 14, 23, 0.8);
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.search-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(15, 14, 23, 0.2);
}

.search-button:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Public Profiles Section */
.discover-profiles-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 40px 40px;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Modern Profile Card Styling */
.discover-profile-card {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background: linear-gradient(145deg, #1f1f3a, #1b1b2f);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  height: 120px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.discover-profile-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.discover-profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.4);
  border-color: rgba(108, 99, 255, 0.5);
}

.discover-profile-card:hover::before {
  transform: translateX(100%);
}

.discover-profile-pic {
  position: relative;
  margin-right: 20px;
  flex-shrink: 0; /* Prevent profile pic from shrinking */
}

.discover-profile-pic img {
  width: 70px;
  height: 70px;
  border-radius: 50%; /* Circular profile pics */
  object-fit: cover;
  border: 3px solid rgba(108, 99, 255, 0.3);
  transition: all 0.3s ease;
}

.discover-profile-placeholder {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #6c63ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: white;
}

.discover-profile-card:hover .discover-profile-pic img {
  border-color: rgba(108, 99, 255, 0.8);
  transform: scale(1.05);
}

.discover-profile-info {
  flex: 1;
  min-width: 0; /* Prevent text overflow */
}

.discover-profile-info h2 {
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.discover-languages-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.discover-language-flag {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.discover-language-flag:hover {
  transform: scale(1.15);
}

/* Verified and Pro Badge Styling */
.verified-badge {
  width: 18px;
  height: 18px;
  transition: transform 0.3s ease;
}

.pro-badge {
  background: linear-gradient(45deg, #6c63ff, #5a52d8); /* Purple gradient */
  padding: 3px 6px;
  border-radius: 6px;
  font-size: 0.7em;
  font-weight: bold;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-left: 6px; /* Add some space after the name */
  letter-spacing: 0.5px; /* Slightly spread out the letters */
}

.pro-badge:hover {
  background: linear-gradient(45deg, #7a72ff, #6861e8); /* Slightly lighter on hover */
}

/* Mobile Responsiveness */
@media screen and (max-width: 1200px) {
  .discover-profiles-grid {
    gap: 30px 40px;
    padding: 15px;
  }
}

@media screen and (max-width: 768px) {
  .discover-profiles-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 10px;
  }

  .discover-profile-card {
    max-width: 100%;
    height: 100px;
  }

  .discover-profile-pic img,
  .discover-profile-placeholder {
    width: 55px;
    height: 55px;
  }

  .discover-profile-info h2 {
    font-size: 1.2em;
  }

  .discover-language-flag {
    width: 22px;
    height: 22px;
  }
}

/* Our Picks Section */
.our-picks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  animation: fadeIn 0.6s ease-out;
}

.our-picks-options {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center;
  gap: 15px; /* Space between the dropdown and the button */
  margin-bottom: 20px;
}

.our-picks-dropdown {
  display: flex;
  align-items: center; /* Center items vertically */
}

.our-picks-dropdown label {
  font-size: 1.2em;
  margin-right: 10px;
}

.our-picks-dropdown select {
  padding: 5px 10px;
  font-size: 1em;
  border-radius: 5px;
  background-color: #1b1b2f;
  color: white;
  border: none;
}

/* Spotify Login Button */
.spotify-login {
  padding: 8px 15px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 25px;
  background-color: #1db954;
  color: white;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.spotify-login:hover {
  background-color: #1ed760;
  transform: scale(1.05);
}

.spotify-login:active {
  background-color: #1aa34a;
  transform: scale(0.95);
}

.our-picks-spotify {
  margin-top: 20px;
  width: 100%;
  max-width: 800px; /* Max width of the Spotify container */
  display: flex;
  justify-content: center; /* Center content horizontally */
}

.our-picks-spotify iframe {
  width: 100%;
  height: 380px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Leaderboard Section */
.leaderboard-container {
  margin-top: 30px;
}

.leaderboard-card {
  background-color: #1b1b2f;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.leaderboard-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.leaderboard-rank {
  margin-right: 20px;
  font-size: 1.5em;
  font-weight: bold;
}

.leaderboard-profile-pic img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
}

.leaderboard-info {
  display: flex;
  flex-direction: column;
}

.leaderboard-info h3 {
  display: flex;
  align-items: center;
}

.leaderboard-info .verified-badge {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  vertical-align: middle;
}

.leaderboard-info p {
  margin: 5px 0;
  font-size: 0.9em;
}

/* Animation */
.discover-header,
.discover-videos-container,
.discover-profiles-grid,
.leaderboard-container,
.our-picks-container {
  animation: fadeIn 0.6s ease-out;
}

.discover-subtitle{
  text-align: center;
  font-size: 1em;
  margin-bottom: 50px;
  margin-top: -20px;
}

/* Loading Skeleton */
.loading-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #aaaaaa;
  font-size: 1.2em;
}

.verified-badge {
  display: inline-block;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  margin-left: 5px;
  vertical-align: middle;
}

.discover-profile-info h2 {
  display: flex;
  align-items: center;
}

.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998; /* Just below Shepherd's z-index */
  pointer-events: none;
}

.tour-active {
  pointer-events: none;
}

.tour-active .shepherd-element,
.tour-active .shepherd-target {
  pointer-events: auto;
}

/* Existing styles for the Spotify player */
.spotify-player {
  /* ... existing styles ... */
}

/* Mobile Adjustments */
@media screen and (max-width: 768px) {
  /* General Page Styling */
  .discover-page {
    padding: 10px;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  .discover-subtitle{
    text-align: center;
    font-size: 1em;
    margin-top: -20px;
    margin-bottom: 10px
  }
  

  /* Header Styling */
  .discover-header {
    margin-top: 60px;
    font-size: 2em;
  }

  .discover-header-upper {
    margin-top: 30px;
    font-size: 2em;
  }

  /* Videos Section */
  .discover-videos-container {
    height: 400px;
    margin-bottom: 40px;
    margin-top: 20px;
    border-radius: 15px;
  }

  .discover-video-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 15px;
  }

  .discover-video-deck {
    border-radius: 15px;
  }

  .discover-video-thumbnail {
    width: 100%;
    height: 100%;
    margin-top: 0;
    border-radius: 15px;
  }

  .discover-video-content {
    padding: 25px;
  }

  .discover-video-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .watch-youtube-button {
    padding: 10px 20px;
    font-size: 1rem;
  }

  /* Search Bar Section */
  .discover-search-container {
    margin-bottom: 15px;
  }

  .search-form {
    flex-direction: column;
    max-width: 350px;
    gap: 8px;
  }

  .discover-search-bar {
    font-size: 14px;
    padding: 8px 12px;
  }

  .search-button {
    padding: 8px 16px;
    font-size: 12px;
  }

  /* Public Profiles Section */
  .discover-profiles-grid {
    grid-template-columns: 1fr;
    column-gap: 40px;
    row-gap: 25px;
    padding: 10px;
  }

  .discover-profile-card {
    max-width: 350px;
    height: auto;
    padding: 15px;
  }

  .discover-profile-pic img {
    width: 60px;
    height: 60px;
  }

  .discover-profile-placeholder {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }

  .discover-profile-info h2 {
    font-size: 1.4em;
  }

  .discover-language-flag {
    width: 25px;
    height: 25px;
  }

  /* Our Picks Section */
  .our-picks-container {
    margin-top: 30px;
  }

  .our-picks-options {
    gap: 10px;
    margin-bottom: 15px;
  }

  .our-picks-dropdown label {
    font-size: 1em;
    margin-right: 5px;
  }

  .our-picks-dropdown select {
    font-size: 0.9em;
    padding: 4px 8px;
  }

  .our-picks-spotify {
    max-width: 100%;
    padding: 0 10px;
  }

  .our-picks-spotify iframe {
    height: 250px;
  }

  /* Leaderboard Section */
  .leaderboard-container {
    padding: 0 10px;
  }

  .leaderboard-card {
    padding: 10px;
    margin-bottom: 10px;
  }

  .leaderboard-rank {
    font-size: 1.2em;
    margin-right: 10px;
  }

  .leaderboard-profile-pic img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .leaderboard-info p {
    font-size: 0.8em;
  }

  /* Loading Skeleton */
  .loading-skeleton {
    min-height: 150px;
    font-size: 1em;
  }

  /* Prevent content from overflowing */
  * {
    box-sizing: border-box;
    max-width: 100%;
  }

  /* Ensure no horizontal overflow */
  body, html {
    overflow-x: hidden;
  }

  /* Spotify Section Adjustments */
  .spotify-section {
    width: 100%;
    padding: 0 15px;
    margin: 20px 0;
  }

  .spotify-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
  }

  .spotify-title {
    font-size: 0.9em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin: 0;
    line-height: 1.4;
  }

  .travel-feature-beta {
    background: rgba(255, 255, 255, 0.2);
    padding: 2px 6px;
    border-radius: 8px;
    font-size: 0.8em;
    letter-spacing: 1px;
    margin-bottom: 4px;
  }

  .spotify-x {
    margin: 0 4px;
  }

  .spotify-section .spotify-content .spotify-logo,
  .spotify-section .spotify-logo {
    width: 40px !important;
    height: 40px !important;
    margin-top: 4px;
  }

  .spotify-description {
    font-size: 0.8em;
    text-align: center;
    margin-top: 8px;
    line-height: 1.4;
  }
}

/* Additional styles for even smaller screens */
@media screen and (max-width: 428px) {
  .discover-videos-container {
    height: 300px;
    margin-top: 10px;
  }

  .discover-video-content {
    padding: 20px;
  }

  .discover-video-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .watch-youtube-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .spotify-title {
    font-size: 0.8em;
  }

  .spotify-section .spotify-content .spotify-logo,
  .spotify-section .spotify-logo {
    width: 35px !important;
    height: 35px !important;
  }
}

.travel-section {
  background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
  border-radius: 20px;
  padding: 30px;
  margin: 20px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.travel-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.travel-link {
  text-decoration: none;
  color: white;
  display: block;
}

.travel-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.travel-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.travel-beta-badge {
  background: rgba(255, 255, 255, 0.2);
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.travel-icon {
  width: 80px;
  height: auto;
}

.travel-description {
  font-size: 1.2rem;
  margin-top: 15px;
  color: white;
}

/* Update these styles for more subtlety */
.video-nav-arrows {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  z-index: 3;
  transform: translateY(-50%);
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease;
}

.discover-video-wrapper:hover .video-nav-arrows {
  opacity: 1; /* Show on hover */
}

.video-nav-button {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.7);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
  font-size: 1.2rem;
}

.video-nav-button:hover {
  background: rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 255, 255, 0.8);
  color: white;
  transform: scale(1.05);
}

.video-nav-button:active {
  transform: scale(0.95);
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .video-nav-button {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }
  
  .video-nav-arrows {
    padding: 0 15px;
    /* Always visible on mobile for better UX */
    opacity: 1;
  }
}

/* Add these new styles */
.video-language-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}

.video-language-select {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  color: white;
  border: 2px solid white;
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  padding-right: 35px; /* Space for the custom arrow */
  outline: none;
}

.video-language-select:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-2px);
}

/* Custom dropdown arrow */
.video-language-selector::after {
  content: '▼';
  font-size: 0.8em;
  color: white;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Dropdown options styling */
.video-language-select option {
  background: #1b1b2f;
  color: white;
  padding: 10px;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .video-language-selector {
    top: 15px;
    right: 15px;
  }

  .video-language-select {
    padding: 6px 30px 6px 12px;
    font-size: 0.8rem;
  }
}

/* Update/add these styles */
.video-actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 15px;
}

.video-language-select {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 8px 28px 8px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
}

.video-language-select:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.5);
}

/* Custom dropdown arrow */
.video-actions {
  position: relative;
}

.video-language-select {
  position: relative;
}

.video-language-select::after {
  content: '▼';
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .video-actions {
    flex-wrap: wrap;
    gap: 8px;
  }

  .video-language-select {
    font-size: 0.8rem;
    padding: 6px 24px 6px 10px;
  }
}

.language-select-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.video-language-select {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 8px 35px 8px 15px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
}

.video-language-select:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.4);
}

.dropdown-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
  transition: transform 0.2s ease;
}

.language-select-wrapper:hover .dropdown-icon {
  color: rgba(255, 255, 255, 1);
}

/* Style the dropdown options */
.video-language-select option {
  background: #1b1b2f;
  color: white;
  padding: 8px;
  font-size: 0.85rem;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .video-language-select {
    padding: 6px 30px 6px 12px;
    font-size: 0.8rem;
  }

  .dropdown-icon {
    width: 14px;
    height: 14px;
    right: 10px;
  }
}