/* Welcome Screen */
.speakeasy-welcome-container {
  padding: 40px 40px;
  border-radius: 20px;
  max-width: 900px;
  margin: 60px auto 60px;
  color: #fff;
  border: 1px solid rgba(108, 99, 255, 0.1);
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 60px;
}

.speakeasy-welcome-header {
  text-align: center;
  margin-bottom: 40px;
}

.speakeasy-welcome-header h1 {
  font-size: 2.5em;
  color: #6c63ff;
  margin-bottom: 15px;
}

.speakeasy-welcome-header p {
  color: #bbb;
  font-size: 1.1em;
  line-height: 1.6;
}

.speakeasy-features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 30px 0;
}

.speakeasy-feature-card {
  background: rgba(108, 99, 255, 0.1);
  border: 1px solid rgba(108, 99, 255, 0.2);
  border-radius: 15px;
  padding: 25px;
  text-align: center;
  transition: transform 0.3s ease;
}

.speakeasy-feature-card:hover {
  transform: translateY(-5px);
  background: rgba(108, 99, 255, 0.15);
}

.feature-icon {
  font-size: 2em;
  margin-bottom: 15px;
}

.speakeasy-feature-card h3 {
  color: #6c63ff;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.speakeasy-feature-card p {
  color: #bbb;
  font-size: 0.9em;
  line-height: 1.5;
}

.speakeasy-start-chat-btn {
  background: linear-gradient(135deg, #6c63ff 0%, #4a47a3 100%);
  color: white;
  border: none;
  padding: 15px 40px;
  border-radius: 30px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 40px auto 0;
}

.speakeasy-start-chat-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.4);
}

/* Main Conversation Container */
.speakeasy-conversation {
  background: #0f0e17;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  margin: 20px auto;
  max-width: 1200px;
  border-radius: 20px;
  border: 1px solid rgba(108, 99, 255, 0.1);
  overflow: hidden;
}

/* Tips Section */
.speakeasy-tips {
  width: 280px;
  background: rgba(108, 99, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  height: fit-content;
}

.speakeasy-tips h3 {
  color: #6c63ff;
  font-size: 1.2em;
  margin-bottom: 15px;
}

.speakeasy-tips ul {
  list-style: none;
  padding: 0;
}

.speakeasy-tips li {
  color: #bbb;
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
  line-height: 1.4;
}

.speakeasy-tips li::before {
  content: "💡";
  position: absolute;
  left: 0;
  top: 0;
}

/* Main Chat Area */
.speakeasy-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #1b1b2f;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  max-height: 100%;
}

.speakeasy-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.speakeasy-messages.no-scroll {
  overflow: hidden;
}

/* Message Styles */
.speakeasy-message {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.speakeasy-message.user {
  align-self: flex-end;
  margin-left: auto;
}

.speakeasy-message.ai {
  align-self: flex-start;
}

/* Profile picture and message content */
.ai-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  background: #2a2a4a;
  border: 2px solid rgba(108, 99, 255, 0.2);
}

.ai-profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message-content-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  width: 100%;
}

/* Message content */
.speakeasy-message.user .speakeasy-message-content {
  background: #6c63ff;
  color: white;
  border-radius: 18px 18px 0 18px;
  padding: 12px 16px;
  margin-left: auto;
}

.speakeasy-message.ai .speakeasy-message-content {
  background: #2a2a4a;
  color: #fff;
  border-radius: 18px 18px 18px 0;
  padding: 12px 16px;
  max-width: calc(100% - 92px);
  flex: 1;
}

/* Tutor info */
.tutor-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  padding-left: 52px;
  font-size: 0.9rem;
  color: #bbb;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .ai-profile-pic {
    width: 32px;
    height: 32px;
  }
  
  .speakeasy-message.ai .speakeasy-message-content {
    max-width: calc(100% - 76px);
  }
}

/* Input Area */
.speakeasy-input-container {
  padding: 15px;
  background: #2a2a4a;
  display: flex;
  gap: 10px;
}

.speakeasy-input {
  flex-grow: 1;
  padding: 12px 20px;
  border: 2px solid rgba(108, 99, 255, 0.3);
  border-radius: 50px;
  background: #1b1b2f;
  color: #fff;
  font-size: 0.95rem;
}

.speakeasy-input:focus {
  border-color: #6c63ff;
  outline: none;
}

/* Matching Account Page Button Style */
.speakeasy-send-btn {
  padding: 12px 24px;
  font-size: 0.9em;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 100px;
}

.speakeasy-send-btn:hover:not(:disabled) {
  background-color: rgba(15, 14, 23, 0.8);
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.speakeasy-send-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .speakeasy-welcome-container {
    margin: 60px 15px 15px;
    padding: 40px 15px;
    min-height: calc(100vh - 120px);
  }

  .speakeasy-features-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .speakeasy-welcome-header h1 {
    font-size: 2em;
  }

  .speakeasy-feature-card {
    padding: 20px;
  }

  .speakeasy-conversation {
    margin: 10px;
    padding: 15px;
    min-height: calc(100vh - 60px);
  }
  
  .speakeasy-message {
    max-width: 85%;
  }

  .speakeasy-send-btn {
    padding: 10px 20px;
    font-size: 0.85em;
    min-width: 80px;
  }
} 

/* Update typing animation styles */
.speakeasy-typing {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 80px;
}

.speakeasy-typing span {
  white-space: nowrap;
}

.speakeasy-typing-dots {
  display: flex;
  gap: 4px;
  margin-left: 4px;
}

.speakeasy-typing-dot {
  width: 6px;
  height: 6px;
  background: #6c63ff;
  border-radius: 50%;
  animation: typing 1.4s infinite ease-in-out;
}

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-6px); }
} 

/* Conversation Header */
.speakeasy-conversation-header {
  background: rgba(108, 99, 255, 0.1);
  padding: 15px 20px;
  border-radius: 15px 15px 0 0;
  margin-bottom: 0;
}

.conversation-title {
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 5px;
}

.conversation-subtitle {
  color: #bbb;
  font-size: 0.9em;
}

.conversation-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.language-label {
  color: #fff;
  font-size: 1.1em;
  font-weight: 600;
}

.level-badge {
  background: #6c63ff;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9em;
  font-weight: 600;
}

.close-conversation-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.close-conversation-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(90deg);
}

/* Update responsive styles */
@media (max-width: 1024px) {
  .speakeasy-conversation {
    flex-direction: column;
    max-width: 800px;
  }

  .speakeasy-tips {
    width: 100%;
    order: 2;
  }
} 

/* Enhanced mobile styles */
@media (max-width: 768px) {
  /* Welcome Container */
  .speakeasy-welcome-container {
    margin: 10px;
    padding: 15px;
    border-radius: 15px;
  }

  .speakeasy-welcome-header {
    margin-bottom: 20px;
  }

  .speakeasy-welcome-header h1 {
    font-size: 1.8em;
  }

  .speakeasy-welcome-header p {
    font-size: 1em;
    line-height: 1.4;
  }

  /* Features Grid */
  .speakeasy-features-grid {
    grid-template-columns: 1fr;
    gap: 15px;
    margin: 20px 0;
  }

  .speakeasy-feature-card {
    padding: 15px;
  }

  .feature-icon {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .speakeasy-feature-card h3 {
    font-size: 1.1em;
  }

  .speakeasy-feature-card p {
    font-size: 0.85em;
  }

  /* Start Chat Button */
  .speakeasy-start-chat-btn {
    padding: 12px 30px;
    font-size: 1em;
    margin: 30px auto 0;
    width: 100%;
    max-width: 280px;
  }

  /* Main Conversation */
  .speakeasy-conversation {
    margin: 10px;
    padding: 10px;
    gap: 10px;
    height: auto;
    min-height: calc(100vh - 40px);
  }

  /* Tips Section */
  .speakeasy-tips {
    padding: 15px;
  }

  .speakeasy-tips h3 {
    font-size: 1.1em;
  }

  .speakeasy-tips li {
    font-size: 0.9em;
    padding-left: 20px;
    margin-bottom: 10px;
  }

  /* Messages */
  .speakeasy-message {
    max-width: 85%;
  }

  .speakeasy-message-content {
    padding: 12px 16px;
    font-size: 0.95rem;
  }

  /* Input Area */
  .speakeasy-input-container {
    padding: 10px;
  }

  .speakeasy-input {
    padding: 10px 15px;
    font-size: 0.9rem;
  }

  .speakeasy-send-btn {
    padding: 10px 20px;
    font-size: 0.85em;
    min-width: 70px;
  }

  /* Header */
  .speakeasy-conversation-header {
    padding: 12px 15px;
  }

  .conversation-title {
    font-size: 1.1em;
  }

  .conversation-subtitle {
    font-size: 0.8em;
  }

  .language-label {
    font-size: 1em;
  }

  .level-badge {
    padding: 3px 10px;
    font-size: 0.8em;
  }

  .close-conversation-btn {
    font-size: 20px;
    width: 32px;
    height: 32px;
  }
}

/* Extra small devices */
@media (max-width: 480px) {
  .speakeasy-welcome-header h1 {
    font-size: 1.5em;
  }

  .speakeasy-feature-card {
    padding: 12px;
  }

  .speakeasy-message {
    max-width: 90%;
  }

  .speakeasy-input-container {
    flex-direction: column;
    gap: 8px;
  }

  .speakeasy-send-btn {
    width: 100%;
  }

  .conversation-info {
    flex-wrap: wrap;
    gap: 8px;
  }
}

/* Handle landscape orientation */
@media (max-height: 600px) and (orientation: landscape) {
  .speakeasy-conversation {
    height: auto;
    min-height: 100vh;
  }

  .speakeasy-messages {
    max-height: 40vh;
  }

  .speakeasy-welcome-container {
    padding: 15px;
  }

  .speakeasy-features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Update mobile styles */
@media (max-width: 768px) {
  .speakeasy-welcome-container {
    margin: 70px 15px 15px;
    padding: 25px 15px;
  }
}

/* Translation UI */
.speakeasy-message-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.speakeasy-translate-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border: none;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.speakeasy-translate-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #333;
}

.translate-icon {
  font-size: 1rem;
}

.speakeasy-translation-loading {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 0.9rem;
}

.translation-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.speakeasy-translated-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.translation-divider {
  position: relative;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 8px 0;
}

.translation-label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  background: white;
  padding: 0 8px;
  color: #666;
  font-size: 0.8rem;
}

.translated-text {
  color: #2563eb;
  font-weight: 500;
}

.speakeasy-reset-translation {
  align-self: flex-start;
  padding: 4px 8px;
  border: none;
  background: none;
  color: #666;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration: underline;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add these new styles */
.speakeasy-message-content.clickable {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.message-text {
  transition: opacity 0.3s ease;
  flex: 1;
}

.message-text.fade-out {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.message-text.fade-in {
  opacity: 1;
}

.message-text.translated {
  color: inherit;
}

.translation-action-btn {
  background: none;
  border: none;
  color: #6c63ff;
  font-size: 0.75em;
  padding: 2px 0;
  cursor: pointer;
  opacity: 0.7;
  margin-top: 4px;
  margin-left: 52px;
  align-self: flex-start;
}

.translation-action-btn:hover {
  opacity: 1;
  text-decoration: underline;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .translation-action-btn {
    margin-left: 44px;
  }
}

@media screen and (max-width: 390px) {
  .translation-action-btn {
    margin-left: 32px;
  }
}

/* Voice controls and speak button */
.voice-controls {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 0;
}

.speak-button {
  background: transparent;
  border: none;
  color: #6c63ff;
  opacity: 0.6;
  cursor: pointer;
  padding: 4px;
  font-size: 1.2em;
  transition: all 0.2s ease;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.speak-button:hover {
  opacity: 1;
}

.speak-button:disabled {
  cursor: default;
  opacity: 0.3;
}

.speak-button.playing {
  color: #ff6b6b;
  opacity: 1;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .voice-controls {
    right: -35px;
  }
  
  .speak-button {
    font-size: 1em;
    width: 24px;
    height: 24px;
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .ai-profile-pic {
    width: 32px;
    height: 32px;
    border-width: 1.5px;
  }
  
  .speakeasy-message.ai .speakeasy-message-content {
    padding: 8px 12px;
    max-width: calc(100% - 45px);
  }
}

/* Extra small devices */
@media screen and (max-width: 390px) {
  .ai-profile-pic {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
  
  .speakeasy-message.ai .speakeasy-message-content {
    padding: 6px 8px;
    max-width: calc(100% - 35px);
  }
}

/* Add these new styles */
.tutor-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  font-size: 0.9rem;
  color: #bbb;
}

.tutor-name {
  font-weight: 600;
  color: #6c63ff;
}

.tutor-title {
  font-style: italic;
  font-size: 0.8rem;
}

/* New separate container for translation actions */
.translation-actions-container {
  display: flex;
  gap: 8px;
  margin-top: 4px;
  margin-left: 52px;
  margin-bottom: 8px;
}

.translation-action-btn {
  background: none;
  border: none;
  color: #6c63ff;
  font-size: 0.75em;
  padding: 2px 0;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.translation-action-btn:hover {
  opacity: 1;
  text-decoration: underline;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .translation-actions-container {
    margin-left: 44px;
  }
}

/* Extra small devices */
@media screen and (max-width: 390px) {
  .translation-actions-container {
    margin-left: 32px;
  }
}

.speakeasy-message-container {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  align-self: flex-start;
}

.speakeasy-message.user {
  align-self: flex-end;
}

.translation-actions-container {
  display: flex;
  gap: 8px;
  margin-top: 4px;
  margin-left: 52px;
  margin-bottom: 4px;
}

.translation-action-btn {
  background: none;
  border: none;
  color: #6c63ff;
  font-size: 0.75em;
  padding: 2px 0;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.translation-action-btn:hover {
  opacity: 1;
  text-decoration: underline;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .translation-actions-container {
    margin-left: 44px;
  }
}

@media screen and (max-width: 390px) {
  .translation-actions-container {
    margin-left: 32px;
  }
}

.speakeasy-message-content {
  position: relative;
  padding: 12px 16px;
  border-radius: 18px;
  font-size: 0.95rem;
  line-height: 1.4;
}

/* Voice controls and speak button */
.voice-controls {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 0;
}

.speak-button {
  background: transparent;
  border: none;
  color: #6c63ff;
  opacity: 0.6;
  cursor: pointer;
  padding: 4px;
  font-size: 1.2em;
  transition: all 0.2s ease;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.speak-button:hover {
  opacity: 1;
}

.speak-button:disabled {
  cursor: default;
  opacity: 0.3;
}

.speak-button.playing {
  color: #ff6b6b;
  opacity: 1;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .voice-controls {
    right: -35px;
  }
  
  .speak-button {
    font-size: 1em;
    width: 24px;
    height: 24px;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  background: #1b1b2f;
  border-radius: 50px;
  padding: 0 8px;
}

.speakeasy-input {
  flex: 1;
  padding: 12px 16px;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 0.95rem;
}

.speakeasy-mic-btn {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  color: #6c63ff;
  opacity: 0.6;
  cursor: pointer;
  padding: 8px;
  font-size: 1.2em;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
}

.speakeasy-mic-btn:hover:not(:disabled) {
  opacity: 1;
  background: none !important;
  transform: none !important;
}

.speakeasy-mic-btn.listening {
  color: #ff6b6b !important;
  opacity: 0.8;
  background: none !important;
  animation: subtlePulse 2s infinite;
}

@keyframes subtlePulse {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

.limit-message-wrapper {
  padding: 12px;
  background: #fff3e0;
  border-radius: 8px;
  border-left: 4px solid #ff9800;
}

.speakeasy-message-content.limit-reached {
  color: #e65100;
  font-weight: 500;
}

/* Add these new styles */
.limit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0f0e17;
  border-radius: 20px;
  padding: 40px;
  max-width: 500px;
  width: 90%;
  border: 1px solid rgba(108, 99, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  z-index: 3001;
  color: #fff;
  animation: limitPopupFadeIn 0.8s ease;
}

.limit-popup-content {
  color: #bbb;
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: center;
}

.limit-popup-button {
  background: linear-gradient(135deg, #6c63ff 0%, #4a47a3 100%);
  color: white;
  border: none;
  padding: 15px 40px;
  border-radius: 30px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
}

.limit-popup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.4);
}

/* Update modal overlay to match welcome screen */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 14, 23, 0.8);
  z-index: 3000;
  backdrop-filter: blur(4px);
  animation: overlayFadeIn 0.6s ease;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .limit-popup {
    padding: 30px 20px;
  }

  .limit-popup-content {
    font-size: 1em;
  }

  .limit-popup-button {
    padding: 12px 30px;
    font-size: 1em;
  }
}

/* New animations */
@keyframes limitPopupFadeIn {
  0% { 
    opacity: 0; 
    transform: translate(-50%, -40%); 
  }
  100% { 
    opacity: 1; 
    transform: translate(-50%, -50%); 
  }
}

@keyframes overlayFadeIn {
  0% { 
    opacity: 0; 
  }
  100% { 
    opacity: 1; 
  }
}

/* Update pro modal styles */
.pro-modal {
  z-index: 2000;
}

@keyframes limitPopupFadeOut {
  0% { 
    opacity: 1; 
    transform: translate(-50%, -50%); 
  }
  100% { 
    opacity: 0; 
    transform: translate(-50%, -60%); 
  }
}

/* Ensure limit popup stays on top */
.limit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0f0e17;
  border-radius: 20px;
  padding: 40px;
  max-width: 500px;
  width: 90%;
  border: 1px solid rgba(108, 99, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  z-index: 3001;
  color: #fff;
  animation: limitPopupFadeIn 0.8s ease;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 14, 23, 0.8);
  z-index: 3000;
  backdrop-filter: blur(4px);
}

/* Override any ReactModal styles */
.ReactModal__Overlay {
  z-index: 2000 !important;
}

.ReactModal__Content {
  z-index: 2001 !important;
}