/* WelcomeModal.css */

.welcome-modal {
  background-color: #0f0e17; /* Dark background consistent with home page */
  border-radius: 12px;
  padding: 30px;
  max-width: 550px;
  width: 100%;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  color: #eaeaea; /* Light text to match the home page */
  text-align: center;
  animation: fadeInModal 0.5s ease-in-out;
}

.welcome-modal-overlay {
  background-color: rgba(0, 0, 0, 0.85); /* Slightly darker overlay */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@keyframes fadeInModal {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-title {
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.modal-text {
  font-size: 1.3rem;
  margin-bottom: 30px;
  color: #b3b3b3;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  gap: 25px;
}

.modal-button {
  padding: 12px 28px;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  background-color: #6c63ff; /* Matching primary color */
  color: white;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 8px 15px rgba(108, 99, 255, 0.4);
}

.modal-button:hover {
  background-color: #4b43cc;
  transform: translateY(-2px);
  box-shadow: 0 12px 20px rgba(108, 99, 255, 0.6);
}

.close-button {
  background-color: #f44336;
}

.close-button:hover {
  background-color: #c23629;
  transform: translateY(-2px);
  box-shadow: 0 12px 20px rgba(244, 67, 54, 0.6);
}
