.wordpack-intro-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}

.wordpack-intro-modal {
  background: linear-gradient(135deg, #2a2a4a 0%, #1b1b2f 100%);
  border-radius: 15px;
  padding: 20px;
  width: 95%;
  max-width: 500px;
  max-height: 85vh;
  position: relative;
  color: white;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}

.intro-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s;
}

.intro-close-button:hover {
  transform: scale(1.1);
}

.intro-content {
  text-align: center;
  padding: 10px 0;
  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.intro-icon {
  font-size: 2em;
  color: #6c63ff;
  margin-bottom: 15px;
}

.intro-title {
  font-size: 1.3em;
  margin-bottom: 15px;
  color: white;
}

.intro-body {
  font-size: 0.9em;
  line-height: 1.4;
  margin-bottom: 15px;
}

.example-phrases {
  gap: 8px;
}

.example-phrase {
  background: rgba(108, 99, 255, 0.05);
  border-radius: 12px;
  padding: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(108, 99, 255, 0.1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.example-phrase:hover {
  transform: translateY(-2px);
  background: rgba(108, 99, 255, 0.1);
  box-shadow: 0 8px 20px rgba(108, 99, 255, 0.15);
  border-color: rgba(108, 99, 255, 0.3);
}

.example-phrase::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(108, 99, 255, 0.1) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.example-phrase:hover::before {
  transform: translateX(100%);
}

.phrase {
  font-weight: bold;
  margin-bottom: 6px;
  color: #6c63ff;
  font-size: 0.95em;
  letter-spacing: 0.5px;
}

.translation {
  color: #a0a0a0;
  transition: color 0.3s ease;
  font-size: 0.85em;
}

.example-phrase:hover .translation {
  color: #ffffff;
}

.conversation-examples {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 10px;
}

.conversation-exchange {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 8px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.exchange-initial, .exchange-response {
  flex: 1;
  background: rgba(108, 99, 255, 0.05);
  padding: 12px;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(108, 99, 255, 0.1);
}

.exchange-arrow {
  color: #6c63ff;
  opacity: 0.7;
  transition: transform 0.3s ease;
}

.conversation-exchange:hover .exchange-arrow {
  transform: scale(1.2);
  opacity: 1;
}

.conversation-exchange:hover .exchange-initial,
.conversation-exchange:hover .exchange-response {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.15);
  background: rgba(108, 99, 255, 0.1);
  border-color: rgba(108, 99, 255, 0.3);
}

.conversation-footer {
  margin-top: 15px;
  padding: 12px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  font-style: italic;
  color: #a0a0a0;
  text-align: center;
  transition: all 0.3s ease;
  font-size: 0.9em;
}

.conversation-footer:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.intro-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid rgba(108, 99, 255, 0.2);
  margin-top: auto;
  background: transparent;
  position: relative;
}

.slide-dots {
  display: flex;
  gap: 8px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background: #6c63ff;
}

.intro-next-button {
  background: #6c63ff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1.1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.2s, background-color 0.2s;
}

.intro-next-button:hover {
  background: #5b54d6;
  transform: translateY(-2px);
}

.arrow-icon {
  transition: transform 0.2s;
}

.intro-next-button:hover .arrow-icon {
  transform: translateX(5px);
}

@media (max-width: 480px) {
  .wordpack-intro-modal {
    padding: 15px;
    max-height: 80vh;
  }

  .intro-close-button {
    top: 10px;
    right: 10px;
    font-size: 1.2em;
  }

  .intro-content {
    padding: 10px 0;
  }

  .intro-icon {
    font-size: 2em;
    margin-bottom: 15px;
  }
} 