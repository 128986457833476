.sign-in-page {
  display: flex;
  position: fixed; /* Make the container fixed to the viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Use full viewport width */
  height: 100vh; /* Use full viewport height */
  background-color: #0f0e17;
  overflow: hidden; /* Disable vertical scrolling */
}

.sign-in-left {
  flex: 0.3; /* Take 30% of the page */
  position: relative;
  overflow: hidden;
  background-color: #000;
  border-right: 3px solid #777; /* Thin gray border */
  padding: 0;
  position: relative; /* Position relative for pseudo-element */
  overflow: hidden; /* Ensure the pseudo-element stays within bounds */
}

.video-slider {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 200%; /* 100% * number of videos */
  height: 100%;
  transition: transform 1.5s ease-in-out;
}

.video-slide {
  flex: 0 0 50%; /* 100% / number of videos */
  height: 100%;
  position: relative;
  overflow: hidden;
}

.sign-in-hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
}

.sign-in-left-content {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-in-logo {
  position: absolute; /* Position it absolutely */
  top: 20px; /* 20px from the top */
  right: 20px; /* 20px from the right */
  max-width: 120px;
  cursor: pointer;
}

.sign-in-right {
  flex: 0.7; /* Take 70% of the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #ffffff; /* White text */
}

.sign-in-title {
  margin-bottom: 30px;
  font-size: 28px;
  color: #ffffff; /* White text */
  text-align: center;
}

.sign-in-title-forgot-pass {
  margin-bottom: 0px;
  font-size: 28px;
  color: #ffffff; /* White text */
  text-align: center;
}


.sign-in-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sign-in-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sign-in-label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  color: #bbbbbb; /* Lighter gray for labels */
}


.password-input-wrapper {
  position: relative;
  width: 100%; /* Ensure the wrapper takes the full width */
  max-width: 400px; /* Example max-width to keep the input container size consistent */
  margin: 0 auto; /* Center the container */
}

.sign-in-input {
  width: 100%; /* Take full width of the container */
  padding: 10px 40px 10px 10px; /* Space for the eye icon on the right */
  border-radius: 4px;
  border: 1px solid #333;
  font-size: 14px;
  background-color: #1e1e1e;
  color: #ffffff;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
}

.password-toggle-icon:hover {
  color: #333;
}


.sign-in-button {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(45deg, #ff6b6b, #f06595, #cc5de8);
  background-size: 200% 200%;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: background-position 0.5s, box-shadow 0.3s, transform 0.3s;
}

.sign-in-button:hover {
  background-position: 100% 0;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  transform: translateY(-5px);
}

.sign-in-or-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: 14px;
  color: #bbbbbb;
  width: 100%;
  position: relative; /* Ensure the lines are contained within the parent */
}

.sign-in-or-divider::before,
.sign-in-or-divider::after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: #444; /* Dark gray line */
  margin: 0 10px;
}

.sign-in-or-divider span {
  white-space: nowrap; /* Prevent line break */
  padding: 0 10px;
  color: #bbbbbb; /* Text color */
}

.sign-in-oauth-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.sign-in-oauth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #333;
  background-color: #1e1e1e;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #ffffff;
}

.sign-in-oauth-icon {
  margin-right: 10px;
  height: 20px;
}

.sign-in-oauth-button:hover {
  background-color: #38385a;
}

.sign-in-signup-link {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9em;
}

.sign-in-signup-link-text {
  font-weight: bold;
  color: #6c63ff; /* Light purple color */
  text-decoration: none;
}

.sign-in-signup-link-text:hover {
  text-decoration: underline;
}
.sign-in-forgot-password {
  margin-top: 10px;
  text-align: center;
  font-size: 0.9em;
}

.sign-in-forgot-password-link {
  color: #6c63ff; /* Your desired purple color */
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9em;
}

.sign-in-forgot-password-link:hover {
  text-decoration: underline;
}

.sign-in-instructions {
  font-size: 1.1em;
  color: #cccccc; /* Light gray color to match your existing theme */
  margin-bottom: 30px; /* Adds some space below the instructions */
  text-align: center; /* Centers the text */
  line-height: 1.5; /* Adds some line height for better readability */
  padding: 0 20px; /* Adds some padding on the sides to give it some breathing room */
}

.sign-in-title-reset-pass {
  margin-bottom: 30px;
  font-size: 28px;
  color: #ffffff;
  text-align: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .sign-in-page {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    position: relative;
  }

  /* Hide the left section with background image on mobile */
  .sign-in-left {
    display: none;
  }

  .sign-in-right {
    flex: 1;
    padding: 20px;
    width: 100%;
  }

  /* Adjust logo for mobile */
  .sign-in-logo {
    position: absolute;
    top: 15px;
    right: 15px;
    max-width: 80px; /* Smaller logo size */
    z-index: 10; /* Ensure it stays on top */
  }

  .sign-in-title {
    font-size: 24px;
    margin-top: 60px; /* Add space below logo */
    margin-bottom: 20px;
  }

  .video-slider,
  .sign-in-hero-video {
    display: none;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .sign-in-right {
    padding: 15px;
  }

  .sign-in-logo {
    max-width: 70px;
    top: 10px;
    right: 10px;
  }

  .sign-in-title {
    font-size: 20px;
    margin-top: 50px;
  }
}

.error-message {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: #ff4444;
  padding: 10px 15px;
  border-radius: 8px;
  margin: 10px 0;
  font-size: 0.9em;
  text-align: center;
  min-height: 42px; /* Fixed height to prevent layout shifts */
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.error-message-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms ease;
}

.error-message-exit {
  opacity: 1;
  transform: translateY(0);
}

.error-message-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: all 300ms ease;
}

.error-message-container {
  min-height: 62px; /* Container height to prevent layout shifts */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}