.chat-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: #0f0e17;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden; /* Prevent overall page scrolling */
    overflow-y: hidden;

}

.chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0f0e17;
    color: #e0e0e0;
    height: calc(100vh - 40px); /* Take full viewport height minus margin-top */
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    overflow: hidden; /* Ensure no overflow */
    position: relative; /* Add this */
}

.header {
    text-align: center;
    margin-bottom: 0; /* Remove the bottom margin */
    padding-bottom: 10px; /* Add padding instead */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.chat-title {
    margin-bottom: 10px;
}

.header h1 {
    margin: 0;
    font-size: 1.8em;
    color: #ffffff;
}

.header p {
    margin: 0;
    margin-top: 8px;
    font-size: 1.2em;
    color: #ffffff;
}

.chat-info {
    margin-bottom: 10px;
}

.progress-bar-container {
    position: absolute; /* Change to absolute positioning */
    top: 20px; /* Adjust as needed */
    right: 20px; /* Position on the right side */
    padding: 6px;
    border-radius: 8px;
    width: 150px; /* Adjust width as needed */
    box-sizing: border-box;
    z-index: 10; /* Ensure it's above other elements */
}

.progress-bar-container h3 {
    font-size: 0.9em;
    margin-bottom: 5px;
    color: #ffffff;
}

.progress-bar-container p {
    font-size: 0.8em;
    color: #ffffff;
}

.progress-bar {
    width: 100%;
    background-color: #444;
    border-radius: 10px;
    overflow: hidden;
    height: 10px;
    margin: 5px 0;
}

.progress-bar-fill {
    height: 100%;
    background-color: #bb86fc;
    width: 0;
    transition: width 0.3s ease-in-out;
}

.chat-messages-container {
    display: flex;
    flex-direction: column;
    width: 90%; /* Changed from fixed width to percentage */
    max-width: 1200px; /* Maximum width */
    height: calc(75vh - 40px); /* Increased height */
    background-color: #161515;
    border-radius: 10px;
    overflow: hidden; /* Hide any overflow */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(125, 116, 116);
    position: relative;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    margin: 0 auto; /* Center horizontally */
    margin-top: 0; /* Remove top margin if any */
}

.members-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(90deg, #1f1f1f, #333); /* Gradient background */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    z-index: 10;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.members-navbar .left-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.members-navbar .right-section {
    display: flex;
    align-items: center;
    margin-left: auto; /* Push to the right */
    padding-right: 30px; /* Ensure visibility */
}

.members-navbar .title {
    margin-right: 15px;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2em; /* Adjusted font size */
    margin-left: 15px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow */
    cursor: pointer; /* Pointer cursor */
    transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.members-navbar .title:hover {
    transform: scale(1.1);
}

.attendee-profile-pic {
    margin-bottom: 0px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: -10px;
    border: 2px solid #0f0e17;
    position: relative;
    z-index: 1;
    transition: transform 0.2s ease-in-out; /* Smooth scaling effect */
}

.attendee-profile-pic:hover {
    transform: scale(1.2); /* Scale up on hover */
}

.attendee-profile-pic:first-of-type {
    margin-left: 0;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding: 10px; /* Reduced padding */
}

.chat-message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px; /* Adjusted margin */
    position: relative;
    animation: fadeIn 0.5s ease-in-out;
    transition: transform 0.3s ease;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.chat-message.active {
    transform: scale(1.02); /* Increase size when active */
}

.own-message {
    flex-direction: row-reverse;
    align-self: flex-end;
}

.profile-pic {
    width: 40px; /* Adjusted size */
    height: 40px; /* Adjusted size */
    border-radius: 50%;
    margin-right: 10px;
}

.own-message .profile-pic {
    margin-right: 0;
    margin-left: 10px;
}

.message-content {
    background-color: #161515;
    padding: 10px 15px; /* Adjusted padding */
    border-radius: 12px; /* Adjusted radius */
    display: flex;
    flex-direction: column;
    color: #eaeaea;
    max-width: calc(100% - 60px); /* Adjusted width */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative; /* Make the message content relative */
}

.own-message .message-content {
    background-color: #4a47a3;
    color: white;
}

.display-name {
    font-weight: bold;
    margin-bottom: 5px; /* Adjusted margin */
    font-size: 1em; /* Adjusted font size */
}

.timestamp {
    font-size: 0.8em; /* Adjusted font size */
    color: #888;
    align-self: flex-end;
    margin-top: 5px; /* Adjusted margin */
}

.typing-indicator {
    font-size: 0.8em; /* Adjusted font size */
    color: #888;
    margin-top: 8px;
    align-self: flex-start;
}

.typing-indicator::after {
    content: '...';
    animation: typing 1s steps(3, end) infinite;
}

@keyframes typing {
    from { width: 0 }
    to { width: 1.25em }
}

.chat-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%; /* Ensure it's slightly less wide than chat-messages-container */
    max-width: 1200px; /* Match the max-width of chat-messages-container */
    background-color: #0f0e17;
    padding: 10px;
    border-top: 1px solid #333;
    border-radius: 0 0 10px 10px;
    margin: 10px auto 0 auto; /* Center the input container */
    justify-content: center; /* Vertically center items */
    box-sizing: border-box;
}

.chat-input {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure the button stays next to the input */
    width: 100%;
    max-width: 1160px; /* Slightly smaller than chat-messages-container to account for padding */
    margin-top: 10px; /* Add margin between reply section and input */
}

.chat-input input {
    flex-grow: 1; /* Ensures the input takes as much space as possible */
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    margin-right: 10px;
    background-color: #333;
    color: #eaeaea;
    font-size: 1em;
    outline: none;
}

.send-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #bb86fc;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    transform: translate(2px, -6px); /* Fixed typo: should use transform */
}

.send-button:hover {
    background-color: #8a63d2;
}

.send-button i, .send-button svg {
    font-size: 18px;
}

.footer {
    margin-top: auto; /* Ensures it stays at the bottom */
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    color: #888;
}

.reply-context {
    background-color: #444;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 5px;
    color: #aaa;
    font-size: 0.9em;
}

.reply-display-name {
    font-weight: bold;
}

.replying-to {
    background-color: #444;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 0px; /* No extra margin that pushes the input */
    color: #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Ensure it takes full width without affecting input layout */
    max-width: 1200px;
    box-sizing: border-box;
}

.replying-to button {
    background: none;
    border: none;
    color: #bb86fc;
    cursor: pointer;
}

.reply-button {
    color: #bb86fc;
    cursor: pointer;
    font-size: 0.8em;
}

.reply-button:hover {
    text-decoration: underline;
}

.message-options {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.chat-message.active .message-options {
    display: block;
}

.message-options button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    margin-left: 5px;
}

.message-options button:hover {
    text-decoration: underline;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .chat-page-container {
    padding: 10px 0;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
  }

  .chat-container {
    padding: 5px;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .header {
    padding-bottom: 5px;
  }

  .header h1 {
    font-size: 1.4em;
  }

  .header p {
    font-size: 1em;
  }

  /* Progress Bar */
  .progress-bar-container {
    position: relative;
    top: 0;
    right: 0;
    width: 120px;
    margin: 5px 0;
  }

  /* Messages Container - Key Fix */
  .chat-messages-container {
    width: 95%;
    margin: 5px auto;
    max-width: 100%;
  }

  .chat-messages {
    padding: 8px;
    height: calc(100% - 50px);
    overflow-y: auto;
  }

  /* Members Navbar */
  .members-navbar {
    width: 100%;
    padding: 8px 5px;
  }

  .members-navbar .title {
    font-size: 1em;
    margin-left: 8px;
  }

  .attendee-profile-pic {
    height: 20px;
    width: 20px;
    margin-left: -8px;
  }

  /* Messages */
  .chat-messages {
    padding: 8px;
  }

  .chat-message {
    margin-bottom: 10px;
  }

  .profile-pic {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .own-message .profile-pic {
    margin-left: 8px;
  }

  .message-content {
    padding: 8px 12px;
    max-width: calc(100% - 45px);
  }

  .display-name {
    font-size: 0.9em;
  }

  .timestamp {
    font-size: 0.7em;
  }

  /* Input Area */
  .chat-input-container {
    width: 100%;
    max-width: 100%;
    padding: 8px 0;
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + 70px);
    left: 0;
    background-color: #0f0e17;
    z-index: 99;
  }

  .chat-input {
    width: 95%;
    margin: 0 auto;
    padding: 0;
  }

  .send-button {
    width: 35px;
    height: 35px;
    transform: translate(0, -4px);
  }

  .send-button i, 
  .send-button svg {
    font-size: 16px;
  }

  /* Reply Context */
  .replying-to {
    padding: 4px 8px;
    font-size: 0.8em;
  }

  .reply-context {
    padding: 4px;
    font-size: 0.8em;
  }

  /* Update header layout for mobile */
  .header {
    padding-bottom: 5px;
  }

  .chat-title {
    margin-bottom: 5px;
  }

  .chat-title h1 {
    font-size: 1.3em;
    margin-bottom: 2px;
  }

  .chat-title p {
    font-size: 0.9em;
  }

  /* Create flex row for info and progress bar */
  .chat-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
  }

  .chat-info {
    margin: 0;
    font-size: 0.9em;
  }

  .progress-bar-container {
    position: relative;
    top: 0;
    right: 0;
    width: auto;
    margin: 0;
    padding: 0;
  }

  .progress-bar-container h3 {
    font-size: 0.8em;
    margin: 0;
  }

  .progress-bar {
    margin: 2px 0;
  }

  .progress-bar-container p {
    font-size: 0.7em;
    margin: 0;
  }

  /* Prevent any scrolling */
  .chat-page-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    padding: 0;
  }

  /* Make individual chat messages smaller */
  .chat-message {
    margin-bottom: 6px;
  }

  .profile-pic {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }

  .own-message .profile-pic {
    margin-left: 6px;
  }

  .message-content {
    padding: 6px 8px;
    font-size: 0.85em;
    max-width: calc(100% - 35px);
  }

  .display-name {
    font-size: 0.8em;
    margin-bottom: 2px;
  }

  .timestamp {
    font-size: 0.65em;
    margin-top: 2px;
  }

  /* Even smaller for iPhone-sized devices */
  @media screen and (max-width: 390px) {
    .profile-pic {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    .own-message .profile-pic {
      margin-left: 4px;
    }

    .message-content {
      padding: 4px 6px;
      font-size: 0.8em;
      max-width: calc(100% - 30px);
    }

    .display-name {
      font-size: 0.75em;
    }

    .timestamp {
      font-size: 0.6em;
    }
  }

  /* Message bubbles */
  .message-content {
    border-radius: 20px;
    padding: 10px 14px;
    position: relative;
    max-width: 75%;
    margin: 2px 0;
  }

  .own-message .message-content {
    background: linear-gradient(135deg, #0A84FF, #0066CC);
    border-top-right-radius: 4px;
  }

  .chat-message:not(.own-message) .message-content {
    background-color: #1C1C1E;
    border-top-left-radius: 4px;
  }

  /* Message tail styling */
  .message-content::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 20px;
  }

  .own-message .message-content::before {
    right: -8px;
    border-left: 8px solid #0066CC;
    border-bottom-left-radius: 16px 14px;
    transform: translate(0, -2px);
  }

  .chat-message:not(.own-message) .message-content::before {
    left: -8px;
    border-right: 8px solid #1C1C1E;
    border-bottom-right-radius: 16px 14px;
    transform: translate(0, -2px);
  }

  /* Profile pic adjustments */
  .profile-pic {
    width: 28px;
    height: 28px;
    margin: 0 8px;
    border: 2px solid #1C1C1E;
  }

  /* Send button improvements */
  .chat-input-container {
    background-color: rgba(28, 28, 30, 0.9);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .send-button {
    width: 45px;
    height: 45px;
    background: linear-gradient(135deg, #0A84FF, #0066CC);
    margin-left: 8px;
    transform: none;
  }

  .send-button svg {
    width: 24px;
    height: 24px;
    color: white;
  }

  /* Input field styling */
  .chat-input input {
    background-color: #2C2C2E;
    border-radius: 24px;
    padding: 12px 18px;
    font-size: 16px;
  }

  /* Message grouping */
  .chat-message + .chat-message {
    margin-top: 2px;
  }

  .chat-message:not(:last-child) .message-content {
    margin-bottom: 2px;
  }

  /* Animations */
  .chat-message {
    animation: slideIn 0.3s ease-out;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

/* Extra small devices */
@media screen and (max-width: 380px) {
  .members-navbar .title {
    font-size: 0.9em;
  }

  .attendee-profile-pic {
    height: 18px;
    width: 18px;
  }

  .message-content {
    max-width: calc(100% - 40px);
  }

  .chat-messages-container {
    width: 98%;
  }

  .chat-input {
    width: 98%;
  }
}

/* Fix send button icon */
.send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #bb86fc;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button svg {
  width: 16px;
  height: 16px;
  transform: translateX(1px); /* Slight adjustment to center the icon */
}

/* Fix send button and icon size */
.send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #bb86fc;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button svg {
  width: 20px; /* Increased from 16px */
  height: 20px; /* Increased from 16px */
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .chat-messages-container {
    width: 100%;
    height: calc(100vh - 340px - env(safe-area-inset-bottom));
    max-height: calc(52vh - env(safe-area-inset-bottom));
    margin-bottom: calc(60px + env(safe-area-inset-bottom));
  }

  /* Input container position stays at bottom */
  .chat-input-container {
    width: 100%;
    padding: 5px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #0f0e17;
    height: 50px;
    z-index: 99;
  }

  /* Adjust container height */
  .chat-container {
    transform: translateY(55px);
    padding: 0 10px;
    height: calc(100vh - 200px); /* Adjusted height */
    margin-bottom: 50px;
  }
}

/* Extra small devices and iPhones */
@media screen and (max-width: 390px) {
  .chat-messages-container {
    height: calc(100vh - 380px - env(safe-area-inset-bottom));
    max-height: calc(48vh - env(safe-area-inset-bottom));
  }
}

@media screen and (max-width: 768px) {
  /* Message styling */
  .own-message .message-content {
    background: #0A84FF;
    border-top-right-radius: 4px;
    border-radius: 20px;
  }

  /* Input container and send button */
  .chat-input-container {
    width: 100%;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #0f0e17;
    z-index: 99;
  }

  .chat-input {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  .chat-input input {
    flex: 1;
    padding: 12px 15px;
    border-radius: 20px;
    background-color: #2C2C2E;
    border: none;
    color: white;
  }

  .send-button {
    width: 40px;
    height: 40px;
    background-color: #0A84FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .send-button svg {
    width: 20px;
    height: 20px;
    color: white;
  }
}

/* Desktop message tails */
.message-content {
  position: relative;
  border-radius: 20px;
}

.message-content::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 20px;
}

.own-message .message-content {
  background-color: #4a47a3;
  border-top-right-radius: 4px;
}

.own-message .message-content::before {
  right: -8px;
  border-left: 8px solid #4a47a3;
  border-bottom-left-radius: 16px 14px;
  transform: translate(0, -2px);
}

.chat-message:not(.own-message) .message-content::before {
  left: -8px;
  border-right: 8px solid #161515;
  border-bottom-right-radius: 16px 14px;
  transform: translate(0, -2px);
}

/* Mobile specific overrides */
@media screen and (max-width: 768px) {
  .own-message .message-content {
    background-color: #4a47a3;
  }

  .own-message .message-content::before {
    border-left-color: #4a47a3;
  }

  .send-button {
    background-color: #4a47a3;
    background-image: none; /* Remove any gradient */
  }

  /* Override any other send button styles */
  .chat-input-container .send-button {
    background: #4a47a3;
  }
}