.chat-rooms-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0f0e17;
    color: #eaeaea;
    min-height: 100vh;
    padding: 40px;
  }
  
  .chat-rooms-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .chat-room-card {
    background-color: #1b1b2f;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .chat-room-card:hover {
    transform: scale(1.05);
  }
  
  .chat-room-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .chat-room-name {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  
  .chat-room-status {
    font-size: 0.9em;
    color: #aaaaaa;
  }
  
  .chat-rooms-note {
    margin-bottom: 50px;
    text-align: center; /* Center the text */
    max-width: 600px; /* Limit the width for better readability */
    margin-left: auto;
    margin-right: auto;
  }

  .chat-room-h1 {
    padding-bottom: 0px;
    margin-bottom: 0px;
    color: #6c63ff; /* This is the purple color */
    font-size: 2.5em; /* Increased font size for emphasis */
    text-align: center; /* Ensure it's centered */
    margin-top: 20px; /* Add some top margin */
  }

  .direct-messages-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin-top: 40px;
  }

  .dm-section-title {
    padding-bottom: 0px;
    margin-bottom: 0px;
    color: #6c63ff; /* This is the purple color */
    font-size: 2.5em; /* Increased font size for emphasis */
    text-align: center; /* Ensure it's centered */
    margin-top: 20px; /* Add some top margin */
  }

  .add-dm-button {
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5em;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-dm-button:hover {
    background-color: #5750d3;
    transform: scale(1.1);
  }

  .dm-container {
    background-color: #1b1b2f;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(108, 99, 255, 0.2);
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
  }

  .dm-rooms-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;
  }

  .dm-room-card {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: rgba(108, 99, 255, 0.1);
    transition: background-color 0.3s;
    cursor: pointer;
  }

  .dm-room-card:hover {
    background-color: rgba(108, 99, 255, 0.2);
  }

  .dm-room-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .dm-room-name {
    font-size: 1em;
    color: #eaeaea;
  }

  .no-dms-message {
    text-align: center;
    color: #aaaaaa;
  }

  /* Scrollbar styles for dm-rooms-container */
  .dm-rooms-container::-webkit-scrollbar {
    width: 6px;
  }

  .dm-rooms-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }

  .dm-rooms-container::-webkit-scrollbar-thumb {
    background-color: rgba(108, 99, 255, 0.5);
    border-radius: 3px;
  }

  .dm-rooms-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(108, 99, 255, 0.7);
  }

  .existing-conversation-link {
    display: block;
    margin-top: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }

  .existing-conversation-link:hover {
    background-color: #e0e0e0;
  }

  .tour-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    pointer-events: all;
  }

  /* Ensure Shepherd elements are above the overlay */
  .shepherd-element {
    z-index: 10000;
    position: relative;
  }

  /* Make sure clickable elements during the tour are above the overlay */
  .shepherd-enabled {
    pointer-events: auto !important;
  }

  .shepherd-target {
    pointer-events: auto !important;
  }

  .chat-room-card.tour-active {
    pointer-events: none;
    opacity: 0.7;
  }

  .existing-conversations-list {
    margin: 20px 0;
    max-height: 300px;
    overflow-y: auto;
  }

  .existing-conversation-item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 8px;
    background: rgba(255, 255, 255, 0.05);
    text-decoration: none;
    color: inherit;
    transition: background-color 0.2s;
  }

  .existing-conversation-item:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .conversation-user-info {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
  }

  .conversation-user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .conversation-details {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .conversation-username {
    font-weight: bold;
    font-size: 0.9em;
  }

  .conversation-last-message {
    font-size: 0.8em;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .conversation-time {
    font-size: 0.75em;
    color: #666;
  }

  /* Mobile Responsive Styles */
  @media screen and (max-width: 768px) {
    .chat-rooms-page {
      padding: 20px 15px;
      padding-bottom: calc(80px + env(safe-area-inset-bottom));
      width: 100%;
      min-height: -webkit-fill-available;
    }

    .chat-rooms-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Force 2 columns */
      gap: 12px;
      width: 100%;
      padding: 0 5px;
    }

    .chat-room-card {
      width: 100%;
      min-width: 0; /* Allow cards to shrink */
      padding: 12px;
      margin: 0;
    }

    .chat-room-image {
      width: 60px;
      height: 60px;
      margin-bottom: 8px;
    }

    .chat-room-name {
      font-size: 0.9em;
      text-align: center;
      margin-bottom: 3px;
    }

    .chat-room-status {
      font-size: 0.8em;
    }

    .chat-room-h1 {
      font-size: 1.8em;
      margin-bottom: -5px;
    }

    .chat-rooms-note {
      font-size: 0.85em;
      margin-bottom: 20px;
      padding: 0 0px;
    }

    /* Add these new styles for chat components */
    .chat-input-container {
      position: fixed;
      bottom: env(safe-area-inset-bottom);
      left: 0;
      right: 0;
      background: #0f0e17;
      padding: 10px 15px;
      padding-bottom: max(10px, env(safe-area-inset-bottom));
      z-index: 1000;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    }

    .chat-messages-container {
      margin-bottom: calc(60px + env(safe-area-inset-bottom));
      padding-bottom: 20px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    .dm-container,
    .chat-rooms-container {
      margin-bottom: calc(60px + env(safe-area-inset-bottom));
    }
  }

  /* Extra small devices */
  @media screen and (max-width: 320px) {
    .chat-rooms-container {
      gap: 8px;
      padding: 0 3px;
    }

    .chat-room-card {
      padding: 10px;
    }

    .chat-room-image {
      width: 50px;
      height: 50px;
    }

    .chat-room-name {
      font-size: 0.8em;
    }
  }